import { doc, getDoc, serverTimestamp, setDoc } from 'firebase/firestore';
import { httpsCallable } from 'firebase/functions';
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { requestClassificationListNames } from 'src/appsettings/appsettings';
import { ButtonTW } from 'src/components/Buttons/ButtonTW';
import { getLoadingSpinnerOrNull } from 'src/components/Spinner/util_getLoadingSpinnerOrNull';
import { useAppContext } from 'src/hooks/useAppContext';
import { PayeeCategoryEditor } from 'src/pages/Payees/PayeeCrud/PayeeCategoryEditor';
import { PayeeCategoryType } from 'src/types/types_payee';
import { userrole_isAdmin } from 'src/util/user_roles';
import { getBuildTimeStr } from 'src/util/util_getbuildtime';


export function AdminTools() {

  const { db, userDetails, setDbError, cloudFunctions } = useAppContext()

  if (!userrole_isAdmin(userDetails.roles))
    throw new Error('Unauthorized')

  const [message, setMessage] = useState<string>()


  const [payeeCategories, setPayeeCategories] = useState<PayeeCategoryType[]>()
  useEffect(() => {
    getDoc(doc(db, 'settings', 'payeecategories'))
      .then((catsDoc) => {
        const cats = catsDoc.data()!.categories as PayeeCategoryType[]
        setPayeeCategories(cats)
      })
      .catch((err) => setDbError('Getting payee categories', err))
  }, [db, setDbError])


  const [requestClassificationListsText, setRequestClassificationListsText] = useState<string>()
  useEffect(() => {
    getDoc(doc(db, 'settings', 'requestClassificationLists'))
      .then((doc) => {
        const dbData = doc.data()
        let s = ''
        for (const field of requestClassificationListNames) {
          s += `${field}\n`
          if (dbData) {
            const isTeams = field === 'eightyDaysTeams'
            if (isTeams) {
              for (const group of dbData[field]) {
                s += `- ${group.name}\n`
                for (const subitem of group.items) {
                  s += `--- ${subitem}\n`
                }
              }
            } else {
              for (const choice of dbData[field]) {
                s += `- ${choice}\n`
              }
            }
          } else {
            s += '- [MISSING]\n'
          }
        }
        setRequestClassificationListsText(s.trim())
      })
      .catch((err) => setDbError('Getting requestClassificationLists', err))
  }, [db, setDbError])


  const [countryListText, setCountryListText] = useState<string>()
  useEffect(() => {
    getDoc(doc(db, 'settings', 'countryList'))
      .then((doc) => {
        let s = ''
        if (doc.data()) {
          const list = doc.data()!.list
          for (const country of list) {
            s += `${country}\n`
          }
        }
        setCountryListText(s.trim())
      })
      .catch((err) => setDbError('Getting countryList', err))
  }, [db, setDbError])



  // *** all hooks above ***

  const loadingSpinner = getLoadingSpinnerOrNull([
    ['request classification lists', requestClassificationListsText],
    ['country list', countryListText],
    ['payee categories', payeeCategories],
  ])
  if (!requestClassificationListsText || !countryListText || !payeeCategories)
    return loadingSpinner


  return (
    <div className='container'>
      <Helmet><title>Admin tools</title></Helmet>
      <h2>Admin tools</h2>

      <hr />

      <div>Version: {import.meta.env.VITE_APP_VERSION}</div>
      <div>Build time: {getBuildTimeStr()}</div>

      <hr />

      <div className='alert alert-primary'>{message}</div>

      <hr />

      <h5>Payee Categories</h5>

      <PayeeCategoryEditor
        payeeCategories={payeeCategories}
        setPayeeCategories={setPayeeCategories}
        finishEditing={() => setMessage('Payee categories saved')}
      />

      <hr />

      <h5>Save requestClassificationLists to db</h5>

      <textarea className='form-control' rows={20}
        value={requestClassificationListsText}
        onChange={(e) => {
          setRequestClassificationListsText(e.target.value)
        }}
      ></textarea>

      <ButtonTW textSize='md' onClick={(e) => {
        setMessage('')


        const lines = requestClassificationListsText.trim().split('\n')
        const obj: Record<string, (string | { name: string, items: string[] })[]> = {}
        let currentGroup = null
        let currentSubGroup: { name: string, items: string[] } | null = null
        for (let line of lines) {
          line = line.trim()
          if (!line)
            continue
          if (line.startsWith('//'))
            continue
          if (!line.startsWith('-')) {
            const groupName = line.trim()
            if (groupName in obj) {
              window.alert(`duplicate name ${groupName}`)
              return
            }
            obj[groupName] = []
            currentGroup = groupName
            continue
          }

          const isTeams = currentGroup === 'eightyDaysTeams'

          if (line.startsWith('- ')) {
            const item = line.substring(1).trim()
            if (!currentGroup) {
              window.alert('No current group')
              return
            }
            if (obj[currentGroup].includes(item)) {
              window.alert(`Duplicate item ${item}`)
              return
            }

            if (isTeams) {
              currentSubGroup = { name: item, items: [] }
              obj[currentGroup].push(currentSubGroup)
            } else {
              obj[currentGroup].push(item)
            }
            continue
          }

          if (isTeams && line.startsWith('--- ')) {
            const subitem = line.substring(4).trim()
            if (!currentSubGroup) {
              window.alert('no subgroup')
              return
            }
            currentSubGroup.items.push(subitem)
            continue
          }

          window.alert(`Invalid line ${line}`)
        }

        console.log('save obj', obj)

        setDoc(doc(db, 'settings', 'requestClassificationLists'), {
          ...obj,
          userModifiedUid: userDetails.id,
          userModifiedName: userDetails.displayNameEn,
          userModifiedEmail: userDetails.email,
          dateModified: serverTimestamp(),
        })
          .then(() => {
            setMessage('success')
          })
          .catch((err) => setDbError('Saving requestClassificationLists', err))

      }}>Save requestClassificationLists to db</ButtonTW>


      <hr />

      <h5>Country list</h5>

      <textarea className='form-control' rows={20}
        value={countryListText}
        onChange={(e) => {
          setCountryListText(e.target.value)
        }}
      ></textarea>

      <ButtonTW textSize='md' onClick={(e) => {
        setMessage('')


        const lines = countryListText.trim().split('\n')
        const list: string[] = []
        for (let line of lines) {
          line = line.trim()
          if (!line)
            continue
          if (list.includes(line)) {
            window.alert(`Duplicate item ${line}`)
            return
          }
          list.push(line)
        }

        console.log(list)

        setDoc(doc(db, 'settings', 'countryList'), {
          list,
          userModifiedUid: userDetails.id,
          userModifiedName: userDetails.displayNameEn,
          userModifiedEmail: userDetails.email,
          dateModified: serverTimestamp(),
        })
          .then(() => {
            setMessage('success')
          })
          .catch((err) => setDbError('Saving countryList', err))

      }}>Save countryList to db</ButtonTW>



      <hr />

      <ButtonTW textSize='md' onClick={(e) => {
        const tourrequests_rebuildIndex = httpsCallable(cloudFunctions, 'tourrequests_rebuildIndex');
        tourrequests_rebuildIndex()
          .then((result) => {
            console.log(result)
          })
      }}>Rebuild Algolia index ‘tourrequests’</ButtonTW>




      <hr />

      <h5>Temporary Admin Tools</h5>

      <div style={{ border: '1px solid #00000033', borderRadius: '1em', padding: '1em', width: '30em', display: 'flex', flexDirection: 'column' }}>

        <ButtonTW textSize='md' onClick={(e) => {
          // // FYI: this works, it creates the nested maps
          // updateDoc(doc(db, 'expensesheets', '00000000'), {
          //   'a.b.c.d.e': 123
          // })

          // // this will create a single field whose name contains dots :(
          // addDoc(collection(db, 'expensesheets'), {
          //   'a.b.c.d.e': 123
          // })
          //   .then((added) => console.log('added', added))

          // // this will create a single field whose name contains dots :(
          // setDoc(doc(db, 'expensesheets', '00000000'), {
          //   'a.b.c.d.e': 123
          // })

          // // this will create a single field whose name contains dots :(
          // setDoc(doc(db, 'expensesheets', '00000001'), {
          //   'a.b.c.d.e': 123
          // }, { merge: true })


          // setDoc {merge:true} does a granular merge: no fields are ever deleted, only updated or added.
          // updateDoc on the other hand, without the dot notation, will NOT merge and will erase any sibling fields within the field being updated.

          // setDoc(doc(db, 'expensesheets', '00000000'), {
          //   dept1: {
          //     dept1_team1: {
          //       dept1_team1_a: 1,
          //       dept1_team1_b: 2,
          //       dept1_team1_c: 3,
          //     },
          //     dept1_team2: {
          //       dept1_team2_a: 4,
          //       dept1_team2_b: 5,
          //       dept1_team2_c: 6,
          //     },
          //     dept1_team3: {
          //       dept1_team3_a: 7,
          //       dept1_team3_b: 8,
          //       dept1_team3_c: 9,
          //     },
          //   },
          //   dept2: {
          //     dept2_team1: {
          //       dept2_team1_a: 10,
          //       dept2_team1_b: 20,
          //       dept2_team1_c: 30,
          //     },
          //     dept2_team2: {
          //       dept2_team2_a: 40,
          //       dept2_team2_b: 50,
          //       dept2_team2_c: 60,
          //     },
          //     dept2_team3: {
          //       dept2_team3_a: 70,
          //       dept2_team3_b: 80,
          //       dept2_team3_c: 90,
          //     },
          //   },
          //   dept3: {
          //     dept3_team1: {
          //       dept3_team1_a: 11,
          //       dept3_team1_b: 21,
          //       dept3_team1_c: 31,
          //     },
          //     dept3_team2: {
          //       dept3_team2_a: 42,
          //       dept3_team2_b: 52,
          //       dept3_team2_c: 62,
          //     },
          //     dept3_team3: {
          //       dept3_team3_a: 73,
          //       dept3_team3_b: 83,
          //       dept3_team3_c: 93,
          //     },
          //   },
          // })
          //   .then(() => {

          //     // setDoc(doc(db, 'expensesheets', '00000000'), {
          //     //   dept1: {
          //     //     dept1_team1: {
          //     //       dept1_team1_a: 99,
          //     //       dept1_team1_c: deleteField(),
          //     //     },
          //     //     dept1_team3: deleteField(),
          //     //   },
          //     //   dept3: deleteField(),
          //     // }, { merge: true })

          //     // the below DOES erase the rest of dept1
          //     updateDoc(doc(db, 'expensesheets', '00000000'), {
          //       dept1: {
          //         dept1_team1: {
          //           dept1_team1_a: 1234,
          //         }
          //       }
          //     })

          //   })

        }}>Try deep object update</ButtonTW>

        <hr />


      </div>

    </div>
  )
}
