import { addDoc, collection, doc, DocumentSnapshot, onSnapshot } from 'firebase/firestore';
import { ChangeEvent, useEffect, useMemo, useState } from 'react';
import { Alert, Form } from 'react-bootstrap';
import { Helmet } from 'react-helmet-async';
import { useNavigate, useParams } from 'react-router-dom';
import { ButtonTW } from 'src/components/Buttons/ButtonTW';
import { RequestCodeSelector } from 'src/components/FormControls/RequestCodeSelector';
import { TypeaheadUserList } from 'src/components/FormControls/TypeaheadUserList';
import { getLoadingSpinnerOrNull } from 'src/components/Spinner/util_getLoadingSpinnerOrNull';
import { TopWhiteBarEditControls } from 'src/hooks/autosave/TopWhiteBarEditControls';
import { useUndoRedo } from 'src/hooks/autosave/useUndoRedo';
import { autosaveDocument } from 'src/hooks/autosave/util_autosave';
import { getNewHistoryInfoObj } from 'src/hooks/autosave/util_undoredo';
import { useAppContext } from 'src/hooks/useAppContext';
import { DateInput } from 'src/pages/ExpenseSheet/DateInput';
import { useUserListSimple } from 'src/pages/ExpenseSheet/util_getuserlist';
import { paymentTypeLabel } from 'src/pages/Expenses/ExpenseAccounting/ModalPopupMakeExpensePayment/util_makeexpensepayment';
import { EditableFieldAddInvoice } from 'src/pages/Invoices/EditableFieldAddInvoice';
import { companyCreditCardList, GeneralExpenseRowType, GeneralExpenseType, paymentFlowEnumList, paymentTypeEnumList, PaymentTypeEnumType } from 'src/types/types_generalexpense';
import { UserSimpleType, UserSimpleUidType } from 'src/types/types_user';
import { iso_from_local0, local0_from_iso } from 'src/util/datetools';
import { userrole_canAddInvoice, userrole_canMarkPaid, userrole_isAdmin } from 'src/util/user_roles';
import { convertGeneralExpenseDates, serverTimestampAsDate } from 'src/util/util_firestoredates';
import { log_db_read, log_db_write } from 'src/util/util_log';
import { laxCalc } from 'src/util/util_misc';
import { ExpenseDetailsTable } from './PageComponents/ExpenseDetailsTable';
import { GeneralExpenseReceipts } from './PageComponents/GeneralExpenseReceipts';
import './editgeneralexpense.css';
import { addMetadataModifiedGeneralExpense, arrayFromObject, getNewGeneralExpenseItem, getTotalAmount, objectFromArray } from './util_generalexpense';


type FormStatusType = {
  level: 'danger' | 'info';
  text: string;
}

interface EditGeneralExpenseProps {
  action: 'create' | 'edit';
}

export function EditGeneralExpense({
  action,
}: EditGeneralExpenseProps) {

  const { userDetails, db, setDbError, cloudFunctions, _lang } = useAppContext()

  if (action !== 'create' && action !== 'edit')
    throw new Error('invalid action')

  const { generalExpenseId } = useParams()

  if ((action === 'create' && !generalExpenseId) || (action === 'edit' && generalExpenseId)) {
    // ok
  } else {
    throw new Error(`invalid action]${action} / generalExpenseId=${generalExpenseId}`)
  }

  const navigate = useNavigate()

  // autosave and undo/redo state
  const { addToCache, getUndoRedoHistoryChanges } = useUndoRedo<GeneralExpenseType>('generalexpenseshistory')
  const [enableEditing, setEnableEditing] = useState(action === 'create')
  const [saveStatus, setSaveStatus] = useState<string>()
  const [editedCell, setEditedCell] = useState<string | null>(null)


  const [generalExpense, setGeneralExpense] = useState<GeneralExpenseType>()
  const [multipleItems, setMultipleItems] = useState(false)

  // userHasEditPermissions is handled as a separate state variable, instead of being recomputed
  // from users in the generalExpense every time, to avoid weird behavior when a user
  // removes themselves from the list of collaborators. (UI would instantly get disabled)
  const [userHasEditPermissions, setUserHasEditPermissions] = useState(false)


  const isAdmin = userrole_isAdmin(userDetails.roles)
  const isPaymentIssuer = userrole_canMarkPaid(userDetails.roles)
  const isOfficeStaff = userrole_canAddInvoice(userDetails.roles)

  const userBasic = useMemo(() => {
    return {
      id: userDetails.id,
      email: userDetails.email,
      name: userDetails.displayNameEn,
    }
  }, [userDetails])

  const getCanBeSingleItem = (generalExpenseObj: GeneralExpenseType) => {
    return generalExpenseObj.itemList.length === 0 || (
      generalExpenseObj.itemList.length === 1
      && !generalExpenseObj.itemList[0].isTransportation
      && (!generalExpenseObj.itemList[0].description || generalExpenseObj.itemList[0].description === generalExpenseObj.description)
      && generalExpenseObj.itemList[0].quantity === 1
      && !generalExpenseObj.itemList[0].memorandum
    )
  }

  useEffect(() => {
    if (action === 'create') {
      const userSimple: UserSimpleUidType = {
        uid: userDetails.id,
        email: userDetails.email,
        name: userDetails.displayNameEn,
      }

      const newGeneralExpense: GeneralExpenseType = {
        id: null!,
        _isDeleted: false,
        history: getNewHistoryInfoObj(userSimple, 'Newly created general expense'),

        status: 'DRAFT',
        userOwner: userBasic,
        userReimbursed: userBasic,
        usersCollaborators: {},
        expenseDate: '', // string in yyyy-mm-dd format
        requestCode: '',
        paxName: '',
        description: '',
        itemList: [],
        totalAmount: null,
        paymentType: '',
        paymentFlow: '',
        companyCreditCard: '',
        memorandum: '',
        // freeeStatus: null,
        //metadata
        dateCreated: serverTimestampAsDate(),
        userCreated: userBasic,
        dateModified: serverTimestampAsDate(),
        userModified: userBasic,
      }
      setGeneralExpense(newGeneralExpense)
      setUserHasEditPermissions(true)
      return
    }

    if (action === 'edit') {
      if (!generalExpenseId)
        throw new Error('generalExpenseId missing')

      const processSnapshot = function (docu: DocumentSnapshot) {
        const generalExpenseObj = { ...docu.data(), id: docu.id } as GeneralExpenseType
        if (generalExpenseObj._isDeleted) {
          setDbError(`General expense no longer exists. ${generalExpenseId}`)
          throw new Error(`General expense no longer exists. ${generalExpenseId}`)
        }
        convertGeneralExpenseDates(generalExpenseObj)
        setGeneralExpense(generalExpenseObj)

        if (generalExpenseObj.history?.currentStepId) {
          addToCache(generalExpenseObj.history.currentStepId, generalExpenseObj)
        }

        const isSingleItem = getCanBeSingleItem(generalExpenseObj)
        setMultipleItems(!isSingleItem)

        const userCanEdit = (
          isPaymentIssuer || (
            // isOfficeStaff &&
            generalExpenseObj.userOwner?.id === userDetails.id
            || generalExpenseObj.userReimbursed?.id === userDetails.id
            || generalExpenseObj.usersCollaborators?.[userDetails.id] !== undefined
          )
        ) //&& (generalExpenseObj.status === 'DRAFT' || generalExpenseObj.status === 'SUBMITTED')
        setUserHasEditPermissions(userCanEdit)
      }

      const q = doc(db, 'generalexpenses', generalExpenseId)
      const unsubscribe = onSnapshot(q, processSnapshot, (err) => setDbError(`Getting general expense ${generalExpenseId}`, err))

      log_db_read({ db, userDetails, logkey: 'db_read.open_general_expense', desc: `Open general expense [${generalExpenseId}]` })

      return unsubscribe
    }
  }, [db, setDbError, userDetails, action, generalExpenseId, userBasic, isPaymentIssuer, addToCache])



  const userListSimple = useUserListSimple()

  const [formStatus, setFormStatus] = useState<FormStatusType>()
  const setFormErr = (text: string) => setFormStatus({ level: 'danger', text })
  const setFormInfo = (text: string) => setFormStatus({ level: 'info', text })


  // *** all hooks above this line

  const loadingSpinner = getLoadingSpinnerOrNull([
    ['user list', userListSimple],
    ['general expense', generalExpense],
  ])
  if (!userListSimple || !generalExpense)
    return loadingSpinner


  const canEdit =
    userHasEditPermissions
  // users can edit regardless of status, even if marked 'PAID'
  // && (
  //   (generalExpense.status === 'DRAFT' || generalExpense.status === 'SUBMITTED')
  //   || isPaymentIssuer // Tomoyo can edit anytime, even after marked PAID
  // )

  const isReadOnly = !enableEditing

  const canBeSingleItem = getCanBeSingleItem(generalExpense)



  const autosaveNewStep = async (
    userAction: string,
    updateObj: Partial<GeneralExpenseType>,
    sUndoWall: 'u' | 'UNDOWALL', // u = undoable
  ) => {
    return autosaveAnyStep(userAction, updateObj, false, undefined, sUndoWall)
  }

  const autosaveAnyStep = async (
    userAction: string,
    updateObj: any,
    isUndoRedo: boolean,
    undoRedoTargetStep: number | undefined,
    sUndoWall: 'u' | 'UNDOWALL', // u = undoable
  ) => {

    if (!enableEditing) {
      setDbError('Field change despite editing being disabled')
      throw new Error('Field change despite editing being disabled')
    }

    const userSimple = {
      uid: userDetails.id,
      email: userDetails.email,
      name: userDetails.displayNameEn,
    }

    autosaveDocument(
      updateObj,
      userAction,
      isUndoRedo,
      undoRedoTargetStep,
      sUndoWall,
      generalExpenseId!, // autosaveAnyStep is only called after general expense is saved to db
      generalExpense.history,
      userSimple,
      db,
      'generalexpenses',
      (updateObj) => addMetadataModifiedGeneralExpense(updateObj, userDetails),
      setSaveStatus,
    )
      .catch((err) => setDbError(`Autosave [autosaveDocument] generalexpense id=${generalExpenseId} action=[${userAction}]`, err))
  }

  const autosaveUndoRedoStep = async (action: 'Undo' | 'Redo', targetStep: number) => {

    const undoRedoData = await getUndoRedoHistoryChanges(action, targetStep, generalExpense.history)
    if (!undoRedoData)
      // failed to retrieve history step from db
      return

    const { updateObjHistory, targetStepObj } = undoRedoData

    // fields that we remove in general:
    //   - id: we never save id as a field
    //   - _isDeleted: should always be false
    //   - history: specifically tweaked in a precise way by updateObjHistory
    //   - dateCreated, userCreated: immutable metadata
    //   - dateModified, userModified: metadata set upon save
    // general expense fields that we remove:
    //   - tbd

    // delete the field we specifically don't want:
    delete targetStepObj.id
    delete targetStepObj._isDeleted
    delete targetStepObj.history
    // @ts-expect-error field parentDocumentId doesn't exist on type GeneralExpenseType
    delete targetStepObj.parentDocumentId

    delete targetStepObj.dateCreated
    delete targetStepObj.userCreated

    delete targetStepObj.dateModified
    delete targetStepObj.userModified

    // add more immutable fields as needed:
    // here we should basically add all fields that are not editable through the CRUD UI

    delete targetStepObj.receiptsAll // this should be maintained as a permanent record of all files ever uploaded on this general expense

    const updateObj: Partial<GeneralExpenseType> = {
      ...targetStepObj,
      ...updateObjHistory,
    }

    autosaveAnyStep(
      action, // this isn't actually used
      updateObj,
      true,
      targetStep,
      'u', // undo/redo step is always undoable
    )
  }



  const expenseDate_local0 = generalExpense.expenseDate ? local0_from_iso(generalExpense.expenseDate) : null

  const validateForm = () => {
    if (!generalExpense.userOwner) return 'Select user to be owner.'
    if (!generalExpense.usersCollaborators) return 'usersCollaborators must be an object.'
    if (!generalExpense.expenseDate) return 'Select date.'
    // requestCode and paxName are optional
    if (!generalExpense.description) return 'Input description.'

    if (generalExpense.itemList.length === 0) {
      if (multipleItems) {
        return 'Add at least 1 item to details table.'
      } else {
        return 'Input amount.'
      }
    }

    for (let i = 0; i < generalExpense.itemList.length; i++) {
      const item = generalExpense.itemList[i]
      const rownum = i + 1
      if (item.isTransportation) {
        if (!item.transportationMode) return `Input transportation mode on row ${rownum}`
        if (!item.transportationFrom) return `Input transportation From on row ${rownum}`
        if (!item.transportationTo) return `Input transportation To on row ${rownum}`
      } else {
        // If there's only a single item in the table, we assume its description is the
        // overall general expense description, so we don't require a description on the
        // table row.
        if (!item.description && generalExpense.itemList.length > 1) return `Input description on row ${rownum}`
      }
      if (!item.unitCost) return `Input unit cost on row ${rownum}`
      if (!item.quantity) return `Input quantity cost on row ${rownum}`
      if (!item.totalCost) return `Input total cost on row ${rownum}`
    }

    if (!generalExpense.totalAmount) return 'Total amount missing.'
    if (!generalExpense.paymentFlow) return 'Select payment flow.'
    if (generalExpense.paymentFlow === 'VIA_EMPLOYEE') {
      if (!generalExpense.paymentType) return 'Select employee reimbursement method.'
      if (!generalExpense.userReimbursed) return 'Select user to be reimbursed.'
    } else {
      generalExpense.paymentType = '' // TODO: avoid mutating state
      generalExpense.userReimbursed = null // TODO: avoid mutating state
    }
    if (generalExpense.paymentFlow === 'COMPANY_CREDITCARD') {
      if (!generalExpense.companyCreditCard) return 'Select company credit card.'
    } else {
      generalExpense.companyCreditCard = '' // TODO: avoid mutating state
    }
    // memorandum is optional
    return null
  }


  const createNewGeneralExpenseHandler = () => {

    const validationErr = validateForm()
    if (validationErr) {
      setFormErr(validationErr)
      return
    }

    // save to database
    setFormInfo('Saving...')
    const { id: _, ...saveData } = generalExpense

    log_db_write({ db, userDetails, logkey: 'db_write.general_expense.create', desc: 'Creating new general expense' })

    addDoc(collection(db, 'generalexpenses'), saveData)
      .then((result) => {
        //success
        console.log('addDoc result', result)
        setFormInfo('Saved.')
        navigate('/general-expenses/')
      })
      .catch((err) => {
        setDbError('Saving new general expense to database', err)
      })
  }


  const onChangePaymentFlow = (e: ChangeEvent<HTMLInputElement>) => {
    const paymentFlow = paymentFlowEnumList.find((p) => p === e.target.value) // currentTarget is null here
    const updateObj: Partial<GeneralExpenseType> = {
      paymentFlow,
    }

    // Here we don't reset paymentType to '', so that if user switches back and forth between VIA_EMPLOYEE and other values,
    // the choice of reimbursement method is not lost.
    // if (newGeneralExpense.paymentFlow !== 'VIA_EMPLOYEE')
    //   newGeneralExpense.paymentType = ''

    if (action === 'create') {
      setGeneralExpense((genexp) => ({ ...genexp!, ...updateObj }))
    } else {
      autosaveNewStep(`Change payment flow to ‘${paymentFlow}’`, updateObj, 'u')
    }
  }

  const filterCollaborators = (array: UserSimpleType[], userToExclude: UserSimpleType | null) => {
    const arrayFiltered = array.filter(
      (user) => user.id !== generalExpense.userOwner?.id // userOwner can be null
        && user.id !== generalExpense.userReimbursed?.id // userReimbursed can be null
        && user.id !== userToExclude?.id
    )
    return arrayFiltered
  }

  const autosaveOrSetNewStep = (userAction: string, updateObj: Partial<GeneralExpenseType>, sUndoWall: 'u' | 'UNDOWALL') => {
    if (action === 'create') {
      setGeneralExpense((genexp) => ({ ...genexp!, ...updateObj }))
    } else {
      autosaveNewStep(userAction, updateObj, sUndoWall)
    }
  }


  return (
    <div>
      <Helmet><title>Edit General Expense</title></Helmet>

      <TopWhiteBarEditControls
        whiteBarActive={action === 'edit'}
        enableEditing={enableEditing}
        setEnableEditing={setEnableEditing}
        saveStatus={saveStatus}
        setSaveStatus={setSaveStatus}
        autosaveUndoRedoStep={autosaveUndoRedoStep}
        history={generalExpense.history}
        divFloatingTotals={null}
        userIsAllowedToEdit={canEdit}
      />



      {/* <Form onSubmit={(e) => {
        // Can't have form here because File upload button of details table,
        // and also <EditableField> elements, have their own form elements. (not needed?)
      }}> */}
      <div className='editGeneralExpenseGrid'>


        {/* We put Employee selector ABOVE the datepicker, because the Typeahead
              has some annoying z-index:1 elements that intefere with the datepicker
              if the end up directly underneath the datepicker (not just the placeholder,
              but also an <input>, the X to remove selected items, etc. ) */}


        <h2 className='tw-my-4' style={{ gridColumn: '2 / span 2' }}>
          {_lang('Edit General Expense', '一般経費を編集')}
        </h2>


        <div className='col1'>Owner (Creator)</div>
        <div className='userSelector'>
          <TypeaheadUserList
            id='select-userOwner'
            multiple={false}
            onChange={(array: UserSimpleType[]) => {
              // only admin can do this action
              const selectedEmployee = array.length ? array[0] : null

              // we don't allow saving the general expense with no owner
              if (!selectedEmployee)
                return

              // remove newly set owner from collaborators if needed
              const collaboratorsNew = objectFromArray(filterCollaborators(arrayFromObject(generalExpense.usersCollaborators), selectedEmployee))

              const updateObj: Partial<GeneralExpenseType> = {
                userOwner: selectedEmployee,
                usersCollaborators: collaboratorsNew,
              }

              if (action === 'create') {
                setGeneralExpense((genexp) => ({ ...genexp!, ...updateObj }))
              } else {
                autosaveNewStep(`Change owner to ‘${selectedEmployee?.name ?? '[empty]'}’`, updateObj, 'u')
              }
            }}
            userList={userListSimple}
            selected={generalExpense.userOwner ? [generalExpense.userOwner] : []}
            disabled={!enableEditing || !isAdmin}
            customPlaceholder=''
          />
        </div>

        <div className='col1'>Other collaborators</div>
        <div className=''>
          <TypeaheadUserList
            id='select-usersCollaborators'
            multiple={true}
            onChange={(array) => {
              const collaboratorsArray = filterCollaborators(arrayFromObject(array), null)

              const updateObj: Partial<GeneralExpenseType> = {
                usersCollaborators: objectFromArray(collaboratorsArray),
              }

              if (action === 'create') {
                setGeneralExpense((genexp) => ({ ...genexp!, ...updateObj }))
              } else {
                autosaveNewStep(`Change collaborators to ‘${collaboratorsArray.map((c) => c.name).join(', ')}’`, updateObj, 'u')
              }
            }}
            userList={userListSimple}
            selected={arrayFromObject(generalExpense.usersCollaborators)}
            disabled={isReadOnly}
            customPlaceholder=''
          />
        </div>

        <div className='col1'>Date</div>
        <div>
          <DateInput value_local0={expenseDate_local0} disabled={isReadOnly} onChange={(date_local0) => {
            console.log('date_local0:', date_local0)
            // date is midnight in local timezone
            const sDate = date_local0 ? iso_from_local0(date_local0) : ''

            const updateObj: Partial<GeneralExpenseType> = {
              expenseDate: sDate,
            }

            if (action === 'create') {
              setGeneralExpense((genexp) => ({ ...genexp!, ...updateObj }))
            } else {
              autosaveNewStep(`Change date to ‘${sDate}’`, updateObj, 'u')
            }
          }} />
        </div>

        <div className='note'>
          Request code and pax name should be left blank if the expense is not related to any specific tour or request.
        </div>

        <RequestCodeSelector
          disabled={isReadOnly}
          tripCode={generalExpense.requestCode}
          paxName={generalExpense.paxName}
          setRequestData={({ requestCode, paxName, tourrequestId }) => {
            const updateObj: Partial<GeneralExpenseType> = {
              requestCode,
              paxName,
            }
            if (action === 'create') {
              setGeneralExpense((genexp) => ({ ...genexp!, ...updateObj }))
            } else {
              autosaveNewStep(`Set request code/pax name to ‘${requestCode}’/‘${paxName}’`, updateObj, 'u')
            }
          }}
        />

        {/* <div className='col1'>
          <label htmlFor="paxName">{_lang('Pax name', '旅行者名')}</label>
        </div>
        <div>
          <input id="paxName" disabled={isReadOnly} className='form-control' value={generalExpense.paxName} onChange={(e) => {
            setGeneralExpense((generalExpense) => ({ ...generalExpense, paxName: e.target.value }))
          }} />
        </div> */}

        <div className='note'></div>

        <div className='col1'>
          <Form.Label htmlFor='formDescription'>Description</Form.Label>
        </div>
        <div>
          <EditableFieldAddInvoice
            tableid='generalexpense'
            rowid='main'
            fieldname='description'
            validationType=''
            currentValue={generalExpense.description}
            isClickableToEdit={enableEditing}
            editedCell={editedCell}
            setEditedCell={setEditedCell}
            callbackCommitChange={(dbvalue: any) => {
              const updateObj: Partial<GeneralExpenseType> = {
                description: dbvalue,
              }
              if (action === 'create') {
                setGeneralExpense((genexp) => ({ ...genexp!, ...updateObj }))
              } else {
                autosaveNewStep(`Change description to ‘${dbvalue}’`, updateObj, 'u')
              }
              setEditedCell(null)
            }}
          />
        </div>

        <div className='col1'>
        </div>
        <div>
          <Form.Check type='radio' id='radioSingleItem' label='Single non-transportation item' checked={!multipleItems} disabled={isReadOnly || !canBeSingleItem} onChange={(e) => {
            setMultipleItems(!e.target.checked)
          }} />
          <Form.Check type='radio' id='radioMultipleItems' label='Multiple items, or transportation item' checked={multipleItems} disabled={isReadOnly || !canBeSingleItem} onChange={(e) => {
            setMultipleItems(e.target.checked)
          }} />
        </div>

        {multipleItems ? (
          <>
            <div className='col1'>Details:</div>

            <div className='colSpanAll'>
              <div className='detailsTableContainer'>
                <ExpenseDetailsTable
                  generalExpense={generalExpense}
                  autosaveOrSetNewStep={autosaveOrSetNewStep}
                  isReadOnly={isReadOnly}
                />
              </div>
            </div>
          </>
        ) : (
          <>
            <div className='col1'>Receipt</div>
            <div>
              <GeneralExpenseReceipts
                isReadOnly={isReadOnly}
                isExpandable={true}
                row={generalExpense.itemList[0]}
                tableid='singleItemReceiptTable'
                callbackUploadSuccess={(uploadedFilePathsAndURLs) => {
                  if (!getCanBeSingleItem(generalExpense)) {
                    // should be disabled
                    throw new Error(`add main receipt when multiple items present: ${generalExpense.itemList.length}`)
                  }

                  const itemListNew = [...generalExpense.itemList]

                  if (itemListNew.length === 0) {
                    itemListNew.push(getNewGeneralExpenseItem(false))
                  }

                  // very important not to mutate itemListNew[0], as it will break undo/redo
                  itemListNew[0] = {
                    ...itemListNew[0],
                    receipts: [...itemListNew[0].receipts, ...uploadedFilePathsAndURLs],
                  }

                  const updateObj: Partial<GeneralExpenseType> = {
                    itemList: itemListNew,
                    receiptsAll: [...(generalExpense.receiptsAll ?? []), ...uploadedFilePathsAndURLs],
                  }
                  if (action === 'create') {
                    setGeneralExpense((genexp) => ({ ...genexp!, ...updateObj }))
                  } else {
                    autosaveNewStep(`Uploaded file ${uploadedFilePathsAndURLs.map((f) => `[${f.storagePath}]`).join('')}`, updateObj, 'u')
                  }
                }}
                handleDeleteFile={(file) => {
                  if (!getCanBeSingleItem(generalExpense)) {
                    // should be disabled
                    throw new Error(`delete main receipt when multiple items present: ${generalExpense.itemList.length}`)
                  }

                  const itemListNew = [...generalExpense.itemList]
                  itemListNew[0] = {
                    ...itemListNew[0],
                    receipts: itemListNew[0].receipts.filter((f) => f.storagePath !== file.storagePath),
                  }

                  const updateObj: Partial<GeneralExpenseType> = {
                    itemList: itemListNew,
                  }
                  if (action === 'create') {
                    setGeneralExpense((genexp) => ({ ...genexp!, ...updateObj }))
                  } else {
                    autosaveNewStep(`Deleted file ‘${file.storagePath}’`, updateObj, 'u')
                  }
                }}
                generalExpenseIdForLog={generalExpense.id}
              />
            </div>
          </>
        )}

        <div className='col1'>
          <Form.Label htmlFor='formAmount'>Amount</Form.Label>
        </div>
        <div>
          <EditableFieldAddInvoice
            tableid='generalexpense'
            rowid='main'
            fieldname='amount'
            validationType='number' //className='text-end'
            currentValue={generalExpense.totalAmount}
            isClickableToEdit={enableEditing && !multipleItems}
            editedCell={editedCell}
            setEditedCell={setEditedCell}
            callbackCommitChange={(dbvalue: any) => {

              if (!getCanBeSingleItem(generalExpense)) {
                // should be disabled
                throw new Error(`edited total amount when multiple items present: ${generalExpense.itemList.length}`)
              }

              const itemCurrent = generalExpense.itemList[0] ?? getNewGeneralExpenseItem(false)

              const unitCost = typeof dbvalue === 'number' ? dbvalue : null // not sure if this ternary is necessary or not

              const itemNew: GeneralExpenseRowType = {
                ...itemCurrent,
                unitCost,
                totalCost: laxCalc((unitCost ?? 0) * itemCurrent.quantity, unitCost, itemCurrent.quantity),
              }

              const itemListNew = [itemNew, ...generalExpense.itemList.slice(1)] // just in case (should only have 1 item though)

              const totalAmount = getTotalAmount(itemListNew)

              const updateObj: Partial<GeneralExpenseType> = {
                itemList: itemListNew,
                totalAmount,
              }
              if (action === 'create') {
                setGeneralExpense((genexp) => ({ ...genexp!, ...updateObj }))
              } else {
                autosaveNewStep(`Change total amount to ‘${dbvalue}’`, updateObj, 'u')
              }
              setEditedCell(null)
            }}
          />
        </div>

        <div className='col1'>Payment flow</div>
        <div style={{ paddingTop: '0.4em' }}>
          <Form.Check
            type='radio'
            id='radioPaymentFlowByEmployee'
            name='radioPayment'
            label='Employee pays supplier, and is reimbursed by Eighty Days'
            value='VIA_EMPLOYEE'
            checked={generalExpense.paymentFlow === 'VIA_EMPLOYEE'}
            disabled={isReadOnly}
            onChange={onChangePaymentFlow}
          />
          <Form.Check
            type='radio'
            id='radioPaymentFlowCompanyCreditCard'
            name='radioPayment'
            label='Paid using Company credit card'
            value='COMPANY_CREDITCARD'
            checked={generalExpense.paymentFlow === 'COMPANY_CREDITCARD'}
            disabled={isReadOnly}
            onChange={onChangePaymentFlow}
          />
          <div style={{ marginLeft: '2em' }}>
            <Form.Select
              id='dropdown_company_creditcard'
              disabled={isReadOnly || generalExpense.paymentFlow !== 'COMPANY_CREDITCARD'}
              value={generalExpense.paymentFlow === 'COMPANY_CREDITCARD' ? (generalExpense.companyCreditCard || '') : ''}
              style={{ width: 'auto' }}
              onChange={(e) => {
                const companyCreditCard = companyCreditCardList.find((cc) => cc === e.target.value) // currentTarget is null here
                const updateObj: Partial<GeneralExpenseType> = {
                  companyCreditCard,
                }

                if (action === 'create') {
                  setGeneralExpense((genexp) => ({ ...genexp!, ...updateObj }))
                } else {
                  autosaveNewStep(`Change company credit card to ‘${companyCreditCard}’`, updateObj, 'u')
                }
              }}>
              {companyCreditCardList.map((companyCreditCard) => {
                if (!companyCreditCard)
                  return <option key='none' value=''></option>
                const parts = companyCreditCard.split('_')
                const capitalized = parts[0] === 'FIT' ? parts[0] : `${parts[0].charAt(0).toUpperCase()}${parts[0].slice(1).toLowerCase()}`
                const label = `${capitalized} ${parts[1]}`;
                return <option key={companyCreditCard} value={companyCreditCard}>{label}</option>
              })}
            </Form.Select>
          </div>
          <Form.Check
            type='radio'
            id='radioPaymentFlowCeoCreditCard'
            name='radioPayment'
            label='Paid using CEO credit card'
            value='CEO_CREDITCARD'
            checked={generalExpense.paymentFlow === 'CEO_CREDITCARD'}
            disabled={isReadOnly}
            onChange={onChangePaymentFlow}
          />
          <Form.Check
            type='radio'
            id='radioPaymentFlowDirect'
            name='radioPayment'
            label={<>Eighty Days pays supplier directly: please explain how the payment is actually made in the Memo section<br />(Note: payments by invoice should be registered in the invoice section, not as a General Expense)</>}
            value='DIRECT'
            checked={generalExpense.paymentFlow === 'DIRECT'}
            disabled={isReadOnly}
            onChange={onChangePaymentFlow}
          />
        </div>

        <div className='col1'>Employee to reimburse</div>
        <div className='userSelector'>
          <TypeaheadUserList
            id='select-userReimbursed'
            multiple={false}
            onChange={(array) => {
              const selectedEmployee = array.length ? array[0] : null

              // remove newly set reimbused user from collaborators if needed
              const collaboratorsNew = objectFromArray(filterCollaborators(arrayFromObject(generalExpense.usersCollaborators), selectedEmployee))

              const updateObj: Partial<GeneralExpenseType> = {
                userReimbursed: selectedEmployee,
                usersCollaborators: collaboratorsNew,
              }

              if (action === 'create') {
                setGeneralExpense((genexp) => ({ ...genexp!, ...updateObj }))
              } else {
                autosaveNewStep(`Change user reimbursed to ‘${selectedEmployee?.name ?? '[empty]'}’`, updateObj, 'u')
              }
            }}
            userList={userListSimple}
            selected={(generalExpense.paymentFlow !== 'VIA_EMPLOYEE') ? [] : generalExpense.userReimbursed ? [generalExpense.userReimbursed] : []}
            disabled={isReadOnly || generalExpense.paymentFlow !== 'VIA_EMPLOYEE'}
            customPlaceholder=''
          />
        </div>

        <div className='col1'>Employee reimbursement method</div>
        <div>
          <Form.Select
            value={(generalExpense.paymentFlow !== 'VIA_EMPLOYEE') ? '' : generalExpense.paymentType}
            disabled={isReadOnly || generalExpense.paymentFlow !== 'VIA_EMPLOYEE'}
            onChange={(e) => {
              const paymentType = e.target.value as PaymentTypeEnumType
              const updateObj: Partial<GeneralExpenseType> = {
                paymentType,
              }

              if (action === 'create') {
                setGeneralExpense((genexp) => ({ ...genexp!, ...updateObj }))
              } else {
                autosaveNewStep(`Change reimbursement method to ‘${paymentType}’`, updateObj, 'u')
              }
            }}>
            <option></option>
            {paymentTypeEnumList.map((paymentType) => (
              <option key={paymentType} value={paymentType}>{paymentTypeLabel(paymentType)}</option>
            ))}
          </Form.Select>
        </div>

        <div className='col1'>
          <label htmlFor='memorandum'>{_lang('Memo', '備考')}</label>
        </div>
        <div>
          <EditableFieldAddInvoice
            tableid='generalexpense'
            rowid='main'
            fieldname='memorandum'
            validationType=''
            currentValue={generalExpense.memorandum}
            isClickableToEdit={enableEditing}
            editedCell={editedCell}
            setEditedCell={setEditedCell}
            callbackCommitChange={(dbvalue: any) => {
              const updateObj: Partial<GeneralExpenseType> = {
                memorandum: dbvalue,
              }
              if (action === 'create') {
                setGeneralExpense((genexp) => ({ ...genexp!, ...updateObj }))
              } else {
                autosaveNewStep(`Change memo to ‘${dbvalue}’`, updateObj, 'u')
              }
              setEditedCell(null)
            }}
            isTextArea={true}
            textareaRows={4}
          />
        </div>

        <div className='col1'></div>
        <div style={{ display: 'flex', gap: '1em' }}>
          {!isReadOnly && (
            <>
              {action === 'create' ? (

                <ButtonTW variant='blue' textSize='md' onClick={(e) => {
                  e.preventDefault()
                  createNewGeneralExpenseHandler()
                }}>Create new general expense</ButtonTW>

              ) : generalExpense.status === 'DRAFT' ? (

                <ButtonTW variant='bsGreen' textSize='md' onClick={(e) => {
                  e.preventDefault()

                  const validationErr = validateForm()
                  if (validationErr) {
                    setFormErr(validationErr)
                    return
                  }

                  if (!window.confirm('Are you sure?'))
                    return

                  const updateObj: Partial<GeneralExpenseType> = {
                    status: 'SUBMITTED',
                  }

                  autosaveNewStep('SUBMITTED', updateObj, 'UNDOWALL')
                    .then(() => {
                      navigate('/general-expenses/')
                    })

                  log_db_write({ db, userDetails, logkey: 'db_write.general_expense.submit', desc: `Submitting general expense ${generalExpenseId}` })

                }}>Submit</ButtonTW>

              ) : null}
            </>
          )}
        </div>

        {/* <div className='note'>
          After submission, expense can no longer be modified.
        </div> */}

        <div className='col1'></div>
        <div>
          {formStatus && (
            <Alert variant={formStatus.level}>
              {formStatus.text}
            </Alert>
          )}
        </div>

      </div>
      {/* </Form> */}


    </div>
  )
}
