import { DocumentSnapshot, deleteField, doc, onSnapshot, serverTimestamp } from 'firebase/firestore'
import { useEffect, useMemo, useState } from 'react'
import { Tab, Tabs } from 'react-bootstrap'
import { Helmet } from 'react-helmet-async'
import { useParams } from 'react-router-dom'
import { Tooltip } from 'react-tooltip'
import { CheckboxSwitch } from 'src/components/Buttons/CheckboxSwitch'
import { EditableFieldQuotation, FunctionSaveFieldToDbType, FunctionTabKeyPress } from 'src/components/EditableField/EditableFieldQuotation'
import { getLoadingSpinnerOrNull } from 'src/components/Spinner/util_getLoadingSpinnerOrNull'
import { useUndoRedo } from 'src/hooks/autosave/useUndoRedo'
import { autosaveDocument } from 'src/hooks/autosave/util_autosave'
import { useAppContext } from 'src/hooks/useAppContext'
import { EnumLineItemType, QuotationDayType, QuotationLineItemType, QuotationLineItemTypeAccommodation, QuotationLineItemTypeGuide, QuotationLineItemTypeOther, QuotationLineItemTypeTicket, QuotationLineItemTypeTransportation, QuotationLineItemTypeUnion, QuotationLineItemsByTypeType, QuotationType, QuotationUpdatableItemType } from 'src/types/types_quotation'
import { UserSimpleUidType } from 'src/types/types_user'
import { dateutcFormatJpShort, dateutcFormatWithWeekday } from 'src/util/dateformattools'
import { addDays, getSpanDaysExact, iso_from_utc0, utc0_from_iso } from 'src/util/datetools'
import { userrole_isDev } from 'src/util/user_roles'
import { verifyNotDeleted } from 'src/util/util_db_misc'
import { convertQuotationSheetDates } from 'src/util/util_firestoredates'
import { formatNum } from 'src/util/util_formatnum'
import { getFieldValue } from 'src/util/util_getFieldValue'
import { log_db_read } from 'src/util/util_log'
import { nano_id } from 'src/util/util_nano_id'
import { TopWhiteBarEditControls } from '../../../hooks/autosave/TopWhiteBarEditControls'
import './quotationcrud.css'
import { HotelSummaryTable } from './QuotationParts/HotelSummaryTable'
import { FunctionCallbackOnPasteQuotation, LineItemTable } from './QuotationParts/LineItemTable'
import { TotalsTable } from './QuotationParts/TotalsTable'
import { NEW_ROW_ID, calculateQuotationOverallNumbers, calculateSameRowValues } from './util_calculateQuotationTotals'
import { addMetadataModifiedQuotations } from './util_db_quotations'
import { PrepareSaveFieldToDbType, UpdateQuotationType, handleQuotationPaste } from './util_handlepaste'
import { addBlankItems } from './util_lineitemtables'
import { renderImageTags } from './util_renderimages'




const lineItemTypes: EnumLineItemType[] = ['accommodation', 'transportation', 'tickets', 'guide', 'other']
const lineItemColumns = {
  accommodation: ['name', 'room_type', 'room_size', 'room_otherFeatures', 'room_numberOfPaxPerRoom', 'room_pricePerPerson', 'totalPrice', 'memorandum'],
  transportation: ['name', 'transportation_from', 'transportation_to', 'transportation_carType', 'transportation_unitCount', 'transportation_unitPrice', 'totalPrice', 'memorandum'],
  tickets: ['name', 'ticket_numberOfAdults', 'ticket_pricePerAdult', 'ticket_numberOfChildren', 'ticket_pricePerChild', 'totalPrice', 'memorandum'],
  guide: ['name', 'guide_accommodation', 'guide_transportation', 'guide_meals', 'guide_numberOfHours', 'guide_pricePerHour', 'totalPrice', 'memorandum'],
  other: ['name', 'totalPrice', 'memorandum'],
}

export type NumBlankRowsType = {
  accommodation: number,
  transportation: number,
  tickets: number,
  guide: number,
  other: number,
}

export function QuotationCrud() {

  const { quotationId } = useParams()
  const { db, userDetails, setDbError, storage } = useAppContext()

  if (!quotationId) {
    setDbError('Quotation ID not provided')
    throw new Error('Quotation ID not provided')
  }

  const [enableEditing, setEnableEditing] = useState(false)
  const [editedCell, setEditedCell] = useState<string | null>(null)

  const [selectedTab, setSelectedTab] = useState<string | null>('quoting');

  const [showDebugInfo, setShowDebugInfo] = useState(false)


  const [saveStatus, setSaveStatus] = useState<string>()
  // const refSaveStatus = useRef()

  const userSimple = useMemo(() => {
    return {
      uid: userDetails.id,
      email: userDetails.email,
      name: userDetails.displayNameEn,
    } as UserSimpleUidType
  }, [userDetails])



  const { addToCache, getUndoRedoHistoryChanges } = useUndoRedo<QuotationType>('quotationsheetshistory')


  const [quotation, setQuotation] = useState<QuotationType>()
  useEffect(() => {

    let isLoaded = false

    const processSnapshot = function (snapshot: DocumentSnapshot) {
      // console.log('new snapshot', snapshot.data())
      const quotationsheet = { ...snapshot.data(), id: snapshot.id } as QuotationType
      verifyNotDeleted(snapshot.exists(), quotationsheet, quotationId, setDbError, 'quotationsheet')
      convertQuotationSheetDates(quotationsheet)
      setQuotation(quotationsheet)
      addToCache(quotationsheet.history.currentStepId, quotationsheet)
      if (!isLoaded) {
        log_db_read({ db, userDetails, logkey: 'db_read.open_quotation_sheet', desc: `Open quotation sheet [${quotationsheet.requestInfo.requestCode}] [${quotationsheet.quotationSheetName}] [${quotationsheet.id}]` })
        isLoaded = true
      }
    }

    const q = doc(db, 'quotationsheets', quotationId)
    const unsubscribe = onSnapshot(q, processSnapshot, (err) => setDbError('Getting quotationsheets list', err));

    return unsubscribe

  }, [db, setDbError, userDetails, quotationId, addToCache])


  const [numBlankRows, setNumBlankRows] = useState<Record<string, number>>({
    // day_1_accommodation: 0,
    // day_1_transportation: 0,
    // day_1_tickets: 0,
    // day_1_guide: 0,
    // day_1_other: 0,
  })

  if (!enableEditing && Object.keys(numBlankRows).length > 0)
    // when user disables editing, delete all outstanding blank rows
    setNumBlankRows({})


  const loadingSpinner = getLoadingSpinnerOrNull([
    ['quotation', quotation],
  ])
  if (!quotation)
    return loadingSpinner


  // ***** all hooks above


  const tourStartUTC = utc0_from_iso(quotation.requestInfo.dateisoTourStart) // UTC
  const tourEndUTC = utc0_from_iso(quotation.requestInfo.dateisoTourEnd) // UTC
  const spanDays = getSpanDaysExact(tourStartUTC, tourEndUTC)
  const maxDayNum = spanDays
  const numDays = maxDayNum + 1
  const listDayNums = Array.from(Array(numDays).keys()) // 0-based


  // convert quotation to hierarchy:
  //   dayNum
  //     section (accommodation, transportation, etc)
  //       line item

  // convert lineItems from {id: lineItem} to lineItem[], and add id as field
  const lineItemsAll = Object.entries(quotation.lineItems).map(([id, value]) => {
    return {
      ...value,
      id: `lineItems.${id}`
    } as QuotationLineItemTypeUnion
  })

  const updateQuotation: UpdateQuotationType = (
    updateObj: any,
    userAction: string,
    isUndoRedo: boolean | undefined,
    undoRedoTargetStep?: number,
  ) => {

    const sUndoWall = 'u' // TODO: check this is ok for all changes made via this function

    autosaveDocument(
      updateObj,
      userAction,
      isUndoRedo ?? false,
      undoRedoTargetStep,
      sUndoWall,
      quotationId,
      quotation.history,
      userSimple,
      db,
      'quotationsheets',
      (updateObj) => addMetadataModifiedQuotations(updateObj, userDetails),
      setSaveStatus,
    )
      .catch((err) => setDbError(`Autosave quotation id=${quotation.id} action=[${userAction}]`, err))

  }

  const prepareSaveFieldToDb: PrepareSaveFieldToDbType = (
    baseField: 'overall' | 'days' | 'lineItems',
    itemId: string, // 'overall' for baseField='overall'; 'lineItems.abcdefgh' for line item; 'days.day_7' for day.
    itemNewRow: QuotationUpdatableItemType, // null except when creating a new row. item in previous state BEFORE any change by user
    field: string,
    dbvalue: any,
    formula: string | undefined,
  ) => {

    let newRowId = null // will be returned as null, if this is not a new row
    let updateObj: Record<string, any>


    // When updating a line item in Firestore, at the same time, we want to also update the totals stored in Firestore.
    // The function calculating the total takes a list of line items.
    // However, we normally don't ever mutate the state (quotation and its line items) ourselves.
    // Instead, we update Firestore, and onSnapshot provides the new state to us.
    // So in order to pass the line items with the new data to calculate the totals, we make a temporary clone.

    // Note about TS: for the new row, we are only adding the totalPrice field, so we cast to any[]. This should be refactored at some point.

    // let daysModified = // we don't need to clone days because contents of `days` does not affect totals calculation
    let overallModified = quotation.overall // !do not mutate quotation.overall! instead, reassign overallModified.
    let lineItemsAllModified = lineItemsAll // !do not mutate lineItemsAll! instead, reassign lineItemsAllModified.


    if (itemId === NEW_ROW_ID) {
      // NEW ROW

      if (baseField !== 'lineItems')
        throw new Error('unreachable')

      if (!dbvalue)
        return
      const newRowNanoId = nano_id()
      newRowId = `lineItems.${newRowNanoId}`

      const dbobj: any = {
        ...itemNewRow,
        [field]: dbvalue,
        dateCreated: serverTimestamp(),
        dateModified: serverTimestamp(),
        userCreated: userSimple,
        userModified: userSimple,
      }
      if (formula)
        dbobj[`${field}_formula`] = formula

      delete dbobj.id

      updateObj = {
        [`${newRowId}`]: dbobj,
      }

      const newItem = {
        ...itemNewRow,
        [field]: dbvalue,
        id: newRowNanoId,
      } as QuotationLineItemTypeUnion

      lineItemsAllModified = [
        ...lineItemsAll,
        newItem,
      ]

      // if (field === 'totalPrice') {// only totalPrice is needed in lineItemsClone to calculate quotation total
      //   lineItemsAllClone.push({ [field]: dbvalue })
      // }
    } else {
      // EXISTING ROW

      const currentVal = getFieldValue(quotation, `${itemId}.${field}`)

      if (dbvalue === currentVal)
        // no change
        return

      updateObj = {
        [`${itemId}.${field}`]: dbvalue,
        [`${itemId}.${field}_formula`]: formula ?? deleteField(),
        [`${itemId}.dateModified`]: serverTimestamp(),
        [`${itemId}.userModified`]: userSimple,
      }

      if (baseField === 'lineItems') {
        const itemCurrent = lineItemsAll.find((item) => item.id === itemId)!

        const itemModified = {
          ...itemCurrent,
          [field]: dbvalue,
          [`${field}_formula`]: formula, // can be undefined
        }

        // special handling of calculations. (not needed for newly created rows)
        calculateSameRowValues(itemCurrent, field, dbvalue, updateObj, itemModified)

        lineItemsAllModified = [
          ...lineItemsAll.filter((item) => item.id !== itemId),
          itemModified,
        ]
      } else if (baseField === 'days') {
        // currently, data under 'days' never affects total cost -> do nothing
      } else if (baseField === 'overall') {
        overallModified = {
          ...quotation.overall,
          [field]: dbvalue,
          [`${field}_formula`]: formula, // can be undefined
        }
      }

    }

    calculateQuotationOverallNumbers(overallModified, lineItemsAllModified, updateObj)

    return {
      updateObj,
      newRowId,
    }

  }

  const prepareSaveRowsToDb = (
    newItems: QuotationLineItemTypeUnion[],
  ) => {

    const lineItemsAllModified = [...lineItemsAll] // don't mutate lineItemsAll

    const updateObj: Record<string, any> = {}

    for (const newrow of newItems) {

      const newRowNanoId = nano_id()
      const newRowId = `lineItems.${newRowNanoId}`

      const dbobj: any = {
        ...newrow,
        dateCreated: serverTimestamp(),
        dateModified: serverTimestamp(),
        userCreated: userSimple,
        userModified: userSimple,
      }

      delete dbobj.id

      updateObj[newRowId] = dbobj

      const newItem = {
        ...newrow,
        id: newRowNanoId,
      } as QuotationLineItemTypeUnion

      lineItemsAllModified.push(newItem)

    }

    calculateQuotationOverallNumbers(quotation.overall, lineItemsAllModified, updateObj)

    return {
      updateObj,
    }

  }

  const saveFieldToDb: FunctionSaveFieldToDbType = (
    baseField: 'overall' | 'days' | 'lineItems',
    itemId: string, // 'overall' for baseField='overall'; 'lineItems.abcdefgh' for line item; 'days.day_7' for day.
    itemNewRow: QuotationLineItemType, // null except when creating a new row
    field: string,
    dbvalue: any,
    formula: string | undefined
  ) => {
    const prepResult = prepareSaveFieldToDb(baseField, itemId, itemNewRow, field, dbvalue, formula)
    if (!prepResult)
      return

    const { updateObj, newRowId } = prepResult
    updateQuotation(updateObj, `Type ‘${dbvalue}’ in ‘${field}’`, false)

    if (itemId === NEW_ROW_ID) {
      // new row is now saved to db, so the row from the db object replaces the blank row -> we must delete the blank row
      const key = `day_${itemNewRow.dayNum}_${itemNewRow.itemType}`
      setNumBlankRows((obj) => {
        return {
          ...obj,
          [key]: 0, // obj[key] - 1,
        }
      })
    }

    return { newRowId }
  }


  const callbackOnPasteQuotation: FunctionCallbackOnPasteQuotation = (e, baseField, item, field) => {
    handleQuotationPaste(e, baseField, item, field, userSimple, quotation, storage, prepareSaveFieldToDb, updateQuotation)
  }


  const renderImages = (strValue: string) => {
    return renderImageTags(strValue, quotation?.uploadedFiles)
  }


  const allFields: string[] = [] // to be populated in daily for loop

  const tabKeyPress: FunctionTabKeyPress = (tabKey, currentCellId, newlyAssignedId) => {
    const index = allFields.indexOf(currentCellId)
    if (index === -1) {
      // current cell not found. this shouldn't really ever happen
      console.log(`tabKeyPress: Current cell not found: ${currentCellId}`)
      //console.log('allFields', allFields.filter(s => s.startsWith('day_0_transportation')))
      setEditedCell('')
      return
    }
    if (tabKey === 1 && index < allFields.length - 1) {
      let nextFieldId = allFields[index + 1]
      if (newlyAssignedId)
        nextFieldId = nextFieldId.replace(NEW_ROW_ID, newlyAssignedId)
      setEditedCell(nextFieldId)
      return
    }
    if (tabKey === -1 && index > 0) {
      let previousFieldId = allFields[index - 1]
      if (newlyAssignedId)
        previousFieldId = previousFieldId.replace(NEW_ROW_ID, newlyAssignedId)
      setEditedCell(previousFieldId)
      return
    }
    setEditedCell('')
    return
  }



  const autosaveUndoRedoStep = async (action: 'Undo' | 'Redo', targetStep: number) => {

    const undoRedoData = await getUndoRedoHistoryChanges(action, targetStep, quotation.history)
    if (!undoRedoData)
      // failed to retrieve history step from db
      return

    const { updateObjHistory, targetStepObj } = undoRedoData

    const updateObj = {
      ...updateObjHistory,

      lineItems: targetStepObj.lineItems,
      days: targetStepObj.days,
      overall: targetStepObj.overall,
      // We do NOT restore uploadedFiles when doing undo/redo, as the file is not being removed from storage.
      // We treat uploaded files as independant of undo/redo steps.
      // uploadedFiles: targetStepObj.uploadedFiles,
    }


    updateQuotation(
      updateObj,
      action, // this isn't actually used
      true,
      targetStep,
    )
  }


  const editableFieldCommonProps = {
    isClickableToEdit: enableEditing,
    editedCell: editedCell,
    setEditedCell: setEditedCell,
    saveFieldToDb,
    tabKeyPress,
  }

  const undoOperation = quotation?.history?.steps?.[`step_${quotation.history.currentStep}`]?.stepAction
  const undoLabel = undoOperation ? `Undo: ${undoOperation}` : 'Undo'

  const redoOperation = quotation?.history?.steps?.[`step_${quotation.history.currentStep + 1}`]?.stepAction
  const redoLabel = redoOperation ? `Redo: ${redoOperation}` : 'Redo'

  const propsHotelSummaryTable = {
    lineItemsAll,
    dayNum: 9999,
    dateiso: 'XXXX',
    dayInfo: null! as QuotationDayType, // ??
    updateQuotation,
    enableEditing,
    editedCell,
    setEditedCell,
    saveFieldToDb,
    prepareSaveRowsToDb,
    userSimple,
    tabKeyPress,
    callbackOnPasteQuotation,
    renderImages,
    showDebugInfo,
    overall: quotation.overall,
  }

  return (
    <div className='container-fluidx page-quotationcrud'>

      <Helmet><title>Quotation</title></Helmet>

      <TopWhiteBarEditControls
        whiteBarActive={true}
        enableEditing={enableEditing}
        setEnableEditing={setEnableEditing}
        saveStatus={saveStatus}
        setSaveStatus={setSaveStatus}
        autosaveUndoRedoStep={autosaveUndoRedoStep}
        history={quotation.history}
        divFloatingTotals={
          <div className='floatingTotals'>
            <div>
              Total before com.: {formatNum(quotation.overall.totalPrice)}
            </div>
            <div>
              Total after com.: {formatNum(quotation.overall.totalPriceIncCommission)}
            </div>
          </div>
        }
        userIsAllowedToEdit={true}
      />

      <div style={{ marginLeft: '10em' }}>
        <h2 className='my-4'>Quotation: {quotation.requestInfo.requestCode} / {quotation.quotationSheetName}</h2>



        <div className='topgrid'>

          <div>Request code</div>
          <div>{quotation.requestInfo.requestCode}</div>

          <div>Traveller name</div>
          <div>{quotation.requestInfo.travellerName}</div>

          <div>Number of pax</div>
          <div>{quotation.requestInfo.numOfPax}</div>

          <div>Trip dates</div>
          <div>
            {/* <DateRangeInput startDate={local0_from_utc0(tourStartUTC)} endDate={local0_from_utc0(tourEndUTC)} onChange={(dates) => {
            console.log('dates', dates)
          }} /> */}
            {dateutcFormatJpShort(tourStartUTC)} to {dateutcFormatJpShort(tourEndUTC)} ({numDays} days)
          </div>

          <div>Quotation sheet name</div>
          <div>{quotation.quotationSheetName}</div>

        </div>
      </div>

      {userrole_isDev(userDetails.roles) && (
        <div className='mt-4' style={{ marginLeft: '10em' }}>
          <CheckboxSwitch id='chkShowDebugInfo' label='Show debug info' checked={showDebugInfo} onChange={(e) => {
            setShowDebugInfo(e.target.checked)
          }} />
        </div>
      )}

      <Tooltip id='quotation-tooltip' place='top' variant='dark' />


      <Tabs
        id='tabs-quotation-mode'
        activeKey={selectedTab ?? undefined}
        onSelect={(newTab) => setSelectedTab(newTab)}
        className='mt-5 mb-3'
        style={{ borderBottom: '1px solid rgb(33, 37, 41, 0.25)', paddingLeft: '10em' }}
      >
        <Tab eventKey='quoting' title='Quoting mode'>
        </Tab>
        <Tab eventKey='booking' title='Booking mode'>
        </Tab>
        <Tab eventKey='expenses' title='Expenses mode'>
        </Tab>
      </Tabs>

      {selectedTab !== 'quoting' ? (
        <h5>Under construction</h5>
      ) : (
        <>
          <div>
            <TotalsTable
              quotation={quotation}
              enableEditing={enableEditing}
              editedCell={editedCell}
              setEditedCell={setEditedCell}
              tabKeyPress={tabKeyPress}
              userSimple={userSimple}
              lineItemsAll={lineItemsAll}
              updateQuotation={updateQuotation}
            />
          </div>

          <hr />

          <div>
            <div style={{ marginLeft: '10em', marginBottom: '1em' }}>
              <h3>Hotel summary</h3>
            </div>

            <HotelSummaryTable
              lineItemsThisTable={lineItemsAll
                .filter((item) => item.itemType === 'accommodation')
                .map((item) => (item as QuotationLineItemTypeAccommodation))
                .sort((a, b) => (a.dayNum - b.dayNum) || (a.index - b.index))}
              {...propsHotelSummaryTable}

              tablename='accommodation'
              columns={[
                { field: 'name', header: 'Accommodation', widthEm: 19 },
                { field: 'date_checkin', header: 'Check-in', widthEm: 10 },
                { field: 'date_checkout', header: 'Check-out', widthEm: 10 },
                { field: 'room_type', header: 'Room type', widthEm: 10 },
                { field: 'room_size', header: 'Room size', widthEm: 10 },
                { field: 'room_otherFeatures', header: 'Other features', widthEm: 10 },
                { field: 'room_numberOfPaxPerRoom', header: '# of pax per room', widthEm: 10, isNumeric: true },
                { field: 'room_pricePerPerson', header: 'Price per person', widthEm: 10, isNumeric: true },
                { field: 'totalPrice', header: 'Price per room incl. tax', widthEm: 10, isNumeric: true },
              ]}
              bgcolor='#8fe'
            />


          </div>

          {listDayNums.map((dayNum) => {
            const dateUTC = addDays(tourStartUTC, dayNum)
            const dateiso = iso_from_utc0(dateUTC)

            const lineItemsThisDay = lineItemsAll.filter((item) => item.dayNum === dayNum)

            const lineItemsByType: QuotationLineItemsByTypeType = {
              accommodation: lineItemsThisDay.filter((item) => item.itemType === 'accommodation').map((item) => (item as QuotationLineItemTypeAccommodation)).sort((a, b) => a.index - b.index),
              transportation: lineItemsThisDay.filter((item) => item.itemType === 'transportation').map((item) => (item as QuotationLineItemTypeTransportation)).sort((a, b) => a.index - b.index),
              tickets: lineItemsThisDay.filter((item) => item.itemType === 'tickets').map((item) => (item as QuotationLineItemTypeTicket)).sort((a, b) => a.index - b.index),
              guide: lineItemsThisDay.filter((item) => item.itemType === 'guide').map((item) => (item as QuotationLineItemTypeGuide)).sort((a, b) => a.index - b.index),
              other: lineItemsThisDay.filter((item) => item.itemType === 'other').map((item) => (item as QuotationLineItemTypeOther)).sort((a, b) => a.index - b.index),
            }

            const miscategorized = lineItemsThisDay.filter((item) => !lineItemTypes.includes(item.itemType))
            if (miscategorized.length > 0)
              throw new Error('Miscategorized line item')

            const lineItems_total = lineItemsThisDay.reduce((acc, item) => acc + (item.totalPrice || 0), 0)

            if (enableEditing) {
              // add blank rows to allow user to add new row
              for (const itemType of lineItemTypes) {
                const key = `day_${dayNum}_${itemType}`
                if (!numBlankRows[key])
                  continue
                for (let i = 0; i < numBlankRows[key]; i++) {
                  addBlankItems(
                    lineItemsByType,
                    dayNum,
                    dateiso,
                    NEW_ROW_ID,
                    itemType
                  )
                }
              }
            }


            const dayInfo = quotation.days[`day_${dayNum}`]

            const propsTables = {
              lineItemsAll,
              dayNum,
              dateiso,
              dayInfo,
              updateQuotation,
              enableEditing,
              editedCell,
              setEditedCell,
              saveFieldToDb,
              prepareSaveRowsToDb,
              userSimple,
              tabKeyPress,
              callbackOnPasteQuotation,
              renderImages,
              showDebugInfo,
              overall: quotation.overall,
              setNumBlankRows,
            }

            const dayOverallInfo = {
              ...editableFieldCommonProps,
              baseField: 'days' as const,
              rowObj: { ...dayInfo, id: `days.day_${dayNum}` },
              tableid: `day_${dayNum}_overall`,
            }

            allFields.push(`day_${dayNum}_overall_${dayOverallInfo.rowObj.id}_memorandumTop`)

            lineItemTypes.forEach((type) => {
              const lineItemsThisType = lineItemsByType[type]
              const cols = lineItemColumns[type]

              lineItemsThisType.forEach((item) => {
                cols.forEach((field) => {
                  allFields.push(`day_${dayNum}_${type}_${item.id}_${field}`)
                })
              })
            })

            const hasFlightInfo = dayInfo?.flightIntoformation?.flightNumber || dayInfo?.flightIntoformation?.flightOriginDestination || dayInfo?.flightIntoformation?.flightTime

            return (
              <div key={dayNum}>
                <hr />
                <div style={{ marginLeft: '10em', marginBottom: '1em' }}>
                  <h3>Day {dayNum + 1}</h3>
                </div>

                <div style={{ display: 'flex', gap: '1em', width: '80em', marginLeft: '10em' }}>

                  <div className='divGridDailyTable divGridTable tw-mb-4' style={{
                    display: 'grid',
                    gridTemplateColumns: 'auto auto auto',
                  }}>
                    <div className='cellHeader cellBorderLeft'>Day #</div>
                    <div className='cellHeader'>Date</div>
                    <div className='cellHeader'>Daily total</div>
                    <div className='cellData cellBorderLeft'>{dayNum + 1}</div>
                    <div className='cellData'>{dateutcFormatWithWeekday(dateUTC)}</div>
                    <div className='cellData numeric'>{formatNum(lineItems_total)}</div>
                  </div>

                  <div className='memoFlight' style={{
                    border: enableEditing ? '2px dashed silver' : hasFlightInfo ? '1px solid silver' : 'none',
                    width: (enableEditing || hasFlightInfo) ? '14.5em' : undefined,
                  }}>
                    <EditableFieldQuotation
                      fieldname={'flightIntoformation.flightNumber'}
                      validationType=''
                      placeholderText={'Flight number'}
                      {...dayOverallInfo}
                    />
                    <EditableFieldQuotation
                      fieldname={'flightIntoformation.flightOriginDestination'}
                      validationType=''
                      placeholderText={'Flight origin/destination'}
                      {...dayOverallInfo}
                    />
                    <EditableFieldQuotation
                      fieldname={'flightIntoformation.flightTime'}
                      validationType=''
                      placeholderText={'Flight departure/arrival time'}
                      {...dayOverallInfo}
                    />
                    {!enableEditing && hasFlightInfo && (
                      <div className='memoFlightIcon'>
                        <i className='bi bi-airplane'></i>
                      </div>
                    )}

                  </div>

                  <div className='memoTopOfDay' style={{ borderColor: enableEditing ? 'silver' : 'transparent' }}>
                    <EditableFieldQuotation
                      fieldname={'memorandumTop'}
                      validationType=''
                      isTextArea={true}
                      placeholderText={`Click here to input memo for day ${dayNum + 1}`}
                      callbackOnPaste={(e) => callbackOnPasteQuotation(e, 'days', dayOverallInfo.rowObj, 'memorandumTop')}
                      getDisplayValue={renderImages}
                      {...dayOverallInfo}
                    />
                  </div>

                </div>


                <LineItemTable
                  lineItemsThisTable={lineItemsByType['accommodation']}
                  {...propsTables}

                  tablename='accommodation'
                  columns={[
                    { field: 'name', header: 'Accommodation', widthEm: 19 },
                    { field: 'room_type', header: 'Room type', widthEm: 10 },
                    { field: 'room_size', header: 'Room size', widthEm: 10 },
                    { field: 'room_otherFeatures', header: 'Other features', widthEm: 10 },
                    { field: 'room_numberOfPaxPerRoom', header: '# of pax per room', widthEm: 10, isNumeric: true },
                    { field: 'room_pricePerPerson', header: 'Price per person', widthEm: 10, isNumeric: true },
                    { field: 'totalPrice', header: 'Price per room incl. tax', widthEm: 10, isNumeric: true },
                  ]}
                  bgcolor='#8fe'
                />

                <LineItemTable
                  lineItemsThisTable={lineItemsByType['transportation']}
                  {...propsTables}

                  tablename='transportation'
                  columns={[
                    { field: 'name', header: 'Transportation', widthEm: 19 },
                    { field: 'transportation_from', header: 'From', widthEm: 10 },
                    { field: 'transportation_to', header: 'To', widthEm: 10 },
                    { field: 'transportation_carType', header: 'Car type', widthEm: 10 },
                    { field: 'transportation_unitCount', header: '# of hours/pax', widthEm: 10, isNumeric: true },
                    { field: 'transportation_unitPrice', header: 'Price per hour/ticket', widthEm: 10, isNumeric: true },
                    { field: 'totalPrice', header: 'Price incl. tax', widthEm: 10, isNumeric: true },
                  ]}
                  bgcolor='#8ef'
                />

                <LineItemTable
                  lineItemsThisTable={lineItemsByType['tickets']}
                  {...propsTables}

                  tablename='tickets'
                  columns={[
                    { field: 'name', header: 'Tickets', widthEm: 19 },
                    { field: '', header: '', widthEm: 10, },
                    { field: 'ticket_numberOfAdults', header: '# of adults', widthEm: 10, isNumeric: true },
                    { field: 'ticket_pricePerAdult', header: 'Price per adult', widthEm: 10, isNumeric: true },
                    { field: 'ticket_numberOfChildren', header: '# of children', widthEm: 10, isNumeric: true },
                    { field: 'ticket_pricePerChild', header: 'Price per child', widthEm: 10, isNumeric: true },
                    { field: 'totalPrice', header: 'Price incl. tax', widthEm: 10, isNumeric: true },
                  ]}
                  bgcolor='#f8e'
                />

                <LineItemTable
                  lineItemsThisTable={lineItemsByType['guide']}
                  {...propsTables}

                  tablename='guide'
                  columns={[
                    { field: 'name', header: 'Guide', widthEm: 19 },
                    { field: 'guide_accommodation', header: 'Accommodation', widthEm: 10, isNumeric: true },
                    { field: 'guide_transportation', header: 'Transportation', widthEm: 10, isNumeric: true },
                    { field: 'guide_meals', header: 'Meals', widthEm: 10, isNumeric: true },
                    { field: 'guide_numberOfHours', header: '# of hours', widthEm: 10, isNumeric: true },
                    { field: 'guide_pricePerHour', header: 'Price per hour', widthEm: 10, isNumeric: true },
                    { field: 'totalPrice', header: 'Price incl. tax', widthEm: 10, isNumeric: true },
                  ]}
                  bgcolor='#fe8'
                />

                <LineItemTable
                  lineItemsThisTable={lineItemsByType['other']}
                  {...propsTables}

                  tablename='other'
                  columns={[
                    { field: 'name', header: 'Others', widthEm: 19 },
                    { field: '', header: '', widthEm: 10, },
                    { field: '', header: '', widthEm: 10, },
                    { field: '', header: '', widthEm: 10, },
                    { field: '', header: '', widthEm: 10, },
                    { field: '', header: '', widthEm: 10, },
                    { field: 'totalPrice', header: 'Price incl. tax', widthEm: 10, isNumeric: true },
                  ]}
                  bgcolor='#f88'
                />

              </div>
            )
          })}


        </>
      )}

    </div>
  )
}
