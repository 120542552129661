import { HistoryInfoType } from './types_historized.js';
import { UserSimpleUidType } from './types_user.js';


export const invoiceStatusesDropdown = ['EXPECTED', 'RECEIVED', 'NEEDS CHECKING', 'DONE CHECKING', 'SCHEDULED', 'PAID'] as const
export type InvoiceStatusDropdownType = typeof invoiceStatusesDropdown[number]
export const invoiceStatusesAll = /* */['EXPECTED', 'RECEIVED', 'NEEDS CHECKING', 'DONE CHECKING', 'SCHEDULED', 'PAID', 'TRASH', 'DELETED'] as const
export type InvoiceStatusType = typeof invoiceStatusesAll[number]


// Before invoice is saved for the first time, the following 5 fields can be null, until the user inputs them.
// Later, whenever we retrieve the invoices from the db, they will never be null, so we want the main InvoiceType
// to show them as non-null.
export type InvoiceUnsavedType = Omit<InvoiceType,
  | 'amount'
  | 'payeeId'
  | 'payeeNameEn'
  | 'payeeNameJa'
  | 'payeeCategories'
> & {
  amount: number | null,
  payeeId: string | null,
  payeeNameEn: string | null,
  payeeNameJa: string | null,
  payeeCategories: string[] | null,
}

export type InvoiceType = {
  _isDeleted: boolean;
  id: string;
  history: HistoryInfoType;

  amount: number;
  currency: string;
  dateisoInvoiceReceived: string;
  dateisoDeadline: string;
  files: FileStoragePathAndDownloadUrlType[];
  filesDeleted?: FileStoragePathAndDownloadUrlType[];
  filesAll?: FileStoragePathAndDownloadUrlType[]; // filesAll will serve as a record of all files ever uploaded on this invoice, even after they are deleted by the user. (filesDeleted cannot track files that were removed by the user pressing Undo after the upload)
  memorandum: string;
  tripcode: string;
  paxname: string;
  servicePurchased: string;

  payeeId: string;
  payeeNameEn: string;
  payeeNameJa: string;
  payeeCategories: string[];

  paymentDateiso: string; // 'Z' for not yet paid invoices, such that queries for paymentDate > xxx also return unpaid invoices
  paymentSourceAccount: string | null;
  paymentUserEmail: string | null;
  paymentUserUid: string | null;
  paymentSetDatetime: Date | null; // datetime that user set the invoice as PAID/SCHEDULED

  status: InvoiceStatusType;
  statusPaidOrScheduled: boolean;

  // withholding tax
  hasWithholdingTax?: boolean;
  amountBeforeConsumptionTax?: number; // before withholding tax is deducted
  amountConsumptionTax?: number;
  amountAfterConsumptionTax?: number; // before withholding tax is deducted
  amountWithholdingTax?: number;

  // partial assignment
  //partialAssignments: Record<string, InvoicePartialAssignmentType> // Firestore only, not in client
  partialAssignmentList?: InvoicePartialAssignmentType[];             // Client only, not in Firestore

  // Freee status
  freeeStatus?: InvoiceFreeeStatusType;

  // metadata
  dateCreated: Date;
  uid: string;
  userEmail: string;
  userCreatedName: string;

  dateModified: Date;
  userModifiedUid: string;
  userModifiedEmail: string;
  userModifiedName: string;
}

export type InvoiceRawType = Omit<InvoiceType, 'partialAssignmentList'> & {
  partialAssignments: Record<string, Omit<InvoicePartialAssignmentType, 'id'>>,
}

export type InvoiceFreeeStatusType = {
  checked: boolean;
  memorandum: string;
  dateModified: Date;
  userModified: UserSimpleUidType;
}

export type InvoiceContentsPageType = FileStoragePathAndDownloadUrlType & {
  pageNumber: number;
  textContent: null | string;
}

export type ConversionToolType = null | 'cloudmersive' | 'xpdf' | 'gs' // null if invoice was originally an image

export type InvoiceContentsFileType = FileStoragePathAndDownloadUrlType & {
  pages: InvoiceContentsPageType[];
  conversionTool: ConversionToolType;
}

export type InvoiceContentsType = {
  id: string;
  files: InvoiceContentsFileType[];
  dateModified: Date;
  userModified?: UserSimpleUidType; // only present if object was updated manually by user on breakdown page. absent if this is just the object produced by the automatic pdf parser.
}

// copy/pasted from react-image-crop package:
// (we don't import directly because it causes errors when compiling Firebase Functions
// or scripts that do not have the react-image-crop package installed)
export interface PercentCrop {
  x: number;
  y: number;
  width: number;
  height: number;
  unit: '%';
}

export type InvoicePartialAssignmentType = {
  id: string; // nanoid, not Firestore document id. not present in Firestore
  amount: number;
  requestCode: string;
  paxName: string;        // the pax name taken from the Request, or input manually by the user
  paxNameInvoice: string; // the pax name automatically picked up from the invoice
  fileStoragePath: string;
  pageNumber: number;
  crop: PercentCrop;
  isAutoBreakdown: boolean;
  autoBreakdownData: string[] | null;
  needsManualConfirmation: boolean; // if this assignment was generated automatically

  // metadata
  userCreated: UserSimpleUidType;
  dateCreated: Date;
  userModified: UserSimpleUidType;
  dateModified: Date;
}

export type FileStoragePathAndDownloadUrlType = {
  storagePath: string;
  downloadURL: string;
}

