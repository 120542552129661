import { useAppContext } from 'src/hooks/useAppContext';
import { AutosaveSheetType } from 'src/pages/ExpenseSheet/ExpenseSheet';
import { ExpenseSheetType } from 'src/types/types_expensesheet';
import { dateFormatJpShort } from 'src/util/dateformattools';
import { formatNum } from 'src/util/util_formatnum';
import { EditableField } from './EditableField';


interface EditableFieldExpensesAutosaveProps {
  sheet: ExpenseSheetType;
  rowid: string;
  tableid: keyof ExpenseSheetType;
  fieldname: string;
  currentValue: any;
  validationType: '' | 'number';
  isClickableToEdit: boolean;
  editedCell: string | null;
  setEditedCell: (cellid: string | null) => void;
  autosaveNewStep: AutosaveSheetType;
  sUndoWall: 'u' | 'UNDOWALL';
  nextField?: string;
  previousField?: string;
  customSaveCallback?: (dbvalue: any) => (Partial<ExpenseSheetType> | null);
  callbackOnChange?: (value: string) => void; // called on each key press. used for showing station name suggestions etc.
  isTextArea?: boolean;
}

export function EditableFieldExpensesAutosave({
  sheet,
  rowid,
  tableid,
  fieldname,
  currentValue,
  validationType,
  isClickableToEdit,
  editedCell,
  setEditedCell,
  autosaveNewStep,
  sUndoWall,
  nextField,
  previousField,
  customSaveCallback,
  callbackOnChange, // called on each key press. used for showing station name suggestions etc.
  isTextArea,
}: EditableFieldExpensesAutosaveProps) {

  const { setDbError } = useAppContext()

  // convert non-string types (number, date) to string for displaying to user
  // if (validationType === 'datepicker')
  //   currentValue = dateFormatJpShort(currentValue)
  // -> now currentValue comes in as Date, and is fed to date picker as Date
  if (validationType === 'number') {
    // currentValue can be null
    currentValue = formatNum(currentValue)
  }

  const callbackCommitChange = (dbvalue: any, tabKey: -1 | 1 | undefined) => {

    const rowList = sheet[tableid]
    if (!rowList || !Array.isArray(rowList)) {
      console.error(`EditableFieldExpenses: sheet[${tableid}] is not an array`)
      return
    }

    let updateObj: Partial<ExpenseSheetType> | null = null
    if (customSaveCallback) {
      updateObj = customSaveCallback(dbvalue)
    } else {
      if (currentValue !== dbvalue) {
        const rowListNew = rowList.map((item) => {
          if (!('id' in item)) {
            console.error('field id not found in row object', item)
            throw new Error('field id not found in row object')
          }

          if (item.id === rowid) {
            return {
              ...item,
              [fieldname]: dbvalue,
            }
          } else {
            return item
          }
        })
        updateObj = {
          [tableid]: rowListNew,
        }
      }
    }

    if (updateObj) {
      autosaveNewStep(`Input ‘${(dbvalue instanceof Date) ? dateFormatJpShort(dbvalue) : dbvalue}’ in ${tableid} ${fieldname}`, updateObj, sUndoWall)
        .catch((err) => setDbError('Autosave expense sheet', err))
    }

    if (tabKey === 1 && nextField) {
      const nextFieldId = `${tableid}_${rowid}_${nextField}`
      setEditedCell(nextFieldId)
    } else if (tabKey === -1 && previousField) {
      const previousFieldId = `${tableid}_${rowid}_${previousField}`
      setEditedCell(previousFieldId)
    } else {
      setEditedCell('')
    }
  }

  return (
    <EditableField
      tableid={tableid}
      rowid={rowid}
      fieldname={fieldname}
      validationType={validationType}
      currentValue={currentValue}
      isClickableToEdit={isClickableToEdit}
      editedCell={editedCell}
      setEditedCell={setEditedCell}
      callbackCommitChange={callbackCommitChange}
      callbackOnChange={callbackOnChange} // on each key press
      isTextArea={isTextArea}
    />
  )
}
