import { PillListMutuallyExclusiveType, PillListType, RequestStatusOnePill } from './RequestStatusOnePill'



interface RequestStatusPillEditorProps {
  selectedPill: PillListMutuallyExclusiveType | undefined // never undefined in practice when displayed to user, as all requests have a status set
  vertical: boolean
  clickHandler: (clicked: PillListType) => void
}

export function RequestStatusPillEditor({ // shows all statuses, and allows used to click to change the tour's status
  selectedPill,
  vertical,
  clickHandler,
}: RequestStatusPillEditorProps) {


  const isConfirmed = selectedPill === 'Pre-tour' || selectedPill === 'On-tour' || selectedPill === 'Post-tour' || selectedPill === 'Archived'

  return (
    <div className={`tw-flex tw-gap-1 ${vertical ? 'tw-flex-col' : ''}`} style={{ fontSize: '0.875em', }}>
      <RequestStatusOnePill
        label='ONGOING'
        selected={selectedPill === 'ONGOING'}
        clickHandler={clickHandler}
      />
      <RequestStatusOnePill
        label='LOST'
        selected={selectedPill === 'LOST'}
        clickHandler={clickHandler}
      />
      <RequestStatusOnePill
        label='CANCELLED'
        selected={selectedPill === 'CANCELLED'}
        clickHandler={clickHandler}
      />
      <RequestStatusOnePill
        label='CONFIRMED'
        selected={isConfirmed}
        clickHandler={clickHandler}
      />
      {isConfirmed && (
        <div className={`tw-flex tw-gap-1 ${vertical ? 'tw-flex-col' : ''} tw-px-2`}>
          <RequestStatusOnePill
            label='Pre-tour'
            selected={selectedPill === 'Pre-tour'}
            clickHandler={clickHandler}
          />
          <RequestStatusOnePill
            label='On-tour'
            selected={selectedPill === 'On-tour'}
            clickHandler={clickHandler}
          />
          <RequestStatusOnePill
            label='Post-tour'
            selected={selectedPill === 'Post-tour'}
            clickHandler={clickHandler}
          />
          <RequestStatusOnePill
            label='Archived'
            selected={selectedPill === 'Archived'}
            clickHandler={clickHandler}
          />
        </div>
      )}
    </div>
  )

}
