import { Analytics } from 'firebase/analytics';
import { Auth } from 'firebase/auth';
import { Firestore } from 'firebase/firestore';
import { Functions } from 'firebase/functions';
import { FirebaseStorage } from 'firebase/storage';
import { Dispatch, SetStateAction, createContext } from 'react';
import { UserPermissionAllEnumType } from 'src/util/user_roles';
import { currentErrorType, setDbErrorType } from '../hooks/useDbError';
import { LanguagePreferenceType, UserDetailsType } from '../types/types_user';

export type IAppContext = {
  userDetails: UserDetailsType;
  db: Firestore;
  storage: FirebaseStorage;
  auth: Auth;
  cloudFunctions: Functions;
  currentError: currentErrorType | null;
  setDbError: setDbErrorType;
  analytics: Analytics;
  _lang: (en: string, ja: string) => string;
  setLang: (lang: LanguagePreferenceType) => void;
  cacheRequestCodes: Set<string>;
  navbarClickNewRequestCounter: number;
  setNavbarClickNewRequestCounter: Dispatch<SetStateAction<number>>;
  perm: (permission: UserPermissionAllEnumType) => boolean;
}

export const AppContext = createContext<IAppContext | null>(null)
