import { useMemo } from 'react'
import { AgencyType } from 'src/types/types_agencies'
import { iso_from_jst0 } from 'src/util/datetools'
import './agencymonthlystats.css'


interface AgencyMonthlyStatsProps {
  agencyList: AgencyType[]
}

export function AgencyMonthlyStats({ agencyList }: AgencyMonthlyStatsProps) {

  const [month_num, month_agency, month_designer, month_agency_designer] = useMemo(() => {

    const month_num = new Map<string, number>() // month => num
    const month_agency = new Map<string, Map<string, number>>() // month => agency => num
    const month_designer = new Map<string, Map<string, number>>() // month => userCreated => num
    const month_agency_designer = new Map<string, Map<string, Map<string, number>>>() // month => agency => userCreated => num

    for (const agency of agencyList) {

      for (const [reqId, req] of Object.entries(agency.requestMap)) {

        const sDate = iso_from_jst0(req.dateCreated)
        const sMonth = sDate.substring(0, 7)

        if (!month_num.has(sMonth)) {
          month_num.set(sMonth, 0)
          month_agency.set(sMonth, new Map())
          month_designer.set(sMonth, new Map())
          month_agency_designer.set(sMonth, new Map())
        }

        month_num.set(sMonth, month_num.get(sMonth)! + 1)
        const map_agency = month_agency.get(sMonth)!
        const map_designer = month_designer.get(sMonth)!
        const map_agency_designer1 = month_agency_designer.get(sMonth)!

        map_agency.set(agency.name, (map_agency.get(agency.name) || 0) + 1)
        map_designer.set(req.userCreated.email, (map_designer.get(req.userCreated.email) || 0) + 1)

        if (!map_agency_designer1.has(agency.name)) map_agency_designer1.set(agency.name, new Map())
        const map_agency_designer2 = map_agency_designer1.get(agency.name)!
        map_agency_designer2.set(req.userCreated.email, (map_agency_designer2.get(req.userCreated.email) || 0) + 1)

      }
    }

    return [month_num, month_agency, month_designer, month_agency_designer]

  }, [agencyList])

  const monthNames = Array.from(month_num.keys())
  monthNames.sort((a, b) => -a.localeCompare(b))


  return (
    <div>
      <table className='table'>
        <thead>
          <tr>
            <th>Month</th>
            <th>Requests</th>
          </tr>
        </thead>
        <tbody>
          {monthNames.map((sMonth) => {

            const map_agency = month_agency.get(sMonth)!
            const map_designer = month_designer.get(sMonth)!
            const map_agency_designer = month_agency_designer.get(sMonth)!

            const agencies = Array.from(map_agency.keys())
            agencies.sort((a, b) => {
              return map_agency.get(a)! - map_agency.get(b)!
            })
            agencies.reverse()
            // console.log('agencies', agencies)

            const designers = Array.from(map_designer.keys())
            designers.sort((a, b) => {
              return map_designer.get(a)! - map_designer.get(b)!
            })
            designers.reverse()

            return (
              <tr key={sMonth}>
                <td>{sMonth}</td>
                <td>
                  <table className='table monthlyTable'>
                    <thead>
                      <tr>
                        <th className='blank'></th>
                        <th className='blank'></th>
                        {designers.map((d) => <th key={d} className='headerTitle'>{d}</th>)}
                      </tr>
                      <tr>
                        <th className='blank'></th>
                        <th className='headerCount'>{month_num.get(sMonth)}</th>
                        {designers.map((d) => <th key={d} className='headerCount'>{map_designer.get(d)}</th>)}
                      </tr>
                    </thead>
                    <tbody>
                      {agencies.map((agency) => {
                        return (
                          <tr key={agency}>
                            <th className='headerTitle'>{agency}</th>
                            <th className='headerCount'>{map_agency.get(agency)}</th>
                            {designers.map((d) => <td key={`${agency} _ ${d}`}>{map_agency_designer.get(agency)!.get(d)}</td>)}
                          </tr>
                        )
                      })}
                    </tbody>
                  </table>
                </td>
              </tr>
            )
          })}
        </tbody>
      </table>
    </div>
  )
}
