import { PercentCrop } from 'react-image-crop';
import { setDbErrorType } from 'src/hooks/useDbError';
import { InvoiceContentsFileType, InvoicePartialAssignmentType } from 'src/types/types_invoices';
import { serverTimestampAsDate } from 'src/util/util_firestoredates';
import { nano_id } from 'src/util/util_nano_id';


export const PAYEE_ID_AIRSERVE = 'fhC6kNrZPXkQO1toA18d'

const products = [
  'MEET＆ASSIST',
  '立替金',
  '交通費',
  '時間外対応料金',
  '深夜早朝割増料金',
]

function matchLine(line: string) {
  return line.match(/^(?<carte>\d{7,}) +(?<kubun>SD) +(?<airport>[^ ]+) +(?<date>\d{2}\/\d{2}) +(?<flight>\S+) +(?<product>[^\d ,]+) *(?<numpax>\d+ +)?(?<unitprice>[\d,]+) +(?<qty>\d+) +(?<amount>[\d,]+) +(?<tax>[\d,]+) +(?<comment>.*)$/)
}


export function formatLine_airserve(line: string) {
  const match = matchLine(line)
  if (match) {
    return (
      <span>
        <span className='invoice_line_parsed_gray'>{match[1]}</span>{' '}
        <span className='invoice_line_parsed_gray'>{match[2]}</span>{' '}
        <span className='invoice_line_parsed_black'>{match[3]}</span>{' '}
        <span className='invoice_line_parsed_green'>{match[4]}</span>{' '}
        <span className='invoice_line_parsed_black'>{match[5]}</span>{' '}
        <span className='invoice_line_parsed_gray'>{match[6]}</span>{' '}
        <span className='invoice_line_parsed_gray'>{match[7]}</span>{' '}
        <span className='invoice_line_parsed_gray'>{match[8]}</span>{' '}
        <span className='invoice_line_parsed_gray'>{match[9]}</span>{' '}
        <span className='invoice_line_parsed_blue'>{match[10]}</span>{' '}
        <span className='invoice_line_parsed_green'>{match[11]}</span>{' '}
        <span className='invoice_line_parsed_blue'>{match[12]}</span>{' '}
      </span>
    )
  }

  return line
}



export function auto_breakdown_airserve(
  invoiceId: string,
  selectedFile: string,
  selectedFileContents: InvoiceContentsFileType | undefined,
  partialAssignmentList: InvoicePartialAssignmentType[],
  setDbError: setDbErrorType,
  autosaveNewStep: (
    userAction: string,
    updateObj: any,
    sUndoWall: 'u' | 'UNDOWALL',
  ) => Promise<void>,
) {
  if (!selectedFileContents) {
    window.alert('No file contents')
    return
  }
  if (!selectedFileContents.pages || selectedFileContents.pages.length === 0) {
    window.alert('No pages')
    return
  }
  if (selectedFileContents.conversionTool !== 'xpdf') {
    window.alert(`Please contact Laurent for XPDF conversion. invoiceId=${invoiceId}`)
    return
  }
  if (partialAssignmentList.length > 0) {
    if (!window.confirm('This invoice already has some rows in the breakdown table. Running auto-breakdown may result in duplicate rows. Continue?'))
      return
  }


  const updateObj: Record<string, any> = {}

  const userSimpleAuto = {
    uid: '',
    name: 'Auto',
    email: '',
  }

  const carteRequestCodeMap = new Map<string, string>()

  for (let iPage = 0; iPage < selectedFileContents.pages.length; iPage++) {

    const page = selectedFileContents.pages[iPage]
    if (!page.textContent)
      continue
    const pageLines = page.textContent.split('\n')

    for (let iLine = 0; iLine < pageLines.length; iLine++) {
      let line = pageLines[iLine]
      const match_1 = line.match(/^\d{7,}/)
      if (!match_1)
        continue

      // insert missing spaces
      products.forEach((product) => {
        line = line.replace(product, ` ${product}`)
      })

      const match = matchLine(line)
      if (!match) {
        console.log("didn't match")
        throw new Error(line)
      }

      const carte = match.groups!.carte

      // console.log(`line num ${iLine}`)

      const sAmount = match.groups!.amount
      const amount = Number(sAmount.replaceAll(',', ''))
      // console.log(amount)

      const sTax = match.groups!.tax
      const tax = Number(sTax.replaceAll(',', ''))

      const price = amount + tax

      if (isNaN(price))
        throw new Error(`invalid price on line [${line}]`)

      const comment = match.groups!.comment.trim()

      let requestCode = ''
      let paxNameInvoice = ''

      const match_requestcode = comment.match(/^(?<code>F\d{9}|[A-Z0-9]{4}-[A-Z0-9]{4})(?:[^A-Z0-9]|$)/)
      if (match_requestcode) {
        requestCode = match_requestcode.groups!.code
        let remainder = comment.slice(requestCode.length).trim()
        if (remainder.startsWith('/') || remainder.startsWith('-'))
          remainder = remainder.slice(1).trim()
        if (remainder)
          paxNameInvoice = remainder
      }

      const prevRequestCode = carteRequestCodeMap.get(carte)
      if (requestCode) {
        if (prevRequestCode) {
          if (prevRequestCode !== requestCode) {
            console.error(`carte ${carte} has multiple request codes: ${prevRequestCode} vs ${requestCode}`)
          }
        }
        carteRequestCodeMap.set(carte, requestCode)
      } else {
        if (prevRequestCode) {
          requestCode = prevRequestCode
        }
      }


      const crop: PercentCrop = {
        x: 5,
        width: 85,
        y: (iPage === 0 ? 6.7 : 18.5) + iLine * 0.84,
        height: 1.8,
        unit: '%',
      }


      const newPartialAssignment: InvoicePartialAssignmentType = { // here we explicitly type, so that if fields are added in the future, they will not be forgotten here
        id: nano_id(),
        amount: price,
        requestCode,
        paxName: '',
        paxNameInvoice,
        fileStoragePath: selectedFile,
        pageNumber: 1 + iPage,
        crop: crop,
        isAutoBreakdown: true,
        autoBreakdownData: [line],
        needsManualConfirmation: true,
        //metadata
        userCreated: userSimpleAuto,
        dateCreated: serverTimestampAsDate(),
        userModified: userSimpleAuto,
        dateModified: serverTimestampAsDate(),
      }

      const partialId = newPartialAssignment.id
      const { id: _, ...dbObj } = newPartialAssignment

      updateObj[`partialAssignments.${partialId}`] = dbObj


    } // each line of text
  } // each page


  autosaveNewStep('Auto-assigning breakdown: Airserve', updateObj, 'UNDOWALL')
    .catch((err) => setDbError('Auto-assigning breakdown', err))

}
