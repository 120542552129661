import { QuerySnapshot, addDoc, collection, deleteField, doc, getDoc, onSnapshot, query, serverTimestamp, updateDoc, where } from 'firebase/firestore';
import { httpsCallable } from 'firebase/functions';
import { unflatten } from 'flat';
import { useEffect, useMemo, useState } from 'react';
import { Dropdown, DropdownButton, Form } from 'react-bootstrap';
import { Helmet } from 'react-helmet-async';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { BeatLoader } from 'react-spinners';
import { ButtonTW } from 'src/components/Buttons/ButtonTW';
import { CheckboxSwitch } from 'src/components/Buttons/CheckboxSwitch';
import { TypeaheadUserList } from 'src/components/FormControls/TypeaheadUserList';
import { getLoadingSpinnerOrNull } from 'src/components/Spinner/util_getLoadingSpinnerOrNull';
import { useAppContext } from 'src/hooks/useAppContext';
import { CommuteMonthlyType, EitherLegType } from 'src/types/types_commute';
import { UserDetailsType, UserSimpleTeamType, UserSimpleType } from 'src/types/types_user';
import { dateFormatJpShortWithTime, dateutcFormatJp } from 'src/util/dateformattools';
import { addMonthsUtc, getTodayUTC, iso_from_utc0, jst0_from_utc0, parseIsoDateToUTC } from 'src/util/datetools';
import { userrole_canMarkPaid, userrole_isAdmin } from 'src/util/user_roles';
import { convertCommuteMonthlyDates } from 'src/util/util_firestoredates';
import { formatNum } from 'src/util/util_formatnum';
import { isHoliday } from 'src/util/util_holidays';
import { arraySum } from 'src/util/util_misc';
import { getMonthList } from 'src/util/util_monthlist';
import { useEmergencyPhoneList } from '../Admin/util_emergencyphonelist';
import { useUserListSimple } from '../ExpenseSheet/util_getuserlist';
import { CommutingExpenseCell } from './CommutingExpenseCell';
import { EmergencyPhoneCell } from './EmergencyPhoneCell';
import { ModalTripInput, ModalTripInputActionType } from './ModalTripInput';
import './commutingexpenses.css';
import { calculateTotals, commuteMonthlyCap, getDateutcList, phoneCostHoliday, phoneCostNight } from './util_commutingexpenses';




type MonthlyExpenseCategoryType = 'commuteToWork' | 'commuteFromWork' | 'otherTransportationExpenses' | 'otherNonTransportationExpenses' | 'emergencyPhone'

export type CurrentActionType = {
  action: 'delete';
  cellId: string;
} | {
  action: 'copypaste';
  cellId: string;
  category: string;
  legList: EitherLegType[];
}


export function CommutingExpenses() {

  const { userDetails, perm, db, setDbError, cloudFunctions, _lang } = useAppContext()

  const navigate = useNavigate()

  const [searchParams] = useSearchParams()
  const paramEmployeeId = searchParams.get('employeeId')
  const paramMonth = searchParams.get('month')
  if (paramMonth) {
    if (!paramMonth.match(/^\d{4}-\d{2}$/))
      throw new Error('Invalid month parameter')
  }

  const isPaymentIssuer = userrole_canMarkPaid(userDetails.roles)
  const isAdmin = userrole_isAdmin(userDetails.roles)
  const canEditAnyUser = perm('commute_edit_all')

  const showNonCommutingTransportationExpenses = true
  const showNonTransportationExpenses = false
  const showEmergencyPhone = true

  const daysOfWeek = new Map([
    [0, _lang('Sun', '日')],
    [1, _lang('Mon', '月')],
    [2, _lang('Tue', '火')],
    [3, _lang('Wed', '水')],
    [4, _lang('Thu', '木')],
    [5, _lang('Fri', '金')],
    [6, _lang('Sat', '土')],
  ])



  const [modalAction, setModalAction] = useState<ModalTripInputActionType | null>(null)

  const [commuteMonthlyDict, setCommuteMonthlyDict] = useState<Map<string, CommuteMonthlyType>>() // month => CommuteMonthly

  const [editMode, setEditMode] = useState(false)

  const [currentAction, setCurrentAction] = useState<CurrentActionType | null>(null) // either copying (to paste) or deleting. always has member cellId.

  if (!editMode && currentAction) setCurrentAction(null)

  const userListSimple = useUserListSimple()
  const [selectedEmployee, setSelectedEmployee] = useState<UserSimpleType | null>(null)

  const currentMonth = iso_from_utc0(getTodayUTC()).substring(0, 7)
  // const [selectedMonth, setSelectedMonth] = useState(paramMonth || currentMonth)
  const selectedMonth = paramMonth || currentMonth

  const [selectedPhone, setSelectedPhone] = useState('')


  const monthList = useMemo(() => {
    return getMonthList(2023, 10)
  }, [])

  if (!monthList.includes(selectedMonth))
    throw new Error('Invalid month parameter')

  const userBasic = useMemo<UserSimpleTeamType>(() => {
    return {
      id: userDetails.id,
      email: userDetails.email,
      name: userDetails.displayNameEn,
      teamName: userDetails.teamName,
    }
  }, [userDetails])

  // initialize with current user
  useEffect(() => {
    if (!userListSimple)
      // still loading
      return

    if (paramEmployeeId) {
      const selectedUser = userListSimple.find((u) => u.id === paramEmployeeId)
      if (!selectedUser)
        throw new Error('Invalid employeeId parameter')
      setSelectedEmployee(selectedUser)
    } else {
      setSelectedEmployee(userBasic)
    }
  }, [userBasic, paramEmployeeId, userListSimple])


  // get list of direct reports
  const [directreports, setDirectreports] = useState<UserSimpleTeamType[]>()
  useEffect(() => {
    setDirectreports([]) // clear previous list while loading (setting undefined here will bring back the spinner)

    const processSnapshot = function (snapshot: QuerySnapshot) {
      const list: UserSimpleTeamType[] = []
      for (const docu of snapshot.docs) {
        const userdetails = { ...docu.data(), id: docu.id } as UserDetailsType
        const user: UserSimpleTeamType = {
          id: docu.id,
          name: userdetails.displayNameEn,
          email: userdetails.email,
          teamName: userdetails.teamName,
        }
        list.push(user)
      }
      setDirectreports(list)
    }

    const q = query(collection(db, 'users'), where('manager.uid', '==', userDetails.id))
    const unsubscribe = onSnapshot(q, processSnapshot, (err) => setDbError('Getting direct reports list', err));

    return unsubscribe

  }, [db, setDbError, userDetails.id])


  // get default emergency phone
  useEffect(() => {
    if (!selectedEmployee) {
      setSelectedPhone('')
      return
    }

    if (selectedEmployee.id !== userBasic.id && !isAdmin) {
      // currently only admin has right to read other user's preferences
      setSelectedPhone('')
      return
    }

    getDoc(doc(db, 'users', selectedEmployee.id))
      .then((docu) => {
        const user = docu.data()!
        setSelectedPhone(user.preferences?.defaultEmergencyPhone || '')
      })
      .catch((err) => setDbError('Getting user details', err))
  }, [db, setDbError, selectedEmployee, isAdmin, userBasic.id])

  useEffect(() => {

    if (!selectedEmployee) {
      setCommuteMonthlyDict(new Map())
      return
    }

    const processSnapshot = function (snapshot: QuerySnapshot) {
      const commuteMonthlyDict = new Map()
      for (const doc of snapshot.docs) {
        const commuteMonthly = { ...doc.data(), id: doc.id } as CommuteMonthlyType
        convertCommuteMonthlyDates(commuteMonthly)
        if (commuteMonthlyDict.has(commuteMonthly.month)) {
          const errorMessage = `Duplicate CommuteMonthly for month ${commuteMonthly.month} user ${selectedEmployee.id} (${selectedEmployee.name}): ${commuteMonthlyDict.get(commuteMonthly.month).id} and ${commuteMonthly.id}`
          setDbError(errorMessage)
          throw new Error(errorMessage)
        }
        commuteMonthlyDict.set(commuteMonthly.month, commuteMonthly)
      }
      // console.log('map of monthlies', commuteMonthlyDict)
      setCommuteMonthlyDict(commuteMonthlyDict)
    }

    // console.log('selectedEmployee', selectedEmployee)

    const q = query(collection(db, 'commutemonthlies'), where('employee.id', '==', selectedEmployee.id))
    const unsubscribe = onSnapshot(q, processSnapshot, (err) => setDbError('Getting commutemonthlies list', err));

    return unsubscribe

  }, [db, setDbError, selectedEmployee])


  function saveToCommuteMonthlyBase(commuteMonthlyId: string | undefined, sIsoDate: string, updatedFields: Record<string, any>) {

    if (!commuteMonthlyId) {
      const month = sIsoDate.substring(0, 7)
      const commuteMonthlyTemplate = {
        employee: { ...selectedEmployee },
        dateCreated: serverTimestamp(),
        dateModified: serverTimestamp(),
        userCreated: { ...userBasic },
        userModified: { ...userBasic },

        month: '', // '2023-10'
        commuteToWork: {},
        commuteFromWork: {},
        otherTransportationExpenses: {},
        otherNonTransportationExpenses: {},
      }

      // nested fields with dot notation is not supported by addDoc (only works with updateDoc), so we unflatten here:
      // e.g. {'commuteToWork.2024-03-01': [{id:..., legCost:...}]}
      // becomes {'commuteToWork': {'2024-03-01': [{id:..., legCost:...}]}}
      const updatedFieldsUnflat = unflatten(updatedFields) as Record<string, any>

      const newMonthly = {
        ...commuteMonthlyTemplate,
        month: month,
        ...updatedFieldsUnflat
      }
      addDoc(collection(db, 'commutemonthlies'), newMonthly)
        .catch((err) => setDbError('Adding commutemonthly', err))
    } else {
      const updateObj = {
        ...updatedFields,
        dateModified: serverTimestamp(),
        userModified: { ...userBasic },
      }
      updateDoc(doc(db, 'commutemonthlies', commuteMonthlyId), updateObj)
        .catch((err) => setDbError('Updating commutemonthly', err))
    }
  }

  function saveToCommuteMonthly(commuteMonthlyId: string | undefined, category: MonthlyExpenseCategoryType, sIsoDate: string, value: any) {
    const updatedFields = {
      [`${category}.${sIsoDate}`]: value,
    }
    saveToCommuteMonthlyBase(commuteMonthlyId, sIsoDate, updatedFields)
  }

  const commuteMonthly = commuteMonthlyDict?.get(selectedMonth)

  const editModeSwitchEnabled =
    !!selectedEmployee
    && (canEditAnyUser || selectedEmployee.id === userBasic.id)
    && (!commuteMonthly || commuteMonthly.status !== 'APPROVED')

  // make sure editing is off if checkbox is disabled
  useEffect(() => {
    if (!editModeSwitchEnabled)
      setEditMode(false)
  }, [editModeSwitchEnabled])


  const approve_commute = httpsCallable(cloudFunctions, 'approve_commute')

  const [awaitingApproval, setAwaitingApproval] = useState(false)

  const emergencyPhoneList = useEmergencyPhoneList()

  // **** all hooks above

  const loadingSpinner = getLoadingSpinnerOrNull([
    ['commute data', commuteMonthlyDict],
    ['user list', userListSimple && directreports],
    ['emergency phones', emergencyPhoneList],
  ])
  if (!commuteMonthlyDict || !userListSimple || !directreports || !emergencyPhoneList)
    return loadingSpinner


  let availableUsers: UserSimpleTeamType[]
  if (isPaymentIssuer) {
    availableUsers = userListSimple
  } else {
    availableUsers = [userBasic, ...directreports]
  }

  const canApproveCommuteSheet = selectedEmployee && (isPaymentIssuer || directreports?.some((u) => u.id === selectedEmployee.id))

  if (selectedEmployee && selectedEmployee.id !== userBasic.id && !canApproveCommuteSheet) {
    // maybe user input employee id directly in URL
    // they should not be viewing if it's not theirs and they're not allowed to approve it
    setDbError('Access denied')
  }


  const firstDateUTC = parseIsoDateToUTC(`${selectedMonth}-01`)
  const monthEndUTC = addMonthsUtc(firstDateUTC, 1)
  const monthEndJST = jst0_from_utc0(monthEndUTC)
  const monthIsInPast = monthEndJST < new Date()

  const commuteDefault = commuteMonthlyDict.get('DEFAULT')
  const commuteDefaultToWorkLegList = commuteDefault?.commuteToWork?.DEFAULT
  const commuteDefaultFromWorkLegList = commuteDefault?.commuteFromWork?.DEFAULT

  let defaultCost = 0
  if (commuteDefaultToWorkLegList && commuteDefaultToWorkLegList.length) {
    defaultCost += arraySum(commuteDefaultToWorkLegList.map((leg) => leg.legCost ?? 0))
  }
  if (commuteDefaultFromWorkLegList && commuteDefaultFromWorkLegList.length) {
    defaultCost += arraySum(commuteDefaultFromWorkLegList.map((leg) => leg.legCost ?? 0))
  }

  const dates = []

  let numWorkDays = 0

  const dateutcList = getDateutcList(selectedMonth)


  const monthlyTotals = calculateTotals(dateutcList, commuteMonthly)

  for (const dateutc of dateutcList) {

    const sDate = dateutcFormatJp(dateutc)
    const sIsoDate = iso_from_utc0(dateutc)
    const sMonth = sIsoDate.substring(0, 7)
    if (sMonth !== selectedMonth) throw new Error(`unreachable code months inconsistent: ${sMonth} !== ${selectedMonth}`)

    const dayOfWeek = dateutc.getUTCDay()
    const sDayOfWeek = daysOfWeek.get(dayOfWeek)
    const weekend = isHoliday(dateutc)
    if (!weekend) numWorkDays++

    if (dateutc === firstDateUTC)
      console.log('commuteMonthly', commuteMonthly)

    const hasAtLeastOneWay = !!(commuteMonthly?.commuteToWork?.[sIsoDate] || commuteMonthly?.commuteFromWork?.[sIsoDate])

    const getTwoWayActions = () => {

      if (!editMode)
        return null

      const thisCellId = `bothways.${sIsoDate}`

      if (currentAction && currentAction.action === 'delete' && currentAction.cellId === thisCellId) {
        return (
          <>
            <ButtonTW variant='blue_outline' onClick={(e) => {
              saveToCommuteMonthlyBase(commuteMonthly?.id, sIsoDate, {
                [`${'commuteToWork'}.${sIsoDate}`]: deleteField(),
                [`${'commuteFromWork'}.${sIsoDate}`]: deleteField(),
              })
              setCurrentAction(null)
            }}>{_lang('Confirm Delete', '削除を確定')}</ButtonTW>
            {' '}
            <ButtonTW variant='blue_outline' onClick={(e) => {
              setCurrentAction(null)
            }}>{_lang('Cancel', 'キャンセル')}</ButtonTW>
          </>
        )
      }

      return (
        <>
          <ButtonTW variant='blue_outline' onClick={(e) => {

            const defaultToWork = commuteDefault?.commuteToWork?.DEFAULT
            const defaultFromWork = commuteDefault?.commuteFromWork?.DEFAULT

            if (!defaultToWork && !defaultFromWork)
              return

            const updatedFields: Record<string, any> = {}

            if (defaultToWork)
              updatedFields[`${'commuteToWork'}.${sIsoDate}`] = defaultToWork

            if (defaultFromWork)
              updatedFields[`${'commuteFromWork'}.${sIsoDate}`] = defaultFromWork

            saveToCommuteMonthlyBase(commuteMonthly?.id, sIsoDate, updatedFields)

          }}>{_lang('Set default both ways', '行き帰りともをデフォルトにする')}</ButtonTW>
          {' '}
          <ButtonTW variant={hasAtLeastOneWay ? 'blue_outline' : 'darkgray_outline'} onClick={(e) => {
            if (!hasAtLeastOneWay)
              return
            setCurrentAction({
              action: 'delete',
              cellId: thisCellId,
            })
          }}>{_lang('Delete both ways', '行き帰りともを削除')}</ButtonTW>
        </>
      )

    }

    const legListOtherTransportation = commuteMonthly?.otherTransportationExpenses?.[sIsoDate]
    const listOtherNonTransportation = commuteMonthly?.otherNonTransportationExpenses?.[sIsoDate]
    const listEmergencyPhone = commuteMonthly?.emergencyPhone?.[sIsoDate]

    const legListToWork = commuteMonthly?.commuteToWork?.[sIsoDate]
    const legListFromWork = commuteMonthly?.commuteFromWork?.[sIsoDate]

    dates.push(
      <div key={sDate} className={`row ${weekend ? 'weekend' : ''}`}>
        <div className='grid-col1'>
          <b>{sDate} ({sDayOfWeek})</b>
          {getTwoWayActions()}
        </div>

        <CommutingExpenseCell
          editMode={editMode}
          currentAction={currentAction}
          setCurrentAction={setCurrentAction}
          setModalAction={setModalAction}
          legList={legListToWork}
          saveLegList={(legList) => {
            saveToCommuteMonthly(commuteMonthly?.id, 'commuteToWork', sIsoDate, legList)
          }}
          category={'commuteToWork'}
          sIsoDate={sIsoDate}
          defaultLegList={commuteDefault?.commuteToWork?.DEFAULT}
        />

        <CommutingExpenseCell
          editMode={editMode}
          currentAction={currentAction}
          setCurrentAction={setCurrentAction}
          setModalAction={setModalAction}
          legList={legListFromWork}
          saveLegList={(legList) => {
            saveToCommuteMonthly(commuteMonthly?.id, 'commuteFromWork', sIsoDate, legList)
          }}
          category={'commuteFromWork'}
          sIsoDate={sIsoDate}
          defaultLegList={commuteDefault?.commuteFromWork?.DEFAULT}
        />

        {showNonCommutingTransportationExpenses && (
          <CommutingExpenseCell
            editMode={editMode}
            currentAction={currentAction}
            setCurrentAction={setCurrentAction}
            setModalAction={setModalAction}
            legList={legListOtherTransportation}
            saveLegList={(legList) => {
              saveToCommuteMonthly(commuteMonthly?.id, 'otherTransportationExpenses', sIsoDate, legList)
            }}
            category={'otherTransportationExpenses'}
            sIsoDate={sIsoDate}
          />
        )}

        {showNonTransportationExpenses && (
          <CommutingExpenseCell
            editMode={editMode}
            currentAction={currentAction}
            setCurrentAction={setCurrentAction}
            setModalAction={setModalAction}
            legList={listOtherNonTransportation}
            saveLegList={(legList) => {
              saveToCommuteMonthly(commuteMonthly?.id, 'otherNonTransportationExpenses', sIsoDate, legList)
            }}
            category={'otherNonTransportationExpenses'}
            sIsoDate={sIsoDate}
          />
        )}

        {showEmergencyPhone && (
          <EmergencyPhoneCell
            editMode={editMode}
            legList={listEmergencyPhone}
            saveLegList={(list) => {
              saveToCommuteMonthly(commuteMonthly?.id, 'emergencyPhone', sIsoDate, list)
            }}
            selectedPhone={selectedPhone}
            isHoliday={weekend}
          />
        )}

      </div>
    )
  }

  return (
    <div className='container-fluid'>
      <Helmet><title>Commuting Expenses</title></Helmet>
      <h2 className='my-3'>{_lang('Commuting Expenses', '通勤交通費')}</h2>

      <div className='toolbar'>

        <div className='userSelector'>
          <TypeaheadUserList
            id='select-employee'
            multiple={false}
            onChange={(array) => {
              setEditMode(false)
              const newlySelectedEmployee = array.length ? array[0] : null
              if (newlySelectedEmployee) {
                navigate(`/commute/?employeeId=${newlySelectedEmployee.id}&month=${selectedMonth}`)
              } else {
                setSelectedEmployee(null)
              }
            }}
            userList={availableUsers}
            selected={selectedEmployee ? [selectedEmployee] : []}
            disabled={availableUsers.length <= 1}
          />
        </div>


      </div>

      <div className='my-3'>
        <CheckboxSwitch
          id='activateEditMode'
          label={_lang('Enable edit mode', '編集モードをオンにする')}
          disabled={!editModeSwitchEnabled}
          checked={editMode}
          onChange={(e) => {
            setEditMode(editModeSwitchEnabled && e.target.checked)
          }}
        />
      </div>

      <div className='calendar'>
        <div className='row'>
          <div className='header'>{_lang('Default commute', '通勤ルート')}</div>
          <div className='header'>{_lang('Commute', '通勤')} {_lang('Home', '自宅')} <i className='bi bi-arrow-right-short'></i> {_lang('Office', '会社')}</div>
          <div className='header'>{_lang('Commute', '通勤')} {_lang('Office', '会社')} <i className='bi bi-arrow-right-short'></i> {_lang('Home', '自宅')}</div>
          {showNonCommutingTransportationExpenses && (
            <div className='header'></div>
          )}
          {showNonTransportationExpenses && (
            <div className='header'></div>
          )}
          {showEmergencyPhone && (
            <div className='header'></div>
          )}
        </div>

        <div className='row'>
          <div className='grid-col1'>
            {_lang('Default', 'デフォルト')} →
          </div>

          <CommutingExpenseCell
            editMode={editMode}
            currentAction={currentAction}
            setCurrentAction={setCurrentAction}
            setModalAction={setModalAction}
            legList={commuteDefault?.commuteToWork?.DEFAULT}
            saveLegList={(legList) => {
              saveToCommuteMonthly(commuteDefault?.id, 'commuteToWork', 'DEFAULT', legList)
            }}
            category={'commuteToWork'}
            sIsoDate={'DEFAULT'}
          />

          <CommutingExpenseCell
            editMode={editMode}
            currentAction={currentAction}
            setCurrentAction={setCurrentAction}
            setModalAction={setModalAction}
            legList={commuteDefault?.commuteFromWork?.DEFAULT}
            saveLegList={(legList) => {
              saveToCommuteMonthly(commuteDefault?.id, 'commuteFromWork', 'DEFAULT', legList)
            }}
            category={'commuteFromWork'}
            sIsoDate={'DEFAULT'}
          />

          {showNonCommutingTransportationExpenses && (
            <div></div>
          )}
          {showNonTransportationExpenses && (
            <div></div>
          )}
          {showEmergencyPhone && (
            <div></div>
          )}

        </div>

        <div className='blankSpace'>

          <div className='blankSpaceTop'>
            <DropdownButton id='dropdown-month' title={selectedMonth}>
              {monthList.map((month) => {
                return (
                  <Dropdown.Item key={month} onClick={(e) => {
                    // setSelectedMonth(month)
                    navigate(`/commute/?${paramEmployeeId ? `employeeId=${paramEmployeeId}&` : ''}month=${month}`)
                  }}>{month}</Dropdown.Item>
                )
              })}
            </DropdownButton>

            <div>Monthly total: {formatNum(monthlyTotals.monthlyTotal_all)}</div>

            <div>
              {(commuteMonthly && monthIsInPast) && (
                awaitingApproval ? (
                  <div>
                    <BeatLoader style={{ opacity: 0.3 }} size={10} />
                  </div>
                ) : commuteMonthly.status === 'APPROVED' ? (
                  <div className='alert alert-success'>
                    Approved by {commuteMonthly.userApproved.name} on {commuteMonthly.dateApproved && dateFormatJpShortWithTime(commuteMonthly.dateApproved)}.
                    {' '}
                    {canApproveCommuteSheet && (
                      <ButtonTW variant='bsOrange' style={{ marginLeft: '2em' }} onClick={() => {
                        if (!window.confirm('Are you sure?'))
                          return

                        // updateDoc(doc(db, 'commutemonthlies', commuteMonthly.id), {
                        //   status: 'RESCINDED',
                        //   dateApproved: serverTimestamp(),
                        //   userApproved: { ...userBasic },
                        // })
                        //   .catch((err) => setDbError('Approving commuting expense sheet', err))

                        setAwaitingApproval(true)

                        approve_commute({
                          commuteMonthlyId: commuteMonthly.id,
                          action: 'RESCIND',
                        })
                          .then(() => setAwaitingApproval(false))
                          .catch((err) => setDbError('Approving commuting expense sheet', err))

                      }}>
                        Rescind approval
                      </ButtonTW>
                    )}
                  </div>
                ) : (
                  canApproveCommuteSheet && (
                    <ButtonTW variant='bsGreen' onClick={() => {
                      if (!window.confirm('Are you sure?'))
                        return

                      // updateDoc(doc(db, 'commutemonthlies', commuteMonthly.id), {
                      //   status: 'APPROVED',
                      //   dateApproved: serverTimestamp(),
                      //   userApproved: { ...userBasic },
                      // })
                      //   .catch((err) => setDbError('Approving commuting expense sheet', err))

                      setAwaitingApproval(true)

                      approve_commute({
                        commuteMonthlyId: commuteMonthly.id,
                        action: 'APPROVE',
                      })
                        .then(() => setAwaitingApproval(false))
                        .catch((err) => setDbError('Approving commuting expense sheet', err))
                    }}>
                      Approve this commuting expense sheet
                    </ButtonTW>
                  )
                )
              )}
            </div>
          </div>

          <div>
            <table className='table table-monthly-total-horizontal'>
              <thead>
                <tr>
                  <th></th>
                  <th>Commuting</th>
                  <th>Other transportation</th>
                  <th>Emergency phone</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Monthly total</td>
                  <td className='number'>
                    <div>{formatNum(monthlyTotals.monthlyTotal_commute)}</div>
                    {monthlyTotals.monthlyTotal_commute > commuteMonthlyCap && (
                      <div>Capped at {formatNum(commuteMonthlyCap)}</div>
                    )}
                  </td>
                  <td className='number'>{formatNum(monthlyTotals.monthlyTotal_nonCommuteTransportation)}</td>
                  <td className='number'>{formatNum(monthlyTotals.monthlyTotal_emergencyPhone)}</td>
                </tr>
                <tr>
                  <td>Info</td>
                  <td className='forReference'>
                    For reference:<br />
                    {numWorkDays} days &times; {formatNum(defaultCost)} = {formatNum(numWorkDays * defaultCost)}
                  </td>
                  <td></td>
                  <td>
                    Night shifts:<br />
                    {formatNum(phoneCostNight)} &times; {monthlyTotals.monthlyTotalNum_emergencyPhone_night}
                    {' '}= {formatNum(monthlyTotals.monthlyTotalNum_emergencyPhone_night * phoneCostNight)}
                    <br />
                    Holiday shifts:<br />
                    {formatNum(phoneCostHoliday)} &times; {monthlyTotals.monthlyTotalNum_emergencyPhone_holiday}
                    {' '}= {formatNum(monthlyTotals.monthlyTotalNum_emergencyPhone_holiday * phoneCostHoliday)}

                  </td>
                </tr>
              </tbody>
            </table>

            {false && (
              <table className='table table-monthly-total'>
                <tbody>
                  <tr>
                    <td colSpan={4}>Monthly grand total</td>
                    <td className='number'>{formatNum(monthlyTotals.monthlyTotal_all)}</td>
                  </tr>
                  <tr>
                    <td></td>
                    <td colSpan={3}>Commuting</td>
                    <td className='number'>{formatNum(monthlyTotals.monthlyTotal_commute)}</td>
                  </tr>
                  <tr className='forReference'>
                    <td></td>
                    <td colSpan={3}>For reference: {numWorkDays} days &times; {formatNum(defaultCost)}</td>
                    <td className='number'>{formatNum(numWorkDays * defaultCost)}</td>
                  </tr>
                  {(showNonCommutingTransportationExpenses || showNonTransportationExpenses || showEmergencyPhone) && (
                    <>
                      <tr>
                        <td></td>
                        <td colSpan={3}>Non-commuting total</td>
                        <td className='number'>{formatNum(monthlyTotals.monthlyTotal_nonCommute)}</td>
                      </tr>
                      {showNonCommutingTransportationExpenses && (
                        <tr>
                          <td></td>
                          <td></td>
                          <td colSpan={2}>Non-commuting transportation</td>
                          <td className='number'>{formatNum(monthlyTotals.monthlyTotal_nonCommuteTransportation)}</td>
                        </tr>
                      )}
                      {showNonTransportationExpenses && (
                        <tr>
                          <td></td>
                          <td></td>
                          <td colSpan={2}>Non-commuting other</td>
                          <td className='number'>{formatNum(monthlyTotals.monthlyTotal_nonCommuteOther)}</td>
                        </tr>
                      )}
                      {showEmergencyPhone && (
                        <>
                          <tr>
                            <td></td>
                            <td></td>
                            <td colSpan={2}>Emergency phone</td>
                            <td className='number'>{formatNum(monthlyTotals.monthlyTotal_emergencyPhone)}</td>
                          </tr>
                          <tr>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td>Night shifts: {formatNum(phoneCostNight)} &times; {monthlyTotals.monthlyTotalNum_emergencyPhone_night}</td>
                            <td className='number'>{formatNum(monthlyTotals.monthlyTotalNum_emergencyPhone_night * phoneCostNight)}</td>
                          </tr>
                          <tr>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td>Holiday shifts: {formatNum(phoneCostHoliday)} &times; {monthlyTotals.monthlyTotalNum_emergencyPhone_holiday}</td>
                            <td className='number'>{formatNum(monthlyTotals.monthlyTotalNum_emergencyPhone_holiday * phoneCostHoliday)}</td>
                          </tr>
                        </>
                      )}
                    </>
                  )}
                </tbody>
              </table>
            )}
          </div>

        </div>

        <div className='row'>
          <div className='header'>{_lang('Date', '日付')}</div>
          <div className='header'>{_lang('Commute', '通勤')} {_lang('Home', '自宅')} <i className='bi bi-arrow-right-short'></i> {_lang('Office', '会社')}</div>
          <div className='header'>{_lang('Commute', '通勤')} {_lang('Office', '会社')} <i className='bi bi-arrow-right-short'></i> {_lang('Home', '自宅')}</div>
          {showNonCommutingTransportationExpenses && (
            <div className='header'>{_lang('Non-commuting transportation expenses', '通勤以外の交通費')}</div>
          )}
          {showNonTransportationExpenses && (
            <div className='header'>{_lang('Non-transportation expenses', '交通以外の経費')}</div>
          )}
          {showEmergencyPhone && (
            <div className='header'>
              {_lang('Emergency phone', '緊急電話')}
              {editMode && (
                <Form.Select value={selectedPhone} onChange={(e) => {
                  setSelectedPhone(e.target.value)
                }}>
                  <option value=''></option>
                  {emergencyPhoneList.map((phoneName) =>
                    <option key={phoneName} value={phoneName}>{phoneName}</option>
                  )}
                </Form.Select>
              )}
            </div>
          )}
        </div>

        {dates}

      </div>

      <ModalTripInput
        modalAction={modalAction}
        setModalAction={setModalAction}
      />

    </div>
  )
}
