import React, { Dispatch, MouseEvent, SetStateAction, useMemo, useState } from 'react';
import { useAppContext } from 'src/hooks/useAppContext';
import { TourRequestTypeWithDates } from 'src/types/types_tourrequest';
import { UserSimpleType } from 'src/types/types_user';
import { FullMonthGridCell } from '../Components/FullMonthGridCell';
import { MonthItemType } from '../PageTourCalendar';
import { GuideCalendarPopup } from '../Popups/GuideCalendarPopup';
import { WeekendBars } from '../WeekendBars';
import { CellContentsType, GuideCalendarCellDataType, guideDayHasConflict, RectangularBlockType } from '../useGuideCalendarCellData';
import { UserSimpleCalendarGuideType } from '../useGuideList';
import { RedTriangle } from './RedTriangle';



export type GuideCalendarSelectedCellType = {
  row: number, // 0-based
  colList: number[], // 0-based. NOT sorted in ascending order. (sorted in order of addition to the array)
  guideObj: UserSimpleType,
}

interface R3_GuidesProps {
  CELL_WIDTH_EM: number;
  CELL_HEIGHT_GUIDECAL_EM: number;
  FONT_SIZE_PX: number;
  CALENDAR_RIGHT_BUFFER: number;
  GUIDECALENDAR_BOTTOM_BUFFER: number;
  block3height: string;
  calendarGridWidth: string;
  ref_R3_GuideCalendarGrid: React.RefObject<HTMLDivElement>;
  onScrollR3: React.UIEventHandler<HTMLDivElement>;
  currentHourUTC: number;
  dateutcCalendarStart: Date;
  dateutcCalendarEnd: Date;
  listDays: Date[];
  listMonths: MonthItemType[];
  numDaysInCalendar: number;
  usedGuides: UserSimpleCalendarGuideType[];
  requests: TourRequestTypeWithDates[];
  hoveredDayNum: number | null;
  setHoveredDayNum: (value: number | null) => void;
  selectedColumns: number[];
  isReadOnly: boolean;
  guideCalendarCellData: GuideCalendarCellDataType;
  guideCalClipboard: RectangularBlockType | null;
  setGuideCalClipboard: (data: RectangularBlockType | null) => void;
  selectedCellR3: GuideCalendarSelectedCellType | null;
  setSelectedCellR3: Dispatch<SetStateAction<GuideCalendarSelectedCellType | null>>;
}

export function R3_Guides({
  CELL_WIDTH_EM,
  CELL_HEIGHT_GUIDECAL_EM,
  FONT_SIZE_PX,
  CALENDAR_RIGHT_BUFFER,
  GUIDECALENDAR_BOTTOM_BUFFER,
  block3height,
  calendarGridWidth,
  ref_R3_GuideCalendarGrid,
  onScrollR3,
  currentHourUTC,
  dateutcCalendarStart,
  dateutcCalendarEnd,
  listDays,
  listMonths,
  numDaysInCalendar,
  usedGuides,
  requests,
  hoveredDayNum,
  setHoveredDayNum,
  selectedColumns,
  isReadOnly,
  guideCalendarCellData,
  guideCalClipboard,
  setGuideCalClipboard,
  selectedCellR3,
  setSelectedCellR3,
}: R3_GuidesProps) {

  const { db, userDetails } = useAppContext()

  const userSimple: UserSimpleType = useMemo(() => {
    return (
      {
        id: userDetails.id,
        email: userDetails.email,
        name: userDetails.displayNameEn,
      }
    )
  }, [userDetails])


  const [showDeletionConfirmationPopup, setShowDeletionConfirmationPopup] = useState<string | null>(null)

  const { lookup, allRectangles } = guideCalendarCellData


  const popupDiv = selectedCellR3 ? (
    <GuideCalendarPopup
      CELL_WIDTH_EM={CELL_WIDTH_EM}
      CELL_HEIGHT_GUIDECAL_EM={CELL_HEIGHT_GUIDECAL_EM}
      selectedCell={selectedCellR3}
      guideName={usedGuides[selectedCellR3.row].name}
      dateutcCalendarStart={dateutcCalendarStart}
      isReadOnly={isReadOnly}
      userSimple={userSimple}
      showDeletionConfirmationPopup={showDeletionConfirmationPopup}
      setShowDeletionConfirmationPopup={setShowDeletionConfirmationPopup}
      setSelectedCell={setSelectedCellR3}
      rowDays={lookup.get(usedGuides[selectedCellR3.row].id)!}
      guideCalClipboard={guideCalClipboard}
      setGuideCalClipboard={setGuideCalClipboard}
    />
  ) : null


  const onMouseEnterRectangle = (e: React.MouseEvent<HTMLDivElement>, widthEm: number) => {
    e.currentTarget.style.opacity = '1'
    e.currentTarget.style.overflow = 'visible'
    e.currentTarget.style.width = 'fit-content'
    e.currentTarget.style.minWidth = `${widthEm}em`
    e.currentTarget.style.zIndex = '10'
  }

  const onMouseLeaveReactangle = (e: React.MouseEvent<HTMLDivElement>, widthEm: number) => {
    e.currentTarget.style.opacity = '0.85'
    e.currentTarget.style.overflow = 'hidden'
    e.currentTarget.style.width = `${widthEm}em`
    e.currentTarget.style.minWidth = 'auto'
    e.currentTarget.style.zIndex = '0'
  }


  const guideRows = useMemo(() => {
    return usedGuides.map((guide, iGuide) => {


      // overall div for entire row (not really needed currently)
      // divs.push(
      //   <div
      //     key={guide.id}
      //     style={{
      //       position: 'absolute',
      //       left: '0em',
      //       top: `${iGuide * CELL_HEIGHT_GUIDECAL_EM}em`,
      //       height: `${CELL_HEIGHT_GUIDECAL_EM}em`,
      //       width: `${CELL_WIDTH_EM * numDaysInCalendar}em`,
      //       borderLeft: '1px solid #aaa',
      //       borderRight: '1px solid #aaa',
      //       borderBottom: '1px solid #aaa',
      //       paddingLeft: '0.5em',
      //       display: 'flex',
      //       alignItems: 'center',
      //     }}>
      //   </div>
      // )

      const divsMonths: JSX.Element[] = []
      for (let iMonth = 0; iMonth < listMonths.length; iMonth++) {
        const month = listMonths[iMonth]

        // FULL MONTHS
        // provides the black border on month start and end, and top/bottom border throughout.
        divsMonths.push(
          <FullMonthGridCell
            key={`month_${iMonth}_full`}
            iRow={iGuide}
            indexStart={month.monthStartDayIndex}
            indexEnd={month.monthEndDayIndexExc}
            className='leftMonthBorder rightMonthBorder'
            CELL_WIDTH_EM={CELL_WIDTH_EM}
            CELL_HEIGHT_EM={CELL_HEIGHT_GUIDECAL_EM}
          />
        )
      }


      // background cells, with number of work hours
      const divsBgCells: JSX.Element[] = []
      const days = lookup.get(guide.id)!;
      [...days.keys()].sort((a, b) => a - b).forEach((calendarDayIndex) => { // calendarDayIndex is 0-based
        // calendarDayIndex does not take every value, only values where there is something on the day
        const items: CellContentsType[] = days.get(calendarDayIndex)! // items has at least 1 element

        let hours: string
        let className = ''
        const hasConflict = guideDayHasConflict(items)
        if (hasConflict === 'CONFLICT') {
          // CONFLICT: multiple items on the same day. Show a red background
          hours = items.map((x) => x.guidingHours ?? '?').join('+')
          className = 'tw-bg-red-600 tw-text-white'
        } else if (hasConflict === 'MULTIPLE') {
          hours = items.map((x) => x.guidingHours ?? '?').join('+')
          className = 'tw-bg-red-200 tw-text-black'
        } else {
          // No conflict. Show guiding hours.
          // we can't assume items.length is 1 because there could be calendar notes (which don't cause a conflict)
          hours = items.filter((x) => x.guidingHours).map((x) => x.guidingHours).join('|')
        }

        if (hours || className) {
          divsBgCells.push(
            <div
              key={`guide_${guide.id}_day_${calendarDayIndex}_bg`}
              className={className}
              style={{
                position: 'absolute',
                left: `${calendarDayIndex * CELL_WIDTH_EM}em`,
                top: `${iGuide * CELL_HEIGHT_GUIDECAL_EM}em`,
                width: `${CELL_WIDTH_EM}em`,
                height: `${CELL_HEIGHT_GUIDECAL_EM}em`,
                display: 'flex',
                alignItems: 'end',
                justifyContent: 'center',
                // borderBottom: '1px solid #eee', // border color is different for last guide (darker) so we shouldn't set border-bottom here
              }}>
              <div style={{ marginBottom: '-0.1em' }}>
                {hours}
              </div>
            </div>
          )
        }

      }) // each day


      // convert the items to GridCells
      const divsRectangles: JSX.Element[] = []
      const divsCalendarNotes: JSX.Element[] = []
      const divsDaysOff: JSX.Element[] = []
      const rectangleList = allRectangles.get(guide.id)!
      for (const rectangle of rectangleList) {

        const isDayOff = rectangle.cellType === 'dayoff'
        const isCalNote = rectangle.cellType === 'calendarnote'
        // if (rectangle.cellType === 'dayoff')
        //   continue

        const indexStart = rectangle.dayIndex
        const width = rectangle.length
        const iRow = iGuide

        if (isCalNote) {
          const padLeft = 0.3
          const padRight = 0.3
          const widthEm = width * CELL_WIDTH_EM - padLeft - padRight

          const div =
            <div
              key={`guide_${guide.id}_day_${rectangle.dayIndex}_${rectangle.identifier}`}
              style={{
                position: 'absolute',
                left: `${indexStart * CELL_WIDTH_EM + padLeft}em`,
                top: `${iRow * CELL_HEIGHT_GUIDECAL_EM + 0.1}em`,
                width: `${widthEm}em`,
                height: `${1.6}em`,
                display: 'flex',
                alignItems: 'center',
                overflow: 'hidden',

                backgroundColor: rectangle.backgroundColor,
                color: rectangle.textColor,
                opacity: '0.70',
                // boxShadow: '0 0 1em rgba(0,0,0,0.2)',
                border: '1px solid #00000020',
                borderRadius: '0.25em',
                paddingLeft: '0.2em',
                paddingRight: '0.2em',
                cursor: 'default',
              }}
              className='requestGuideCell'
              onMouseEnter={(e) => onMouseEnterRectangle(e, widthEm)}
              onMouseLeave={(e) => onMouseLeaveReactangle(e, widthEm)}
            >
              {rectangle.cellContentJsx}
            </div>

          divsCalendarNotes.push(div)
        } else {
          let padTop: number
          let heightEm: number
          if (isDayOff) {
            padTop = CELL_HEIGHT_GUIDECAL_EM * 0.25
            heightEm = CELL_HEIGHT_GUIDECAL_EM * 0.4
          } else {
            padTop = CELL_HEIGHT_GUIDECAL_EM * 0.16
            heightEm = CELL_HEIGHT_GUIDECAL_EM * 0.63
          }

          const padLeft = 0.2
          const padRight = 0.2

          const widthEm = width * CELL_WIDTH_EM - padLeft - padRight

          const redtriangles: JSX.Element[] = []
          for (let i = 0; i < rectangle.length; i++) {
            const text = rectangle.cells[i].guidingAdditionalText
            if (!text)
              // no memo, skip
              continue
            if (i < rectangle.length - 1 && text === rectangle.cells[i + 1].guidingAdditionalText)
              // memo is repeated in next cell: only show 1 red triangle, in next cell
              continue
            const cssRight = `${(rectangle.length - 1 - i) * CELL_WIDTH_EM}em`
            redtriangles.push(
              <RedTriangle key={`redtriangle_${i}`} cssRight={cssRight} />
            )
          }

          const div =
            <div
              key={`guide_${guide.id}_day_${rectangle.dayIndex}_${rectangle.identifier}`}
              style={{
                position: 'absolute',
                left: `${indexStart * CELL_WIDTH_EM + padLeft}em`,
                top: `${iRow * CELL_HEIGHT_GUIDECAL_EM + padTop}em`,
                width: `${widthEm}em`,
                height: `${heightEm}em`,
                display: 'flex',
                alignItems: 'center',
                overflow: 'hidden',

                backgroundColor: rectangle.backgroundColor,
                color: rectangle.textColor,
                opacity: '0.85',
                boxShadow: '0 0 1em rgba(0,0,0,0.2)',
                borderStyle: 'none',
                paddingLeft: '0.3em',
                paddingRight: '0.3em',
                cursor: 'default',
              }}
              className='requestGuideCell'
              onMouseEnter={(e) => onMouseEnterRectangle(e, widthEm)}
              onMouseLeave={(e) => onMouseLeaveReactangle(e, widthEm)}
            >
              {rectangle.cellContentJsx}
              {redtriangles}
            </div>

          if (isDayOff)
            divsDaysOff.push(div)
          else
            divsRectangles.push(div)
        }
      }

      let selectionDivs: JSX.Element[] | null = null
      if (selectedCellR3 && selectedCellR3.row === iGuide) {
        const areas: { start: number, length: number }[] = []
        const sortedCols = [...selectedCellR3.colList].sort()
        for (const colNum of sortedCols) {
          if (areas.length > 0 && areas[areas.length - 1].start + areas[areas.length - 1].length === colNum) {
            areas[areas.length - 1].length++
          } else {
            areas.push({ start: colNum, length: 1 })
          }
        }

        selectionDivs = []
        for (const area of areas) {
          selectionDivs.push(
            <div className='tempSquare' key={`area_${area.start}`} style={{
              position: 'absolute',
              top: `${selectedCellR3.row * CELL_HEIGHT_GUIDECAL_EM}em`,
              left: `${area.start * CELL_WIDTH_EM}em`,
              width: `${area.length * CELL_WIDTH_EM}em`,
              height: `${CELL_HEIGHT_GUIDECAL_EM}em`,
              border: '3px solid darkblue',
            }}></div>
          )
        }
      }


      return (
        <div className={`calendarRow ${iGuide < usedGuides.length - 1 && usedGuides[iGuide + 1].teamName !== guide.teamName ? 'lastTourOfMonth' : ''}`} key={`guide_${guide.id}`}>
          {divsMonths}
          {divsBgCells}
          {divsRectangles}
          {divsDaysOff}
          {divsCalendarNotes}
          {selectionDivs}
        </div>
      )
    }) // each row/guide

  }, [CELL_HEIGHT_GUIDECAL_EM, CELL_WIDTH_EM, allRectangles, listMonths, lookup, selectedCellR3, usedGuides])


  return (
    <div
      ref={ref_R3_GuideCalendarGrid}
      className='guideCalendarGrid'
      style={{
        height: block3height,
        position: 'relative',
        width: calendarGridWidth,
        overflowX: 'scroll',
        overflowY: 'scroll',
        borderBottom: '1px solid #666',
      }}
      onScroll={onScrollR3}
    >
      <div className='horizontal-spacer' style={{
        position: 'absolute',
        width: `${(numDaysInCalendar + CALENDAR_RIGHT_BUFFER) * CELL_WIDTH_EM}em`,
        height: '1px',
      }}></div>

      <div className='vertical-spacer' style={{
        position: 'absolute',
        height: `${(usedGuides.length + GUIDECALENDAR_BOTTOM_BUFFER) * CELL_HEIGHT_GUIDECAL_EM}em`,
        width: '1px',
      }}></div>

      <div id='calendarGridBackground'
        style={{
          width: `${numDaysInCalendar * CELL_WIDTH_EM}em`,
          height: `${usedGuides.length * CELL_HEIGHT_GUIDECAL_EM}em`,
        }}
        onMouseDown={(event) => {
          event.preventDefault() // to prevent dragging behavior
          // console.log(e.clientX, e.clientY)
          const rect = event.currentTarget.getBoundingClientRect();
          const x = event.clientX - rect.left;
          const y = event.clientY - rect.top;
          // console.log(x, y)
          const iGuide = Math.floor(y / FONT_SIZE_PX / CELL_HEIGHT_GUIDECAL_EM) // 0-based
          const dayNum = Math.floor(x / FONT_SIZE_PX / CELL_WIDTH_EM) // 0-based

          if (selectedCellR3 && selectedCellR3.row === iGuide && selectedCellR3.colList.includes(dayNum)) {
            // if we click inside the selection, remove the selection
            setSelectedCellR3(null)
            return
          }

          const guideObj: UserSimpleType = {
            id: usedGuides[iGuide].id,
            email: usedGuides[iGuide].email,
            name: usedGuides[iGuide].name,
          }

          setSelectedCellR3({
            row: iGuide, // 0-based
            colList: [dayNum], // 0-based
            guideObj,
          })

          // window.addEventListener('mousemove', onMouseMove)
          // window.addEventListener('mouseup', onMouseUp)
        }}
        onMouseMove={(e: MouseEvent) => {
          const rect = e.currentTarget.getBoundingClientRect();
          const x = e.clientX - rect.left;
          const y = e.clientY - rect.top;
          const dayNum = Math.floor(x / FONT_SIZE_PX / CELL_WIDTH_EM)

          setHoveredDayNum(dayNum)

          if (!selectedCellR3)
            return
          if (e.buttons !== 1)
            return
          const thisId = (e.target as any).id
          if (thisId === 'tempSquare')
            return

          if (!selectedCellR3.colList.includes(dayNum)) {
            // select intermediate cells that were not hovered by cursor due to fast mouse movement
            const newColList = [...selectedCellR3.colList]
            if (selectedCellR3.colList.length === 0) {
              newColList.push(dayNum)
            } else {
              const last = newColList[newColList.length - 1]
              const dir = dayNum < last ? -1 : 1
              for (let i = last + dir; ; i += dir) {
                if (!newColList.includes(i))
                  newColList.push(i)
                if (i === dayNum)
                  break
              }
            }
            setSelectedCellR3({ ...selectedCellR3, colList: newColList })
          }

        }}
        onMouseLeave={() => {
          setHoveredDayNum(null)
        }}
        onMouseUp={(e: MouseEvent) => {
          // console.log('mouseup')
          // setSelectedCell(null)
          // window.removeEventListener('mousemove', onMouseMove)
          // window.removeEventListener('mouseup', onMouseUp)
        }}
      >

        <WeekendBars
          listDays={listDays}
          numRows={usedGuides.length}
          currentHourUTC={currentHourUTC}
          dateutcCalendarStart={dateutcCalendarStart}
          dateutcCalendarEnd={dateutcCalendarEnd}
          CELL_WIDTH_EM={CELL_WIDTH_EM}
          CELL_HEIGHT_EM={CELL_HEIGHT_GUIDECAL_EM}
        />

        {hoveredDayNum !== null && (
          <div className='hoveredDayColumn' style={{
            position: 'absolute',
            left: `${hoveredDayNum * CELL_WIDTH_EM}em`,
            width: `${1 * CELL_WIDTH_EM}em`,
            height: `${usedGuides.length * CELL_HEIGHT_GUIDECAL_EM}em`,
          }}></div>
        )}

        {selectedColumns.map((colNum) => {
          return (
            <div key={colNum} className='' style={{
              position: 'absolute',
              left: `${colNum * CELL_WIDTH_EM}em`,
              width: `${1 * CELL_WIDTH_EM}em`,
              height: `${requests.length * CELL_HEIGHT_GUIDECAL_EM}em`,
              // zIndex: 1,
              // pointerEvents: 'none',
              backgroundColor: '#ddccee55',
              border: '1px solid #ddccee',
            }}></div>
          )
        })}


        {guideRows}
        {popupDiv}
      </div>
    </div>
  )
}
