import { userDepartmentList, UserDetailsType, UserSimpleTeamType, userTeamList } from 'src/types/types_user'
import { compare } from 'src/util/util_misc'


export function getTeamList(guidesFirst: boolean) {
  let arrayTeams: string[] = [...userTeamList]
  if (guidesFirst)
    arrayTeams = [
      arrayTeams[0], // CEO
      'Guide',
      ...arrayTeams.slice(1).filter((t) => t !== 'Guide')
    ]

  return arrayTeams
}


const getDomain = (email: string) => email.slice(email.indexOf('@') + 1)

interface GetUserComparerProps {
  guidesFirst: boolean
  sortByDepartment?: boolean
}

export function getUserComparer({
  guidesFirst,
  sortByDepartment,
}: GetUserComparerProps) {

  const compareUsers = (a: UserDetailsType | UserSimpleTeamType, b: UserDetailsType | UserSimpleTeamType) => {
    const compDomain = compare(getDomain(a.email), getDomain(b.email))
    if (compDomain) return compDomain
    //const compRole = -compare(getRoleScore(a.role), getRoleScore(b.role))
    //return compRole
    // const compCreated = compare(a.dateCreated, b.dateCreated)
    // return compCreated
    const arrayTeams = getTeamList(guidesFirst)
    let ind1 = arrayTeams.indexOf(a.teamName)
    if (ind1 === -1) ind1 = 9999
    let ind2 = arrayTeams.indexOf(b.teamName)
    if (ind2 === -1) ind2 = 9999

    const compTeam = compare(ind1, ind2)
    if (compTeam) return compTeam

    if (sortByDepartment) {
      const dept1 = (('departmentName' in a) && a.departmentName) || ''
      const dept2 = (('departmentName' in b) && b.departmentName) || ''
      const ind1 = userDepartmentList.indexOf(dept1) || 9999
      const ind2 = userDepartmentList.indexOf(dept2) || 9999
      const compDept = compare(ind1, ind2)
      if (compDept) return compDept
    }

    const aName = 'displayNameEn' in a ? a.displayNameEn : a.name
    const bName = 'displayNameEn' in b ? b.displayNameEn : b.name
    return compare(aName, bName)
  }

  return compareUsers
}

