import { QuerySnapshot, collection, doc, getDoc, onSnapshot, query, where } from 'firebase/firestore';
import { useEffect, useMemo, useState } from 'react';
import { Form } from 'react-bootstrap';
import { Helmet } from 'react-helmet-async';
import { ButtonTW } from 'src/components/Buttons/ButtonTW';
import { RequestCodeLinkToAggregator } from 'src/components/ContextMenus/RequestCodeLinkToAggregator';
import { RequestCodeSelector } from 'src/components/FormControls/RequestCodeSelector';
import { ModalPopup } from 'src/components/Modal/ModalPopup';
import { getLoadingSpinnerOrNull } from 'src/components/Spinner/util_getLoadingSpinnerOrNull';
import { useAutosaveDocumentInList } from 'src/hooks/autosave/util_autosave';
import { useAppContext } from 'src/hooks/useAppContext';
import { QuotationType } from 'src/types/types_quotation';
import { TourRequestType } from 'src/types/types_tourrequest';
import { dateisoFormatJpShort } from 'src/util/dateformattools';
import { dateFormatUserFriendly } from 'src/util/datelayouttools';
import { convertQuotationSheetDates, convertTourRequestDates } from 'src/util/util_firestoredates';
import { createNewQuotation } from './createNewQuotation';
import { addMetadataModifiedQuotations } from './QuotationCrud/util_db_quotations';
import './quotationlist.css';


export function QuotationList() {

  const { db, setDbError, userDetails } = useAppContext()

  const userSimple = useMemo(() => {
    return {
      uid: userDetails.id,
      email: userDetails.email,
      name: userDetails.displayNameEn,
    }
  }, [userDetails])

  const [shownPopup, setShownPopup] = useState<string | null>(null)

  // below state is for creating new quotation
  const [showModal, setShowModal] = useState(false)
  const [tripCode, setTripCode] = useState<string>('')
  const [paxName, setPaxName] = useState<string>('')
  const [tourRequest, setTourRequest] = useState<TourRequestType | null>()
  const [quotationSheetName, setQuotationSheetName] = useState<string>()
  // ------------------------------

  const [quotations, setQuotations] = useState<QuotationType[]>()
  useEffect(() => {

    const processSnapshot = function (snapshot: QuerySnapshot) {
      const quotations: QuotationType[] = [];
      console.log(`Retrieved ${snapshot.docs.length} quotations from firestore`)
      for (const doc of snapshot.docs) {
        const quotation = { ...doc.data(), id: doc.id } as QuotationType
        convertQuotationSheetDates(quotation)
        quotations.push(quotation)
      }
      setQuotations(quotations)
    }

    const q = query(
      collection(db, 'quotationsheets'),
      where('_isDeleted', '==', false),
    )
    const unsubscribe = onSnapshot(q, processSnapshot, (err) => setDbError('Getting quotation list', err));

    return unsubscribe
  }, [db, setDbError])


  const setTourRequestId = (tourrequestId: string) => {
    getDoc(doc(db, 'tourrequests', tourrequestId))
      .then((docu) => {
        const tourrequestFirestore = { ...docu.data(), id: docu.id } as TourRequestType
        convertTourRequestDates(tourrequestFirestore)
        setTourRequest(tourrequestFirestore)
        console.log('retrieved request from firestore', tourrequestFirestore)
      })
  }


  const autosaveNewStep = useAutosaveDocumentInList('quotationsheets', addMetadataModifiedQuotations)

  /**** ALL HOOKS ABOVE ****/


  const loadingSpinner = getLoadingSpinnerOrNull([
    ['quotation sheets', quotations]
  ])
  if (!quotations)
    return loadingSpinner


  return (
    <div className='container page-quotationlist'>
      <Helmet><title>Quotation Sheet List</title></Helmet>

      <h2 className='my-4'>List of quotation sheets</h2>

      <table className='table'>
        <thead>
          <tr>
            <th></th>
            <th>Request code</th>
            <th>Pax name</th>
            <th>Sheet name</th>
            <th>Tour dates</th>
            <th>Num of pax</th>
            <th>Created</th>
            <th>Modified</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {quotations.map((quotation) => {
            return (
              <tr key={quotation.id}>
                <td>
                  <ButtonTW variant='blue_outline' to={`edit/${quotation.id}`}>Edit</ButtonTW></td>
                <td>
                  <RequestCodeLinkToAggregator
                    requestCode={quotation.requestInfo.requestCode}
                    linkId={quotation.id}
                    shownPopup={shownPopup}
                    setShownPopup={setShownPopup}
                  />
                </td>
                <td>{quotation.requestInfo.travellerName}</td>
                <td>{quotation.quotationSheetName}</td>
                <td>
                  {dateisoFormatJpShort(quotation.requestInfo.dateisoTourStart)} -
                  {dateisoFormatJpShort(quotation.requestInfo.dateisoTourEnd)}
                </td>
                <td>{quotation.requestInfo.numOfPax}</td>
                <td>
                  {dateFormatUserFriendly(quotation.metadataCreated.dateCreated)}
                  {quotation.metadataCreated.userCreated.name}
                </td>
                <td>
                  {dateFormatUserFriendly(quotation.metadataModified.dateModified)}
                  {quotation.metadataModified.userModified.name}
                </td>
                <td>
                  <ButtonTW variant='blue_outline' onClick={(e) => {
                    if (!window.confirm('Are you sure you want to delete this quotation sheet?'))
                      return

                    const updateObj: Partial<QuotationType> = {
                      _isDeleted: true,
                    }

                    autosaveNewStep('DELETE', quotation, updateObj, 'UNDOWALL')
                      .then(() => console.log('Deleted quotation sheet'))
                  }}>Delete</ButtonTW>
                </td>
              </tr>
            )
          }


          )}
        </tbody>
      </table>

      <div>
        <ButtonTW variant='blue' textSize='md' onClick={(e) => {
          // reset popup fields
          setTripCode('')
          setPaxName('')
          setTourRequest(null)
          setQuotationSheetName('Main')

          setShowModal(true)
        }}>Create new quotation sheet</ButtonTW>
      </div>


      <ModalPopup
        title={'Create new quotation sheet'}
        okLabel='Create'
        show={showModal}
        className='modal-superlarge'
        callbackClose={() => setShowModal(false)}
        onSubmit={(e, onSuccess) => {
          e.preventDefault()

          if (!tripCode || !tourRequest) {
            window.alert('Select request code')
            return
          }

          if (!quotationSheetName) {
            window.alert('Enter sheet name')
            return
          }

          console.log('tourrequest', tourRequest)

          createNewQuotation(db, userDetails, tourRequest, tourRequest.id, quotationSheetName)
            .then((result) => {
              if (result === 'success')
                setShowModal(false)
              else
                window.alert(`Error creating quotation sheet: ${result}`)
            })

        }}
        body={

          <div>
            <div className='mb-2'>

            </div>
            <div className=''>
              <RequestCodeSelector
                tripCode={tripCode}
                paxName={paxName}
                setRequestData={({ requestCode, paxName, tourrequestId }) => {
                  setTripCode(requestCode)
                  setPaxName(paxName)
                  if (tourrequestId)
                    setTourRequestId(tourrequestId)
                }}
              />
            </div>

            {/* <div>Pax name</div>
            <div>
              <Form.Control type='text' value={paxName} disabled={true} />
            </div> */}

            <div>Quotation sheet name</div>
            <div>
              <Form.Control type='text' value={quotationSheetName} onChange={(e) => setQuotationSheetName(e.target.value)} />
            </div>
          </div>

        } />

    </div>
  )
}
