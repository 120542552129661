import { Form } from 'react-bootstrap'
import { dateFormatJpShort } from 'src/util/dateformattools'
import { ModalActionExpensePaymentType } from './ModalPopupMakeExpensePayment'


export function paymentTypeLabel(paymentType: string) {
  switch (paymentType) {
    case 'CASH':
      return 'Cash'
    case 'ADHOC_BANK_TRANSFER':
      return 'Ad hoc bank transfer'
    case 'WITH_SALARY':
      return 'With next salary'
    default:
      return paymentType
  }
}

export function paymentFlowShortLabel(paymentFlow: string, companyCreditCard: string) {
  switch (paymentFlow) {
    case 'VIA_EMPLOYEE':
      return (
        <div className='paymentFlowIcon'>
          <i className='bi bi-person' title='Via employee'></i>
          <div>Employee</div>
        </div>
      )
    case 'CEO_CREDITCARD':
      return (
        <div className='paymentFlowIcon'>
          <i className='bi bi-credit-card' title='Credit card'></i>
          <div>CC: CEO</div>
        </div>
      )
    case 'COMPANY_CREDITCARD':
      return (
        <div className='paymentFlowIcon'>
          <i className='bi bi-credit-card' title='Company card'></i>
          <div style={{ textAlign: 'center' }}>CC: {companyCreditCard.split('_')[0]}</div>
        </div>
      )
    case 'DIRECT':
      return (
        <div className='paymentFlowIcon'>
          <i className='bi bi-building-up' title='Direct'></i>
          <div>Direct</div>
        </div>
      )
    default:
      return paymentFlow
  }
}

export function getGridRowsDescribingPayment(modalAction: ModalActionExpensePaymentType) {
  return (
    <>
      <div className='pt-2'>Payment type:</div>
      <div className='py-2'>
        <div className='form-check'>
          <input className='form-check-input' type='radio' name='radioPaymentType' id='radioPaymentType_CASH' value='CASH' checked={modalAction.paymentType === 'CASH'} disabled={true} />
          <label className='form-check-label' htmlFor='radioPaymentType_CASH'>
            Cash
          </label>
        </div>
        <div className='form-check'>
          <input className='form-check-input' type='radio' name='radioPaymentType' id='radioPaymentType_ADHOC_BANK_TRANSFER' value='ADHOC_BANK_TRANSFER' checked={modalAction.paymentType === 'ADHOC_BANK_TRANSFER'} disabled={true} />
          <label className='form-check-label' htmlFor='radioPaymentType_ADHOC_BANK_TRANSFER'>
            Adhoc bank transfer
          </label>
        </div>
        <div className='form-check'>
          <input className='form-check-input' type='radio' name='radioPaymentType' id='radioPaymentType_WITH_SALARY' value='WITH_SALARY' checked={modalAction.paymentType === 'WITH_SALARY'} disabled={true} />
          <label className='form-check-label' htmlFor='radioPaymentType_WITH_SALARY'>
            With salary
          </label>
        </div>
      </div>

      {(modalAction.paymentFor === 'GUIDING_EXPENSE' && modalAction.paymentDbProps.isAdvance) ? (
        <>
          <div className='pt-2'>Tour code:</div>
          <div className=''><Form.Control type='text' disabled={true} value={modalAction.paymentDbProps.tourCode} /></div>

          <div className='pt-2'>Desired payment date:</div>
          <div className='pt-2'>
            {dateFormatJpShort(
              modalAction.paymentDisplayProps!.desiredDate // paymentDisplayProps is non-null for advance payments
            )}
          </div>
        </>
      ) : null}

      {modalAction.paymentType === 'WITH_SALARY' ? (
        <>
          <div className='pt-2'>Salary month:</div>
          <div className=''><Form.Control type='text' disabled={true} value={modalAction.paymentDbProps.salaryMonth} /></div>
        </>
      ) : null}

    </>
  )
}

