import React, { useEffect, useState } from 'react';
import { SortCaretProps } from 'src/components/ColumnSorter/SortCaret';
import { EditableField } from 'src/components/EditableField/EditableField';
import { useAppContext } from 'src/hooks/useAppContext';
import { ColumnFilterAmountType } from 'src/types/types_columnfilters';
import { formatNum } from 'src/util/util_formatnum';
import { log_info } from 'src/util/util_log';
import { ButtonTW } from '../Buttons/ButtonTW';
import { TreeListItem } from './TreeListItem';
import './columnfilterpopup.css';
import { PageNamesType, UrlParamNames, useSetOneSearchParam } from './useSetOneSearchParam';
import { constructDefaultAmountTreeListState, refreshAmountsTernaryState } from './util_filter_amount';





interface ColumnFilterPopupAmountProps {
  pageName: PageNamesType;
  urlParameterName: UrlParamNames;
  allAmounts: number[] | undefined; // undefined while loading
  appliedFilter: ColumnFilterAmountType | null;
  popupIsOpen: boolean;
  closePopup: () => void;
  sortCaretProps: SortCaretProps | null;
}

export function ColumnFilterPopupAmount({
  pageName,
  urlParameterName,
  allAmounts,
  appliedFilter,
  popupIsOpen,
  closePopup,
  sortCaretProps,
}: ColumnFilterPopupAmountProps) {

  const { db, userDetails } = useAppContext()

  const { setOneSearchParam } = useSetOneSearchParam(pageName)

  const [editedCell, setEditedCell] = useState<string | null>(null)

  const [searchValue, setSearchValue] = useState<string>('')


  const [localFilterState, setLocalFilterState] = useState<ColumnFilterAmountType>({ filterMode: null })

  // update this component's filter state if it changes in InvoiceTable
  useEffect(() => {
    setLocalFilterState(appliedFilter ?? { filterMode: null })
  }, [appliedFilter])





  // *** all hooks above

  if (!allAmounts)
    // still loading
    return null


  const getDefaultTreeListState = () => {
    return constructDefaultAmountTreeListState(allAmounts)
  }

  const onCheckChange = (checked: boolean, amount: number) => {

    const treeListState = localFilterState.treeListState ?? getDefaultTreeListState()
    treeListState.amounts.set(amount, checked ? 1 : 0)

    refreshAmountsTernaryState(treeListState)

    if (treeListState.ternarySelected !== 0) {
      setLocalFilterState({
        filterMode: 'checkboxes',
        treeListState,
      })
    } else {
      // nothing at all was ticked
      setLocalFilterState({ filterMode: null })
    }

  }


  return (
    <div className={`filterPopup filterPopupAmount ${popupIsOpen ? 'show' : 'hide'}`}>
      {sortCaretProps && (
        <>
          <div className='clickable' onClick={() => {
            sortCaretProps.setSortSetting([sortCaretProps.colName, 1])
            log_info({ db, userDetails, logkey: 'filterpopup.sortasc', desc: 'Filter popup Amount: Sort ascending' })
          }}>
            <i className={`bi ${(sortCaretProps.sortCol === sortCaretProps.colName && sortCaretProps.sortDir === 1) ? 'bi-caret-up-square' : 'bi-caret-up'}`}></i>
            {' '}
            Sort ascending
          </div>
          <div className='clickable' onClick={() => {
            sortCaretProps.setSortSetting([sortCaretProps.colName, -1])
            log_info({ db, userDetails, logkey: 'filterpopup.sortdesc', desc: 'Filter popup Amount: Sort descending' })
          }}>
            <i className={`bi ${(sortCaretProps.sortCol === sortCaretProps.colName && sortCaretProps.sortDir === -1) ? 'bi-caret-down-square' : 'bi-caret-down'}`}></i>
            {' '}
            Sort descending
          </div>
          {/* <hr className='my-2' /> */}
        </>
      )}
      <div className='filterTitle'>Number filters</div>
      <hr className='my-1' />
      <div className={`equalityFilter filterArea ${localFilterState.filterMode === 'equals' ? 'filterActive' : ''}`}>
        <div>
          Amount =
        </div>
        <EditableField
          tableid='filterPopup'
          rowid='equals'
          fieldname='value'
          validationType='number'
          currentValue={localFilterState.filterEquals ? formatNum(localFilterState.filterEquals) : ''}
          isClickableToEdit={true}
          editedCell={editedCell}
          setEditedCell={setEditedCell}
          useSpan={true}
          callbackCommitChange={(value) => {
            console.log('value', value)
            if (value) {
              setLocalFilterState({
                filterMode: 'equals',
                filterEquals: value,
              })
            } else {
              setLocalFilterState({
                filterMode: null,
              })
            }
            setEditedCell(null)
          }}
        />
      </div>
      <hr className='my-1' />
      <div className={`filterArea ${localFilterState.filterMode === 'range' ? 'filterActive' : ''}`}>
        <div className='equalityFilter'>
          <div>
            Amount ≥
          </div>
          <EditableField
            tableid='filterPopup'
            rowid='greaterThan'
            fieldname='value'
            validationType='number'
            currentValue={localFilterState.filterLowerBound ? formatNum(localFilterState.filterLowerBound) : ''}
            isClickableToEdit={true}
            editedCell={editedCell}
            setEditedCell={setEditedCell}
            useSpan={true}
            callbackCommitChange={(value) => {
              console.log('value', value)
              if (value) {
                if (localFilterState.filterMode === 'range') {
                  setLocalFilterState({ ...localFilterState, filterLowerBound: value })
                } else {
                  setLocalFilterState({
                    filterMode: 'range',
                    filterLowerBound: value,
                  })
                }
              } else {
                if (localFilterState.filterUpperBound) {
                  setLocalFilterState({ ...localFilterState, filterLowerBound: undefined })
                } else {
                  setLocalFilterState({ filterMode: null })
                }
              }
              setEditedCell(null)
            }}
          />
        </div>
        <div className='equalityFilter'>
          <div>
            Amount ≤
          </div>
          <EditableField
            tableid='filterPopup'
            rowid='smallerThan'
            fieldname='value'
            validationType='number'
            currentValue={localFilterState.filterUpperBound ? formatNum(localFilterState.filterUpperBound) : ''}
            isClickableToEdit={true}
            editedCell={editedCell}
            setEditedCell={setEditedCell}
            useSpan={true}
            callbackCommitChange={(value) => {
              console.log('value', value)
              if (value) {
                if (localFilterState.filterMode === 'range') {
                  setLocalFilterState({ ...localFilterState, filterUpperBound: value })
                } else {
                  setLocalFilterState({
                    filterMode: 'range',
                    filterUpperBound: value,
                  })
                }
              } else {
                if (localFilterState.filterLowerBound) {
                  setLocalFilterState({ ...localFilterState, filterUpperBound: undefined })
                } else {
                  setLocalFilterState({ filterMode: null })
                }
              }
              setEditedCell(null)
            }}
          />
        </div>
      </div>
      <hr className='my-1' />

      <div className={`filterArea ${localFilterState.filterMode === 'checkboxes' ? 'filterActive' : ''}`}>
        <div>
          <input type='text' placeholder='Search' className='searchBox' value={searchValue} onChange={(e) => {
            setSearchValue(e.target.value)
          }} />
        </div>
        <div className='checkboxList'>
          {allAmounts.map((amount) => {

            if (searchValue) {
              if (searchValue.includes(',')) {
                if (!formatNum(amount).includes(searchValue))
                  return null
              } else {
                if (!amount.toString().includes(searchValue))
                  return null
              }
            }

            return (
              <React.Fragment key={amount}>
                <TreeListItem
                  label={formatNum(amount)}
                  indentation={0}
                  childNodes={null}
                  ternarySelected={localFilterState.treeListState?.amounts?.get(amount) ?? 0}
                  onCheckChange={(checked) => {
                    onCheckChange(checked, amount)
                  }}
                  onLabelClick={() => {
                    log_info({ db, userDetails, logkey: 'filterpopup.directclick', desc: `Filter popup ${urlParameterName}: Direct click ${amount}` })
                    setOneSearchParam(urlParameterName, `${amount}`)
                    closePopup()
                  }}
                />
              </React.Fragment>
            )
          })}
          {/* <TreeListItem
            label='(empty)'
            indentation={0}
            childNodes={null}
            ternarySelected={localFilterState.treeListState?.emptyValues ?? 0}
            onCheckChange={(checked) => {
              onCheckChange(checked, null)
            }}
          /> */}

        </div>
      </div>
      <div className='mt-3'>
        <ButtonTW variant='blue' style={{ width: '100%' }} onClick={() => {

          let queryparam = null

          if (localFilterState.filterMode === 'equals') {
            queryparam = localFilterState.filterEquals.toString()
          } else if (localFilterState.filterMode === 'range') {
            queryparam = localFilterState.filterLowerBound ? localFilterState.filterLowerBound.toString() : ''
            queryparam += '~'
            queryparam += localFilterState.filterUpperBound ? localFilterState.filterUpperBound.toString() : ''
          } else if (localFilterState.filterMode === 'checkboxes') {
            const treeListState = localFilterState.treeListState
            if (treeListState.ternarySelected === 1) {
              // everything is selected
              queryparam = '[all]'
            } else if (treeListState.ternarySelected === 0) {
              // nothing is selected -> disable this filter
              queryparam = null
            } else {
              const list: string[] = []
              for (const [amount, amountSelected] of treeListState.amounts) {
                if (amountSelected === 1) {
                  list.push(amount.toString())
                }
              }

              queryparam = `[${list.join(',')}]`
            }
          }

          log_info({ db, userDetails, logkey: 'filterpopup.apply', desc: `Filter popup ${urlParameterName}: Apply ${queryparam}` })

          setOneSearchParam(urlParameterName, queryparam)

          closePopup()

        }}>Apply</ButtonTW>
      </div>
      <div className='mt-1'>
        <ButtonTW variant='bsDarkGray' style={{ width: '100%' }} onClick={() => {

          log_info({ db, userDetails, logkey: 'filterpopup.clear', desc: `Filter popup ${urlParameterName}: Clear` })

          setSearchValue('')

          setOneSearchParam(urlParameterName, '')

          closePopup()

        }}>Clear</ButtonTW>
      </div>
    </div>
  )
}
