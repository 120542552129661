import { DocumentSnapshot, QuerySnapshot, collection, doc, getDoc, limit, onSnapshot, orderBy, query, updateDoc } from 'firebase/firestore'
import { useEffect, useState } from 'react'
import { Form } from 'react-bootstrap'
import { Typeahead } from 'react-bootstrap-typeahead'
import { LoadingSpinner } from 'src/components/Spinner/LoadingSpinner'
import { useAppContext } from 'src/hooks/useAppContext'
import { addNewAgency } from 'src/pages/Agencies/util_agencies'
import { AgencySimpleType } from 'src/types/types_agencies'
import { TourRequestType } from 'src/types/types_tourrequest'
import { convertTourRequestDates } from 'src/util/util_firestoredates'



export function FixAgencyNames() {


  const { db, setDbError, userDetails } = useAppContext()



  const [agencyList, setAgencyList] = useState<AgencySimpleType[]>()
  useEffect(() => {
    getDoc(doc(db, '_cachedlists', 'cachedlistAgencies'))
      .then((doc: DocumentSnapshot) => {
        if (doc.data()) {
          const objlist: AgencySimpleType[] = doc.data()!.objlist
          setAgencyList(objlist)
        }
      })
      .catch((err) => setDbError('Getting cachedlistAgencies', err))
  }, [db, setDbError])


  const [requestList, setRequestList] = useState<TourRequestType[]>()
  useEffect(() => {

    const processSnapshot = function (snapshot: QuerySnapshot) {
      const tourrequests: TourRequestType[] = [];
      for (const doc of snapshot.docs) {
        const tourrequest = { ...doc.data(), id: doc.id }
        convertTourRequestDates(tourrequest)
        tourrequests.push(tourrequest as TourRequestType)
      }
      setRequestList(tourrequests)
    }

    const queryConstraints = []
    queryConstraints.push(orderBy('dateCreated', 'desc'))
    queryConstraints.push(limit(10))

    const q = query(collection(db, 'tourrequests'), ...queryConstraints)
    const unsubscribe = onSnapshot(q, processSnapshot, (err) => setDbError('Getting request list', err));
    //getDocs(q).then(processSnapshot)

    return unsubscribe
  }, [db, setDbError])


  if (!requestList || !agencyList)
    return <LoadingSpinner />



  // const badAgencies = []
  const badRequests = []

  for (const req of requestList) {
    const agency = req.agencyOrPlatform
    if (!agencyList.find((a) => a.name === agency)) {
      badRequests.push(req)
    }
  }

  // agencies.sort(stringCompare)
  // bad.sort(stringCompare)

  console.log(badRequests)



  return (
    <div className='container'>

      <h2>Fix agency names</h2>

      Official names:
      <Form.Control as='textarea' readOnly={true} value={agencyList.map((a) => a.name).join('\n')} rows={10} />

      <table className='table mt-3'>
        <thead>
          <tr>
            <th>Bad name</th>
            <th>Correct name</th>
          </tr>
        </thead>
        <tbody>
          {badRequests.map((req) => {

            return (
              <tr key={req.id}>
                <td>
                  {req.agencyOrPlatform}
                </td>
                <td>
                  <Typeahead
                    className='typeahead-suppress-popper-warning'
                    id={`typeahead-${req.id}`}
                    //labelKey='label'
                    onChange={(list) => {
                      // if (list.length > 0 && list[0].customOption) {
                      //   callbackCommitChange(list[0].label)
                      //   return
                      // }
                      // if (list.length > 0)
                      //   callbackCommitChange(list[0].label)
                      // else
                      //   callbackCommitChange('')

                      console.log(list)

                      if (list.length === 1) {
                        const item = list[0]
                        let correctName = ''
                        if (typeof item !== 'string') {
                          if (!item.customOption)
                            throw new Error('unknown')

                          correctName = item.label

                          addNewAgency(db, userDetails, agencyList, correctName, '')
                            .then(({ newSimpleAgencyList }) => {
                              setAgencyList(newSimpleAgencyList)
                            })
                            .catch((err) => setDbError('Adding new agency', err))

                        } else {
                          correctName = item
                        }

                        console.log(correctName)

                        if (correctName !== req.agencyOrPlatform) {

                          updateDoc(doc(db, 'tourrequests', req.id), { agencyOrPlatform: correctName })
                            .then(() => {
                              console.log('success')
                            })

                        }
                      }
                    }}
                    options={agencyList}
                    //placeholder="Choose an item..."
                    //selected={currentSelection}
                    allowNew={true}
                    newSelectionPrefix='▷ '
                  //filterBy={['label']}
                  //autoFocus={true}
                  // onKeyDown={(e) => {
                  //   if (e.key === 'Escape')
                  //     setEditedCell('')
                  // }}
                  />


                </td>
              </tr>
            )
          })}
        </tbody>
      </table>

    </div>
  )
}
