import { InvoicePartialAssignmentType, InvoiceRawType, InvoiceType } from '../../types/types_invoices.js'
import { convertInvoiceDates } from '../../util/util_firestoredates.js'



export function convertInvoice(invoiceRaw: InvoiceRawType): InvoiceType {
  // convert partial data from Record<string, ...> (indexed by nanoid) to array
  const list: InvoicePartialAssignmentType[] = []
  if (invoiceRaw.partialAssignments) {
    for (const [partialId, partialObj] of Object.entries(invoiceRaw.partialAssignments)) {
      const partial: InvoicePartialAssignmentType = {
        ...partialObj,
        id: partialId,
      }
      list.push(partial)
    }
  }
  delete (invoiceRaw as Partial<InvoiceRawType>).partialAssignments
  const invoice = invoiceRaw as unknown as InvoiceType
  invoice.partialAssignmentList = list
  convertInvoiceDates(invoice)

  return invoice
}
