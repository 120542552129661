import { UserPermissionAllEnumType, UserRolesType } from '../util/user_roles.js';



export const userDepartmentList = [
  '',
  'Dedicated Accounts',
  'FIT',
  'MICE & French Market',
  'Leisure Groups',
] as const
export type UserDepartmentType = typeof userDepartmentList[number]

export const userTeamList = [
  'CEO',
  'Administration',

  // 'Group & MICE', // old: to delete
  // 'FIT',          // old: to delete

  // these 2 have a department attached:
  'Travel Designer',
  'Operations',

  'Guide',

  // not employees:
  'Former employees',
  'Freelance guides',
  'External accountant',
  'Other',
] as const
export type UserTeamType = typeof userTeamList[number]

export const userTeamNonEmployeeList = [
  'Former employees',
  'Freelance guides',
  'External accountant',
  'Other',
] as const


export const userTeamCanHaveDepartmentList = [
  'Travel Designer',
  'Operations',
  // 'FIT',
  // 'Group & MICE',
]



export type UserPermissionType = {
  id: string;
  email: string;
  dateModified: Date;
  _aliasUid?: string;
} & Record<UserPermissionAllEnumType, boolean>



export type LanguagePreferenceType = 'en' | 'ja'

export type UserDetailsBasicType = {
  id: string;
  email: string;
  displayNameEn: string;

  // not stored in db
  trueUid?: string;
  trueEmail?: string;
  trueName?: string;
}

export type UserDetailsType = UserDetailsBasicType & {
  displayName: string;
  teamName: UserTeamType;
  departmentName: UserDepartmentType;
  // role: UserRoleMainType;
  roles: UserRolesType;

  photoURL: string; // from Google auth api

  photoFileURL: string; // URL on Google's server used to get the file that was saved to storage
  photoFile: string; // Storage path
  photoFileDownloadURL: string; // URL to download the file from Storage
  photoFileLastFetched: Date; // DateTime

  photoLargeFileURL: string; // URL on Google's server used to get the file that was saved to storage
  photoLargeFile: string; // Storage path
  photoLargeFileDownloadURL: string; // URL to download the file from Storage
  photoLargeFileLastFetched: Date; // DateTime

  singleCharacterCode: string;
  dateCreated: Date;
  dateModified: Date;
  dateLastLoggedIn: Date; // DateTime
  preferences?: {
    defaultEmergencyPhone?: string,
    invoicesPayeesEnglish?: boolean,
  };
  languagePreference?: LanguagePreferenceType;
  freeeNumber?: number;
  freeeName?: string;
  manager?: {
    uid: string;
    email: string;
    name: string;
  } | null;
  directreports?: Record<string, boolean>; // uid => true
  guideNumber?: number;
  guideYearStartsOn?: string;
  guidePaidTimeOff?: number;
  // calendarRole: 'editor' | null;
  // roleViewAllGuidesExpenseAccounting?: boolean;
}

export type UserSimpleType = {
  id: string;
  email: string;
  name: string;
}

export type UserSimpleUidType = {
  uid: string;
  email: string;
  name: string;
}

export type UserSimpleTeamType = {
  id: string;
  email: string;
  name: string;
  teamName: UserTeamType;
}

export type UserSimpleHoursType = {
  id: string;
  email: string;
  name: string;
  hours?: number; // optional: not specified on some old entries. currently it's not possible to add guiding without specifying hours.
  am_pm_only?: 'am_only' | 'pm_only' | ''; // optional: not specified on some old entries. currently it's not possible to add guiding without specifying hours.
}

export type UserSimpleTeamUidType = {
  uid: string;
  email: string;
  name: string;
  teamName: UserTeamType;
}

