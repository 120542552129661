import { GeneralExpenseRowType, GeneralExpenseType } from 'src/types/types_generalexpense'
import { UserDetailsType } from 'src/types/types_user'
import { serverTimestampAsDate } from 'src/util/util_firestoredates'
import { arraySum, laxCalc } from 'src/util/util_misc'
import { nano_id } from 'src/util/util_nano_id'
import { getUserSimple } from 'src/util/util_users'



export function getNewGeneralExpenseItem(isTransportation: boolean) {
  if (!isTransportation) {
    const row: GeneralExpenseRowType = {
      id: nano_id(),
      isTransportation: false,
      description: '',
      unitCost: null,
      quantity: 1,
      totalCost: null,
      receipts: [],
      memorandum: '',
    }
    return row
  } else {
    const row: GeneralExpenseRowType = {
      id: nano_id(),
      isTransportation: true,
      transportationMode: '',
      transportationLine: '',
      transportationFrom: '',
      transportationTo: '',
      unitCost: null,
      quantity: 1,
      totalCost: null,
      receipts: [],
      memorandum: '',
    }
    return row
  }
}

export function updateTotalCostOneRow(row: GeneralExpenseRowType) {
  // TODO: delete and inline this function ??
  row.totalCost = laxCalc(row.unitCost! * row.quantity, row.unitCost, row.quantity)
}

export function objectFromArray(arr: { id: string }[]) {
  // convert array to object for storing in Firestore
  return arr.reduce((acc, cur) => {
    acc[cur.id] = cur
    return acc
  }, {} as Record<string, any>)
}

export function arrayFromObject(obj: Record<string, any>) {
  // convert object back to array for easier handling in app
  if (!obj) return []
  return Object.values(obj)
}

export function getTotalAmount(itemList: GeneralExpenseRowType[]) {
  let totalAmount: number | null
  if (itemList.length === 1 && itemList[0].totalCost === null) {
    totalAmount = null
  } else {
    totalAmount = arraySum(itemList.map((item) => item.totalCost ?? 0))
  }
  return totalAmount
}

export function addMetadataModifiedGeneralExpense(updateObj: Partial<GeneralExpenseType>, userDetails: UserDetailsType) {
  updateObj.dateModified = serverTimestampAsDate()
  updateObj.userModified = getUserSimple(userDetails)
}
