

import { DocumentSnapshot, QuerySnapshot, collection, doc, onSnapshot, query, updateDoc, where } from 'firebase/firestore'
import { useEffect, useMemo, useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { Link, useParams } from 'react-router-dom'
import { ButtonTW } from 'src/components/Buttons/ButtonTW'
import { CheckboxSwitch } from 'src/components/Buttons/CheckboxSwitch'
import { getLoadingSpinnerOrNull } from 'src/components/Spinner/util_getLoadingSpinnerOrNull'
import { useUndoRedo } from 'src/hooks/autosave/useUndoRedo'
import { autosaveDocument } from 'src/hooks/autosave/util_autosave'
import { useAppContext } from 'src/hooks/useAppContext'
import { ExpensePaymentType, ExpenseSheetType, ExpenseWorkflowHistoryItemType } from 'src/types/types_expensesheet'
import { UserSimpleUidType } from 'src/types/types_user'
import { dateFormatJpWithTime } from 'src/util/dateformattools'
import { userrole_canEditAnyInvoice } from 'src/util/user_roles'
import { verifyNotDeleted } from 'src/util/util_db_misc'
import { convertExpensePaymentDates, convertExpenseSheetDates } from 'src/util/util_firestoredates'
import { log_db_read, log_possible_bug } from 'src/util/util_log'
import { TopWhiteBarEditControls } from '../../hooks/autosave/TopWhiteBarEditControls'
import { ExpenseSubmitButton } from './ExpenseSubmitButton'
import { ModalRedirectToExpenseList } from './ModalRedirectToExpenseList'
import { A_GuideName } from './Sections/A_GuideName'
import { B_TourInformation } from './Sections/B_TourInformation'
import { C_CalculationSummary } from './Sections/C_CalculationSummary'
import { D_MealAllowance } from './Sections/D_MealAllowance'
import { E_GuideTransportation } from './Sections/E_GuideTransportation'
import { F_OtherExpenses } from './Sections/F_OtherExpenses'
import { G_PaymentsToGuide } from './Sections/G_PaymentsToGuide'
import { H_ExpenseRules } from './Sections/H_ExpenseRules'
import { WhoInputsThis } from './WhoInputsThis'
import './expensesheet.css'
import { calculateAmounts } from './util_calcamounts'
import { addMetadataModifiedExpenseSheet } from './util_db_expensesheet'
import { getWorkflowHistoryMaxId } from './util_expensesheetobject'
import { useUserListSimpleUid } from './util_getuserlist'
import { getStatusLabel } from './util_status'
import { validateSheet } from './util_validate'



export type AutosaveSheetType = (
  action: string,
  updateObj: Partial<ExpenseSheetType>,
  sUndoWall: 'u' | 'UNDOWALL', // u = undoable
) => Promise<void>

export function ExpenseSheet() {

  const { db, userDetails, perm, setDbError } = useAppContext()

  const [showModalRedirect, setShowModalRedirect] = useState(false)

  const { expenseId } = useParams()

  // we distinguish:
  //  - isTravelDesignerRole: means user has Travel Designer permissions set in their user profile
  //  - isTravelDesigner:     means they are Travel Designer role on *this* sheet
  //
  // -> isTravelDesigner = isTravelDesignerRole && sheet.userGuideUid !== userDetails.id

  const isTravelDesignerRole = perm('guiding_expenses_view_all')
  const isSeniorInputter = userrole_canEditAnyInvoice(userDetails.roles)

  const [sheet, setSheet] = useState<ExpenseSheetType>()

  const { addToCache, getUndoRedoHistoryChanges } = useUndoRedo<ExpenseSheetType>('expensesheetshistory')

  const [enableEditing, setEnableEditing] = useState(false)
  const [editedCell, setEditedCell] = useState<string | null>(null)

  const [saveStatus, setSaveStatus] = useState<string>()

  const userSimple = useMemo(() => {
    return {
      uid: userDetails.id,
      email: userDetails.email,
      name: userDetails.displayNameEn,
    } as UserSimpleUidType
  }, [userDetails])


  useEffect(() => {
    // loading of expense sheet from database

    if (!expenseId) {
      setSheet(undefined)
      return
    }

    let isLoaded = false

    const processSnapshot = function (expenseDoc: DocumentSnapshot) {
      const sheet = { ...expenseDoc.data(), id: expenseDoc.id } as ExpenseSheetType
      verifyNotDeleted(expenseDoc.exists(), sheet, expenseId, setDbError, 'expensesheet')
      convertExpenseSheetDates(sheet)
      setSheet(sheet)
      if (sheet.history?.currentStepId) {
        addToCache(sheet.history.currentStepId, sheet)
      }

      // opening the sheet just after creating it -> enable editing
      if (sheet.history?.currentStep === 1) {
        setEnableEditing(true)
      }

      {
        const isTravelDesigner = isTravelDesignerRole && sheet.userGuideUid !== userDetails.id
        const maxId = getWorkflowHistoryMaxId(sheet)
        const workflowHistoryItem = sheet.workflowHistory[maxId]
        const { comment, status, dismissedUserIds } = workflowHistoryItem
        const dismissed = dismissedUserIds && dismissedUserIds[userDetails.id]
        if (status !== sheet.status) {
          // should never happen
          console.log('workflow histo last status', status)
          console.log('sheet.status', sheet.status)
          //throw new Error(`inconsistent status ${sheet.id}`) 
          log_possible_bug({ db, userDetails, logkey: 'possible_bug.expense_sheet.inconsistent_status', desc: `inconsistent status on sheet [${sheet.id}]: status is ${sheet.status} but last workflow history item is ${status}` })
        } else {
          if (comment && !dismissed) {
            if (status === 'TD_DRAFT' && isTravelDesigner)
              setLastWorflowHistoryItem(workflowHistoryItem)
            if (status === 'TL_DRAFT' && !isTravelDesigner)
              setLastWorflowHistoryItem(workflowHistoryItem)
            if (status === 'SUBMITTED' && isTravelDesigner)
              setLastWorflowHistoryItem(workflowHistoryItem)
          }
        }
      }

      if (!isLoaded) {
        log_db_read({ db, userDetails, logkey: 'db_read.open_expense_sheet', desc: `Open expense sheet [${sheet.tourCode}] [${sheet.id}]` })
        isLoaded = true
      }
    }

    const q = doc(db, 'expensesheets', expenseId)
    const unsubscribe = onSnapshot(q, processSnapshot, (err) => setDbError(`Getting expense sheet ${expenseId}`, err));

    return unsubscribe

  }, [db, expenseId, isTravelDesignerRole, setDbError, userDetails, addToCache])


  const userGuideUid = sheet?.userGuideUid

  const [advancePaymentsPaid, setAdvancePaymentsPaid] = useState<Map<string, ExpensePaymentType>>()
  useEffect(() => {
    if (!expenseId || !userGuideUid) {
      setAdvancePaymentsPaid(undefined)
      return
    }

    const processSnapshot = function (snapshot: QuerySnapshot) {
      const dict = new Map<string, ExpensePaymentType>()
      for (const doc of snapshot.docs) {
        const item = { ...doc.data(), id: doc.id } as ExpensePaymentType
        convertExpensePaymentDates(item)
        if (item._isDeleted)
          continue

        const id = `${item.sheetId} ${item.advancePaymentId}`
        if (dict.has(id))
          throw new Error(`multiple payments found for the same advance payment ${id}`)
        dict.set(id, item)
      }

      setAdvancePaymentsPaid(dict)
    }

    const onlyOwnExpensePayments = where('userPaymentToUid', '==', userGuideUid) // necessary for permissions if user is a Guide
    const q = query(collection(db, 'expensepayments'), onlyOwnExpensePayments, where('sheetId', '==', expenseId), where('_isDeleted', '==', false))
    const unsubscribe = onSnapshot(q, processSnapshot, (err) => setDbError('Getting expense payments list', err));

    return unsubscribe
  }, [db, expenseId, setDbError, userGuideUid])


  const [lastWorflowHistoryItem, setLastWorflowHistoryItem] = useState<ExpenseWorkflowHistoryItemType | null>(null)

  const userListSimple = useUserListSimpleUid()

  const [showAdvancedOptions, setShowAdvancedOptions] = useState(false)

  // --- all hooks (useState, useEffect) called above. now we are allowed to return ---


  const loadingSpinner = getLoadingSpinnerOrNull([
    ['sheet', sheet],
    ['advance payments', advancePaymentsPaid],
    ['user list', userListSimple],
  ])
  if (!sheet || !advancePaymentsPaid || !userListSimple)
    return loadingSpinner


  const isTravelDesigner = isTravelDesignerRole && sheet.userGuideUid !== userDetails.id

  const userIsAllowedToEdit =
    (sheet.status === 'TD_DRAFT' || sheet.status === 'TL_DRAFT' || sheet.status === 'SUBMITTED')
    && (isTravelDesigner || sheet.status === 'TL_DRAFT')

  if (!userIsAllowedToEdit && enableEditing) {
    // should not be possible??
    setEnableEditing(false)
  }

  const isReadOnly = !(userIsAllowedToEdit && enableEditing)

  const autosaveNewStep: AutosaveSheetType = async (
    userAction: string,
    updateObj: Partial<ExpenseSheetType>,
    sUndoWall: 'u' | 'UNDOWALL', // u = undoable
  ) => {

    // just in case, check if any fields have dots.
    // the reason for this is that originally we did not calculate totals here, so updateObj
    // was only used in Firestore, which accepts the dot notation.
    // but the dot notation will cause incorrect results in calculateAmounts().
    for (const field of Object.keys(updateObj)) {
      if (field.includes('.')) {
        console.error(`FIELD: ${field}`)
        log_possible_bug({ db, userDetails, logkey: 'possible_bug.expense_sheet.field_with_dot', desc: `Field with dot in expense sheet [${expenseId}]: [${field}]` })
      }
    }

    // calculate totals, and store them in the db
    const newSheet = { ...sheet, ...updateObj }
    const calc = calculateAmounts(newSheet, advancePaymentsPaid)
    updateObj.calc = calc

    return autosaveAnyStep(userAction, updateObj, false, undefined, sUndoWall)
  }

  const autosaveAnyStep = async (
    userAction: string,
    updateObj: Partial<ExpenseSheetType>,
    isUndoRedo: boolean,
    undoRedoTargetStep: number | undefined,
    sUndoWall: 'u' | 'UNDOWALL', // u = undoable
  ) => {
    try {
      if (isReadOnly) {
        if (userAction.startsWith('WORKFLOW')) {
          // clicking workflow buttons does not require 'edit mode' to be turned on
        } else {
          setDbError('Field change despite being readonly')
          throw new Error('Field change despite being readonly')
        }
      }

      autosaveDocument(
        updateObj,
        userAction,
        isUndoRedo,
        undoRedoTargetStep,
        sUndoWall,
        expenseId!, // autosaveAnyStep is only called when expense sheet exists in db
        sheet.history,
        userSimple,
        db,
        'expensesheets',
        (updateObj) => addMetadataModifiedExpenseSheet(updateObj, userDetails),
        setSaveStatus,
      )
        .catch((err) => setDbError(`Autosave [autosaveDocument] expensesheet id=${expenseId} action=[${userAction}]`, err))

      // we do NOT call log_db_write because autosave would create excessive logs, and we can just check the history collection anyway
      // log_db_write({db, userDetails, desc: `Autosave expense sheet [${sheet.tourCode}] [${sheet.id}] ${action}`})
    } catch (err) {
      setDbError(`Autosave expensesheet id=${expenseId} action=[${userAction}]`, err)
    }
  }

  const autosaveUndoRedoStep = async (action: 'Undo' | 'Redo', targetStep: number) => {

    const undoRedoData = await getUndoRedoHistoryChanges(action, targetStep, sheet.history)
    if (!undoRedoData)
      // failed to retrieve history step from db
      return

    const { updateObjHistory, targetStepObj } = undoRedoData

    // fields that we remove in general:
    //   - id: we never save id as a field
    //   - _isDeleted: should always be false
    //   - history: specifically tweaked in a precise way by updateObjHistory
    //   - dateCreated, userCreated: immutable metadata
    //   - dateModified, userModified: metadata set upon save
    // expense sheet fields that we remove:
    //   - accountingDateiso/accountingDateSet: is not part of content of sheet; is not set by sheet inputter but by either automatically based on submission date, or manually by Admin staff
    //   - status: same as accountingDateiso, is not part of content of sheet. we do not allow undo/redo accross a change of status
    //   - workflowHistory: same reason
    //   - userGuide*: immutable, can never change

    // delete the field we specifically don't want:
    delete targetStepObj.id
    delete targetStepObj._isDeleted
    delete targetStepObj.history
    // @ts-expect-error field parentDocumentId doesn't exist on type ExpenseSheetType
    delete targetStepObj.parentDocumentId

    delete targetStepObj.dateCreated
    delete targetStepObj.userCreatorEmail
    delete targetStepObj.userCreatorName
    delete targetStepObj.userCreatorUid

    delete targetStepObj.dateModified
    delete targetStepObj.userModifiedEmail
    delete targetStepObj.userModifiedName
    delete targetStepObj.userModifiedUid

    // @ts-expect-error field metadataModified doesn't exist on type ExpenseSheetType
    delete targetStepObj.metadataModified // added incorrectly by the autosaveDocument function

    delete targetStepObj.accountingDateiso
    delete targetStepObj.accountingDateSet
    delete targetStepObj.status
    delete targetStepObj.workflowHistory
    delete targetStepObj.userGuideEmail
    delete targetStepObj.userGuideName
    delete targetStepObj.userGuideUid

    const updateObj: Partial<ExpenseSheetType> = {
      ...targetStepObj,
      ...updateObjHistory,
    }

    autosaveAnyStep(
      action, // this isn't actually used
      updateObj,
      true,
      targetStep,
      'u', // undo/redo step is always undoable
    )
  }




  sheet.calc = calculateAmounts(sheet, advancePaymentsPaid)


  const tourDays: number[] = []
  for (let i = 0; i < sheet.calc.tourNumDays; i++) {
    tourDays.push(i)
  }


  const validationIssues = validateSheet(sheet)
  const isValidated = validationIssues.length === 0
  let validationMessageHtml, validationMessageText
  if (isValidated) {
    validationMessageHtml = 'No errors in input'
    validationMessageText = 'No errors in input'
  } else {
    validationMessageHtml =
      <ul>
        {validationIssues.map((s, index) => <li key={index}>{s}</li>)}
      </ul>
    validationMessageText = validationIssues.map((s) => `- ${s}`).join('\n')
  }
  const validationClass = isValidated ? 'alert-success' : 'alert-warning' // or warning or danger
  const validationIcon = isValidated ? 'bi-check-circle-fill' : 'bi-exclamation-triangle-fill'


  const propsChildComponents = { sheet, tourDays, editedCell, setEditedCell, autosaveNewStep }

  const isReadOnlyDesignerSection = isReadOnly || !isTravelDesigner

  // if (!isTravelDesigner) {
  //   if (sheet.status !== 'TL_DRAFT' && sheet.status !== 'SUBMITTED' && sheet.status !== 'APPROVED')
  //     return <Unauthorized />
  // }



  return (
    <div>
      <Helmet><title>View Expense Sheet</title></Helmet>

      <TopWhiteBarEditControls
        whiteBarActive={true}
        enableEditing={enableEditing}
        setEnableEditing={setEnableEditing}
        saveStatus={saveStatus}
        setSaveStatus={setSaveStatus}
        autosaveUndoRedoStep={autosaveUndoRedoStep}
        history={sheet.history}
        divFloatingTotals={null}
        userIsAllowedToEdit={userIsAllowedToEdit}
      />

      <div className='container mt-4'>
        <div className='mb-3'>
          <Link to='/guide/expenses/'>&lt;&lt;Back to expense sheet list</Link>
        </div>

        {lastWorflowHistoryItem && (
          <div className='messageContainer alert alert-warning'>
            <div className='titleRow'>
              <h5>Comment from {lastWorflowHistoryItem.userEmail}</h5>
              <i className='bi bi-x-lg' onClick={(e) => {
                setLastWorflowHistoryItem(null)
              }}></i>
            </div>
            <p>{lastWorflowHistoryItem.comment}</p>
            <div className='buttonRow'>
              <ButtonTW variant='blue' textSize='md' onClick={() => {
                // note: this doesn't use autosave, so it's not "undo safe". not a big deal as if the change gets undone, the alert just gets shown again to the user.
                updateDoc(doc(db, 'expensesheets', expenseId!), {
                  [`workflowHistory.${lastWorflowHistoryItem.id}.dismissedUserIds.${userDetails.id}`]: true,
                })
                setLastWorflowHistoryItem(null)
              }}>
                Dismiss
              </ButtonTW>
              {/* <ButtonTW variant="bsDarkGray" textSize='md' onClick={() => setLastWorflowHistoryItem(null)}>
              Close
            </ButtonTW> */}
            </div>
          </div>
        )}

        <div className={`expense-sheet px-4 py-1 ${sheet.status === 'SUBMITTED' ? 'sheet-submitted ' : ''}`}>
          <h3 className='my-4'>Guide expense sheet</h3>

          <div>
            After making changes, always click the ‘Save draft’ button at the bottom to save changes.
            If the page is refreshed or closed without saving, any changes will be lost.
          </div>


          <div className={'input-section'}>
            <A_GuideName {...propsChildComponents} isReadOnly={isReadOnlyDesignerSection} userListSimple={userListSimple} />
          </div>



          <div className={`input-section ${sheet.status === 'TD_DRAFT' ? 'active' : ''}`}>
            <WhoInputsThis show={sheet.status === 'TD_DRAFT'} role='travel designer' />
            <B_TourInformation {...propsChildComponents} isReadOnly={isReadOnlyDesignerSection} userListSimple={userListSimple} />
          </div>



          <div className={'input-section'}>
            <C_CalculationSummary sheet={sheet} />
          </div>


          <div className={`input-section ${sheet.status === 'TD_DRAFT' ? 'active' : ''}`}>
            <WhoInputsThis show={sheet.status === 'TD_DRAFT'} role='travel designer' />

            <D_MealAllowance {...propsChildComponents} isReadOnly={isReadOnlyDesignerSection} />
          </div>



          <div className={`input-section ${sheet.status === 'TL_DRAFT' ? 'active' : ''}`}>
            <WhoInputsThis show={sheet.status === 'TL_DRAFT'} role='guide' />

            <E_GuideTransportation {...propsChildComponents} isReadOnly={isReadOnly} />

            <F_OtherExpenses {...propsChildComponents} isReadOnly={isReadOnly} />
          </div>


          <div className={`input-section ${sheet.status === 'TD_DRAFT' ? 'active' : ''}`}>
            <WhoInputsThis show={sheet.status === 'TD_DRAFT'} role='travel designer' />

            <G_PaymentsToGuide {...propsChildComponents} isReadOnly={isReadOnlyDesignerSection} advancePaymentsPaid={advancePaymentsPaid} />
          </div>


          <hr className='mt-5' />


          {!isReadOnly && (
            <div className={`d-inline-flex ms-3 alert ${validationClass} d-flex align-items-center`} role='alert'>
              <i className={`bi ${validationIcon}`}></i>
              <div className='ms-2'>
                {validationMessageHtml}
              </div>
            </div>
          )}

          <hr className='mt-2' />

          <div>
            <h5>Workflow</h5>
            <div className='workflow'>
              {['TD_DRAFT', 'TL_DRAFT', 'SUBMITTED', 'APPROVED'].map((s, i) => {
                return (
                  <span key={s}>
                    <span className={`status ${sheet.status === s ? 'active' : ''}`}>{getStatusLabel(s)}</span>
                    {i < 3 && (
                      <i className='bi bi-chevron-double-right'></i>
                    )}
                  </span>
                )
              })}
            </div>
          </div>

          <div className='mt-3'>

            {sheet.status === 'TD_DRAFT' && (
              <>
                {isTravelDesigner && (
                  <ExpenseSubmitButton
                    sheet={sheet}
                    label='Send to Guide'
                    isNeedValidation={true}
                    isValidated={isValidated}
                    validationMessageText={validationMessageText}
                    newStatus='TL_DRAFT'
                    styleColor='success'
                    autosaveNewStep={autosaveNewStep}
                    arrows='next'
                    closeSheet={true}
                    setShowModalRedirect={setShowModalRedirect}
                  />
                )}
              </>
            )}

            {sheet.status === 'TL_DRAFT' && (
              <>
                {isTravelDesigner ? (
                  <>
                    <div>
                      <ExpenseSubmitButton
                        sheet={sheet}
                        label='Withdraw from Guide'
                        isNeedValidation={false}
                        newStatus='TD_DRAFT'
                        styleColor='danger'
                        autosaveNewStep={autosaveNewStep}
                        arrows='previous'
                        setShowModalRedirect={setShowModalRedirect}
                      />

                      {(isSeniorInputter && showAdvancedOptions) && (
                        <ExpenseSubmitButton
                          sheet={sheet}
                          label='Override Guide: Mark Approved'
                          isNeedValidation={true}
                          isValidated={isValidated}
                          newStatus='APPROVED'
                          styleColor='danger'
                          autosaveNewStep={autosaveNewStep}
                          arrows='next'
                          setShowModalRedirect={setShowModalRedirect}
                          confirmGuideOverride={true}
                        />
                      )}
                    </div>
                    <div>
                      {isSeniorInputter && (
                        <CheckboxSwitch
                          id='chkShowAdvanced'
                          label='Show advanced workflow actions'
                          checked={showAdvancedOptions}
                          onChange={(e) => {
                            setShowAdvancedOptions(e.target.checked)
                          }}
                        />
                      )}
                    </div>
                  </>
                ) : (
                  <>
                    <ExpenseSubmitButton
                      sheet={sheet}
                      label='Request changes'
                      isNeedValidation={false}
                      newStatus='TD_DRAFT'
                      styleColor='danger'
                      autosaveNewStep={autosaveNewStep}
                      arrows='previous'
                      closeSheet={true}
                      setShowModalRedirect={setShowModalRedirect}
                    />

                    <ExpenseSubmitButton
                      sheet={sheet}
                      label='Submit expense sheet'
                      isNeedValidation={true}
                      isValidated={isValidated}
                      validationMessageText={validationMessageText}
                      newStatus='SUBMITTED'
                      styleColor='success'
                      autosaveNewStep={autosaveNewStep}
                      arrows='next'
                      closeSheet={true}
                      setShowModalRedirect={setShowModalRedirect}
                    />
                  </>
                )}
              </>
            )}

            {sheet.status === 'SUBMITTED' && (
              <>
                {isTravelDesigner ? (
                  <>
                    <ExpenseSubmitButton
                      sheet={sheet}
                      label='Withdraw'
                      isNeedValidation={false}
                      newStatus='TD_DRAFT'
                      styleColor='danger'
                      autosaveNewStep={autosaveNewStep}
                      arrows='previous x2'
                      setShowModalRedirect={setShowModalRedirect}
                    />

                    <ExpenseSubmitButton
                      sheet={sheet}
                      label='Return to Guide'
                      isNeedValidation={false}
                      newStatus='TL_DRAFT'
                      styleColor='danger'
                      autosaveNewStep={autosaveNewStep}
                      arrows='previous'
                      setShowModalRedirect={setShowModalRedirect}
                    />

                    <ExpenseSubmitButton
                      sheet={sheet}
                      label='Approve'
                      isNeedValidation={true}
                      isValidated={isValidated}
                      validationMessageText={validationMessageText}
                      newStatus='APPROVED'
                      styleColor='success'
                      autosaveNewStep={autosaveNewStep}
                      arrows='next'
                      closeSheet={true}
                      setShowModalRedirect={setShowModalRedirect}
                    />
                  </>
                ) : (
                  <>
                    {/* we don't allow the guide to withdraw themselves after submission */}
                    {/* <ExpenseSubmitButton
                      sheet={sheet}
                      label='Withdraw'
                      isNeedValidation={false}
                      newStatus='TL_DRAFT'
                      styleColor='danger'
                      autosaveNewStep={autosaveNewStep}
                      setShowModalRedirect={setShowModalRedirect}
                    /> */}
                  </>
                )}
              </>
            )}

            {sheet.status === 'APPROVED' && (
              <>
                {isTravelDesigner && (
                  <>
                    <ExpenseSubmitButton
                      sheet={sheet}
                      label='Withdraw'
                      isNeedValidation={false}
                      newStatus='TD_DRAFT'
                      styleColor='danger'
                      autosaveNewStep={autosaveNewStep}
                      arrows='previous'
                      setShowModalRedirect={setShowModalRedirect}
                    />
                  </>
                )}
              </>
            )}

          </div>


          <hr />


          {isTravelDesigner && (
            <>
              <h5 className='mt-4'>Workflow History</h5>

              <table className='table'>
                <thead>
                  <tr>
                    <th>ID</th>
                    <th>Date</th>
                    <th>Status</th>
                    <th>Comment</th>
                    <th>User</th>
                  </tr>
                </thead>
                <tbody>
                  {Object.keys(sheet.workflowHistory).map((key, index) => {
                    const histo = sheet.workflowHistory[key]
                    return (
                      <tr key={key}>
                        <td>
                          {histo.id}
                        </td>
                        <td>
                          {histo.date && (histo.date instanceof Date)
                            ? dateFormatJpWithTime(histo.date)
                            // serverTimestamp() set in object for saving to db
                            : 'Just now'
                          }
                        </td>
                        <td>
                          {histo.status}
                        </td>
                        <td>
                          {histo.comment}
                        </td>
                        <td>
                          {histo.userEmail}
                        </td>
                      </tr>
                    )
                  })}
                </tbody>
              </table>

              <hr />
            </>
          )}



          <h5 className='mt-4'>Notes</h5>
          <ul>
            <li>All prices are in JPY and inclusive of consumption tax</li>
          </ul>



          <H_ExpenseRules />


          <ModalRedirectToExpenseList
            show={showModalRedirect}
            setShow={setShowModalRedirect}
          />

        </div>

      </div>
    </div>
  )
}
