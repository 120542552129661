


// all the visually available pills: (8 of them)
export type PillListType = 'CONFIRMED' | 'ONGOING' | 'CANCELLED' | 'LOST' | 'Pre-tour' | 'On-tour' | 'Post-tour' | 'Archived'

// all the distinct possible states of a tour request: (7 of them, excluding 'CONFIRMED')
export type PillListMutuallyExclusiveType = 'ONGOING' | 'CANCELLED' | 'LOST' | 'Pre-tour' | 'On-tour' | 'Post-tour' | 'Archived'

interface RequestStatusOnePillProps {
  label: PillListType;
  selected: boolean;
  clickHandler: ((clicked: PillListType) => void) | undefined; // undefined means not clickable; cursor will not be pointer
}

export function RequestStatusOnePill({
  label,
  selected,
  clickHandler,
}: RequestStatusOnePillProps) {

  const clickable = clickHandler ? true : false

  const tw_common = `tw-border tw-border-solid tw-inline-flex tw-px-1 tw-rounded tw-font-bold ${clickable ? ' tw-cursor-pointer' : ' tw-cursor-default'}`

  const tw_color = getStatusTw(label)

  return (
    <span className={`${tw_common} ${selected ? tw_color.selected : tw_color.deselected}`}
      onClick={clickHandler ? (() => clickHandler(label)) : undefined}>
      {label}
    </span>
  )

}

function getStatusTw(
  label: PillListType,
) {
  const tw_amber_on = 'tw-bg-amber-400 tw-text-white tw-border-amber-400'
  const tw_amber_off = 'tw-bg-white tw-text-amber-400/75 tw-border-amber-400/75'

  const tw_slate_on = 'tw-bg-slate-600 tw-text-white tw-border-slate-600'
  const tw_slate_off = 'tw-bg-white tw-text-slate-600/75 tw-border-slate-600/75'

  const tw_red_on = 'tw-bg-red-600 tw-text-white tw-border-red-600'
  const tw_red_off = 'tw-bg-white tw-text-red-600/75 tw-border-red-600/75'

  const tw_green_on = 'tw-bg-green-700 tw-text-white tw-border-green-700'
  const tw_green_off = 'tw-bg-white tw-text-green-700/75 tw-border-green-700/75'

  const tw_teal_on = 'tw-bg-teal-500 tw-text-white tw-border-teal-500'
  const tw_teal_off = 'tw-bg-white tw-text-teal-500/75 tw-border-teal-500/75'

  const tw_violet_on = 'tw-bg-violet-500 tw-text-white tw-border-violet-500'
  const tw_violet_off = 'tw-bg-white tw-text-violet-500/75 tw-border-violet-500/75'

  const tw_stone_on = 'tw-bg-stone-500 tw-text-white tw-border-stone-500'
  const tw_stone_off = 'tw-bg-white tw-text-stone-500/75 tw-border-stone-500/75'

  // const tw_slate_on = 'tw-bg-slate-500 tw-text-white tw-border-slate-500'
  // const tw_slate_off = 'tw-bg-white tw-text-slate-500/75 tw-border-slate-500/75'

  if (label === 'ONGOING') return { selected: tw_amber_on, deselected: tw_amber_off }
  if (label === 'LOST') return { selected: tw_slate_on, deselected: tw_slate_off }
  if (label === 'CANCELLED') return { selected: tw_red_on, deselected: tw_red_off }
  if (label === 'CONFIRMED') return { selected: tw_green_on, deselected: tw_green_off }
  if (label === 'Pre-tour') return { selected: tw_teal_on, deselected: tw_teal_off }
  if (label === 'On-tour') return { selected: tw_violet_on, deselected: tw_violet_off }
  if (label === 'Post-tour') return { selected: tw_stone_on, deselected: tw_stone_off }
  if (label === 'Archived') return { selected: tw_slate_on, deselected: tw_slate_off }

  // unreachable
  const _typecheck: never = label
  return { selected: '', deselected: '' }

}
