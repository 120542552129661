import { addDoc, collection } from 'firebase/firestore'
import { Dispatch, SetStateAction } from 'react'
import { Form } from 'react-bootstrap'
import { ModalPopup } from 'src/components/Modal/ModalPopup'
import { useAppContext } from 'src/hooks/useAppContext'
import { DateInput } from 'src/pages/ExpenseSheet/DateInput'
import { EDPaymentSourceAccountDropdown } from 'src/pages/Invoices/EDPaymentSourceAccountDropdown'
import { ExpensePaymentType } from 'src/types/types_expensesheet'
import { PaymentTypeEnumType } from 'src/types/types_generalexpense'
import { dateisoFormatJpShort } from 'src/util/dateformattools'
import { iso_from_local0, jst0_from_iso, local0_from_iso } from 'src/util/datetools'
import { serverTimestampAsDate } from 'src/util/util_firestoredates'
import { formatNum2 } from 'src/util/util_formatnum'
import './expensepaymentsmodal.css'
import { getGridRowsDescribingPayment } from './util_makeexpensepayment'



export type ModalActionPaymentDbPropsType = {
  // guiding expense
  isAdvance?: boolean,
  tourCode?: string,
  sheetId?: string,
  advancePaymentId?: string,
  // general expense
  generalExpenseId?: string,
  // common
  salaryMonth?: string,
}

export type ModalActionExpensePaymentType = {
  action: string,
  showModal: boolean,
  paymentRecipient: {
    id: string,
    email: string,
  },
  paymentDbProps: ModalActionPaymentDbPropsType,
  paymentDisplayProps?: { // only passed when paymentType===ADHOC_BANK_TRANSFER (advance payment), not when paymentType===WITH_SALARY
    desiredDate: Date | null, // JST. TODO: convert to dateiso
  },
  paymentId: string,
  paymentFor: 'GENERAL_EXPENSE' | 'GUIDING_EXPENSE',
  paymentType: PaymentTypeEnumType | '',
  paymentSourceAccount: string,
  amount: number | string | null, // will be string if user input is not a valid number, e.g. ''
  paymentDateIso: string,
  callbackOnSuccess?: () => void,
}


interface ModalPopupMakeExpensePaymentProps {
  modalAction: ModalActionExpensePaymentType | null,
  setModalAction: Dispatch<SetStateAction<ModalActionExpensePaymentType | null>>,
}

export function ModalPopupMakeExpensePayment({ modalAction, setModalAction }: ModalPopupMakeExpensePaymentProps) {

  // This component is used for both GENERAL EXPENSES and GUIDING EXPENSES (advance payments)

  const { db, userDetails, setDbError } = useAppContext()
  const makePayment = modalAction?.action === 'make payment'

  return (
    <ModalPopup
      title={makePayment ? 'Mark Paid' : 'View payment'}
      okLabel='OK'
      hideOkButton={!makePayment}
      show={modalAction?.showModal ?? false}
      className='modal-lg'
      callbackClose={() => setModalAction((modalAction) => {
        const newModalAction: ModalActionExpensePaymentType = { ...modalAction!, showModal: false } // here we do not set modalAction to null, as we want to preserve the modal form appearence while it fades out
        return newModalAction
      })}
      onSubmit={(e, onSuccess) => {
        e.preventDefault()

        if (!modalAction)
          throw new Error('modalAction is null')

        if (modalAction.action === 'make payment') {

          const paymentSourceAccount = modalAction.paymentSourceAccount
          if (!paymentSourceAccount || paymentSourceAccount === 'Select payment source account') {
            alert('Select payment source account')
            return
          }

          const amount = modalAction.amount
          if (!(typeof amount === 'number')) {
            alert('input valid amount')
            return
          }

          const paymentDateJST = jst0_from_iso(modalAction.paymentDateIso)


          const paymentObj: Omit<ExpensePaymentType, 'id'> = {
            ...modalAction.paymentDbProps,
            userPaymentToUid: modalAction.paymentRecipient.id,
            userPaymentToEmail: modalAction.paymentRecipient.email,
            paymentSourceAccount, // SBI, SMBC, Rakuten, Cash, Other, etc.
            paymentFor: modalAction.paymentFor, // GUIDING_EXPENSE, GENERAL_EXPENSE
            paymentType: modalAction.paymentType, // CASH, ADHOC_BANK_TRANSFER, WITH_SALARY
            amount,
            paymentDate: paymentDateJST,

            // metadata
            _isDeleted: false,
            status: 'PAID', // can be 'DELETED'
            dateCreated: serverTimestampAsDate(),
            dateModified: serverTimestampAsDate(),
            userCreatorUid: userDetails.id,
            userCreatorEmail: userDetails.email,
            userCreatorName: userDetails.displayNameEn,
          }

          addDoc(collection(db, 'expensepayments'), paymentObj)
            .then((result) => {
              // success
              console.log('added to db', paymentObj, result)
              if (modalAction.callbackOnSuccess) modalAction.callbackOnSuccess()
              if (onSuccess) onSuccess()
            })
            .catch((err) => {
              console.log(err)
              alert(`Error saving payment: ${err}`)
            })


        } else {
          throw new Error(`unknown modal action ${modalAction.action}`)
        }

      }}
      body={modalAction && (

        <div className='modal-grid'>

          <div className='pt-2'>Payment to:</div>
          <div className=''><Form.Control type='text' disabled={true} value={modalAction.paymentRecipient.email} /></div>

          {getGridRowsDescribingPayment(modalAction)}

          <div style={{ gridColumn: 'span 2' }}><hr /></div>


          <div className='pt-2'>Payment source account:</div>
          <div className=''>
            <EDPaymentSourceAccountDropdown id='paymentSourceAccount' disabled={!makePayment} value={modalAction.paymentSourceAccount} onChange={(e) => {
              setModalAction((modalAction) => {
                const newModalAction: ModalActionExpensePaymentType = { ...modalAction!, paymentSourceAccount: e.target.value }
                return newModalAction
              })
            }} />
          </div>

          <div className='pt-2'>Amount:</div>
          <div className=''>
            {makePayment && (
              <div style={{ color: 'darkred' }}>
                Please input the actual amount that will be paid on <b>{dateisoFormatJpShort(modalAction.paymentDateIso)}</b>.<br />
                If nothing is paid this month, please input <b>0</b> and click OK to set the payment amount to zero.<br />
                Any unpaid balance will automatically be carried over to the next month.
              </div>
            )}
            <Form.Control type='text' id='amount' disabled={!makePayment} value={formatNum2(modalAction.amount ?? '')} onChange={(e) => {
              console.log('change')
              const newValue = e.target.value
              const num = Number(newValue.replaceAll(',', ''))
              const newValueDb = (newValue !== '' && !isNaN(num)) ? num : newValue

              setModalAction((modalAction) => {
                const newModalAction: ModalActionExpensePaymentType = { ...modalAction!, amount: newValueDb }
                return newModalAction
              })
            }} />
          </div>

          <div className='pt-2'>Payment date:</div>
          <div className=''><DateInput disabled={!makePayment} value_local0={local0_from_iso(modalAction.paymentDateIso)} onChange={(date_local0) => {
            // TODO: payment date should be stored as iso string
            const paymentDateIso = date_local0 ? iso_from_local0(date_local0) : '' // TODO: properly handle case of null date
            setModalAction((modalAction) => {
              const newModalAction: ModalActionExpensePaymentType = { ...modalAction!, paymentDateIso }
              return newModalAction
            })
          }} /></div>

          {modalAction.action === 'show payment' && (
            <>
              <div className=''>Payment ID:</div>
              <div className=''>{modalAction.paymentId}</div>
            </>
          )}

        </div>

      )} />


  )
}
