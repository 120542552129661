import { QueryConstraint, QuerySnapshot, collection, getDocs, query, updateDoc, where } from 'firebase/firestore'
import Papa from 'papaparse'
import { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { ButtonTW } from 'src/components/Buttons/ButtonTW'
import { CheckboxSwitch } from 'src/components/Buttons/CheckboxSwitch'
import { LoadingSpinner } from 'src/components/Spinner/LoadingSpinner'
import { useAppContext } from 'src/hooks/useAppContext'
import { TourRequestType } from 'src/types/types_tourrequest'
import { addDays } from 'src/util/datetools'
import { convertTourRequestDates } from 'src/util/util_firestoredates'
import { stripFinalLf } from './util_misc'



type TourType = {
  tourName: string;
  custName: string;
  code: string;
  agency: string;
  numPax: string;
  designer: string;
  color: string;
  wifi: string;
  requestNum?: number | string;
}


const colors = new Map([
  [43, 'FIT'],
  [42, 'Event'],
  [48, 'Guide holiday'],
  [6, 'Explore Simply'],
  [37, 'Explore Other'],
])


export function ImportTourCalendar() {

  const { db, setDbError } = useAppContext()

  const [err, setErr] = useState<string>()

  const [tours, setTours] = useState<TourType[]>()


  const [requestList, setRequestList] = useState<TourRequestType[]>()
  useEffect(() => {

    const DOWNLOAD_LIVE_REQUEST_DATA = false

    if (DOWNLOAD_LIVE_REQUEST_DATA) {
      const processSnapshot = function (snapshot: QuerySnapshot) {
        const tourrequests: TourRequestType[] = [];
        for (const docu of snapshot.docs) {
          const tourrequest = { ...docu.data(), id: docu.id } as TourRequestType
          convertTourRequestDates(tourrequest)
          tourrequests.push(tourrequest)
        }
        setRequestList(tourrequests)
      }

      const queryConstraints: QueryConstraint[] = []
      //queryConstraints.push(where('_isDeleted', '==', false))
      //queryConstraints.push(orderBy('dateCreated', 'desc'))

      const q = query(collection(db, 'tourrequests'), ...queryConstraints)
      //const unsubscribe = onSnapshot(q, processSnapshot, err => setDbError("Getting request list", err));
      getDocs(q).then(processSnapshot)

      //return unsubscribe

    } else {
      fetch('/dev_data/db_dump_TEST_tourrequests.json')
        .then((response) => {
          // console.log(response)
          if (!response.ok) {
            setDbError(`Failed to download tourrequests.json. ${response.status} ${response.statusText}`)
            return
          }
          response.json().then((obj) => {
            setRequestList(obj.tourrequests)
          })
        })
        .catch((err) => setDbError('Downloading dev json', err))
    }
  }, [db, setDbError])


  // download calendar csv
  const [calendarText, setCalendarText] = useState<string>()
  useEffect(() => {
    fetch('/dev_data/Tour Calendar 2022&2023 LG as of 20230811.csv')
      .then((response) => {
        // console.log(response)
        if (!response.ok) {
          setDbError(`Failed to download calendar csv. ${response.status} ${response.statusText}`)
          return
        }
        response.text().then((text) => {
          setCalendarText(text)
        })

      })
      .catch((err) => setDbError('Downloading calendar csv', err))
  }, [setDbError])

  // *** all hooks above ***

  if (!requestList || !calendarText)
    return <LoadingSpinner />



  function parseCalendarText(contents: string, saveToDb: boolean = false) {
    // strip final new line as papaparse doesn't like it
    contents = stripFinalLf(contents)


    Papa.parse(contents, {
      header: false,
      dynamicTyping: false, // don't convert strings to numbers
      complete: function (results: Papa.ParseResult<string[]>) {

        console.log('results', results)

        if (results.errors.length > 0) {
          let msg = ''
          results.errors.forEach((err) => {
            msg += err + '\n'
          })
          setErr(msg)
          return
        }


        const tours = []

        const cols = new Map()
        results.data[1].forEach((val, index) => {
          if (index < 8)
            cols.set(val.toLowerCase(), index)
        })

        const jan1 = new Date(2023, 0, 1)
        const dates = []
        for (let i = 0; i < 365; i++) {
          const day = addDays(jan1, i)
          dates.push(day)
        }
        const COL_JAN1 = 8


        for (const [index, row] of results.data.slice(2).entries()) {
          //results.data.forEach((_row, index) => {

          // console.log(row)

          const tourName = row[cols.get('tour name')]
          if (!tourName)
            continue

          let custName = ''
          const match = tourName.match(/^FIT( |: | - |- )([a-z &()0-9-]+)$/i)
          if (match) {
            custName = match[2]
          } else {
            console.log(`Failed regex: ${tourName}`)
          }

          const code = row[cols.get('code')].trim()
          const agency = row[cols.get('agency')]
          const numPax = row[cols.get('pax')]
          const designer = row[cols.get('designer')]
          const colorNum = row[cols.get('color')]
          const color = colors.get(colorNum as unknown as number) ?? colorNum
          const wifi = row[cols.get('wifi')]

          const tour: TourType = {
            tourName,
            custName,
            code,
            agency,
            numPax,
            designer,
            color,
            wifi,
          }

          tours.push(tour)

        } // each row

        console.log(`Parsing complete. ${tours.length} tours`)

        console.log(tours)

        function getNumFromCode(code: string) {
          //const match = code.match(/^[A-Z](22|23)(0[0-9]|10|11|12)00([0-9]{3})$/)
          if (!code)
            return 'MISSING CODE'
          if (code.length !== 10)
            return 'LENGTH not 10'
          if (!code.substring(0, 1).match(/^[A-Z]$/))
            return 'FIRST CHAR not letter'
          const year = code.substring(1, 3)
          if (year !== '22' && year !== '23')
            return 'INVALID YEAR'
          const month = code.substring(3, 5)
          if (!month.match(/^(0[1-9]|10|11|12)$/))
            return 'INVALID MONTH'
          if (code.substring(5, 7) !== '00')
            return 'INVALID 00'
          const num = Number(code.substring(7))
          if (isNaN(num))
            return 'LAST THREE NOT A NUM'

          return num
        }

        for (const tour of tours) {
          tour.requestNum = getNumFromCode(tour.code)
        }

        const nums = tours.map((t) => t.requestNum)
        nums.sort()
        console.log(nums)

        function compareRequestNum(a: TourType, b: TourType) {
          if ((a.requestNum ?? 0) < (b.requestNum ?? 0))
            return -1
          if ((a.requestNum ?? 0) > (b.requestNum ?? 0))
            return 1
          return 0
        }

        const toursWithCode = tours.filter((t) => t.code)

        toursWithCode.sort(compareRequestNum)
        toursWithCode.reverse()

        setTours(toursWithCode)

        if (!saveToDb)
          return

        // store to database
        // tourrequests.forEach(req => {
        //   console.log(`Adding doc ${req.requestNumber}`)
        //   addDoc(collection(db, 'tourrequests'), req)
        //     .then(addedDoc => {
        //       console.log('tour request added to db')
        //       setSuccess(suc => [...suc, addedDoc])
        //     })
        //     .catch(err => {
        //       console.log(err)
        //       setErr(`addDoc error ${err}`)
        //     })
        // })



      }, // `complete` handler
    });

  }


  // below function used only when uploading the calendar csv through the form File input
  function processFile(e: any) {
    const saveToDb = e.target.saveToDb.checked
    console.log({ saveToDb })

    console.log(e.target.fileInput.files)
    if (!(e.target.fileInput.files && e.target.fileInput.files.length > 0))
      return

    const file = e.target.fileInput.files[0]

    const reader = new FileReader();
    reader.onerror = (e) => setErr('error in FileReader')
    reader.onload = function (e) {
      const contents = e.target!.result as string
      console.log(`length ${contents.length}`)

      parseCalendarText(contents, saveToDb)


    }
    reader.readAsText(file, 'UTF-8')

    //e.target.form.reset()

  }


  return (
    <div className='container'>
      <Helmet><title>Import Tour Calendar</title></Helmet>
      <h2 className='my-4'>Import Tour Calendar</h2>

      <div className='alert'>{err}</div>

      <ButtonTW onClick={(e) => {

        const ABORT = true
        if (ABORT)
          return

        const list = [
          [549, 'F230400549'],
          // ...
          [263, 'F221100263'],
        ]

        for (const item of list) {
          const [number, code] = item

          getDocs(query(collection(db, 'tourrequests'), where('requestNumber', '==', number)))
            .then((snapshot) => {
              if (snapshot.docs.length !== 1)
                throw new Error(`doc.length=${snapshot.docs.length} for num=${number}`)
              const doc = snapshot.docs[0]
              if (doc.data().code)
                throw new Error(`Code already present on ${number}`)

              updateDoc(doc.ref, { requestCode: code })
                .then(() => {
                  console.log(`UPDATED ${number}`)
                })
              console.log(doc)
            })


        }

      }}>Store request codes</ButtonTW>

      <div className='border border-primary p-3 m-3'>
        <h5>Download calendar csv from server and parse</h5>

        <ButtonTW onClick={(e) => {

          parseCalendarText(calendarText)

        }}>Parse downloaded csv</ButtonTW>
      </div>

      <div className='border border-primary p-3 m-3'>
        <h5>Upload calendar csv using form</h5>

        <form onSubmit={(e) => {
          e.preventDefault()
          processFile(e)
        }}>
          <CheckboxSwitch id='saveToDb' label='Save to DB' />
          <input type='file' id='fileInput' />
          <ButtonTW type='submit'>Parse</ButtonTW>
        </form>
      </div>

      {
        tours && (
          <table className='table'>
            <thead>
              <tr>
                <th>num</th>
                <th>tourName</th>
                <th>custName</th>
                <th>code</th>
                <th>agency</th>
                <th>numPax</th>
                <th>designer</th>
                <th>color</th>
                <th>wifi</th>
                <th>Name in Request</th>
              </tr>
            </thead>
            <tbody>
              {tours.map((tour, index) => {
                if (!tour.code)
                  return

                const num = Number(tour.code.slice(-3))
                const req = requestList.find((r) => r.requestNumber === num)


                return (
                  <tr key={index}>
                    <td>{tour.requestNum}</td>
                    <td>{tour.tourName}</td>
                    <td>{tour.custName}</td>
                    <td>{tour.code}</td>
                    <td>{tour.agency}</td>
                    <td>{tour.numPax}</td>
                    <td>{tour.designer}</td>
                    <td>{tour.color}</td>
                    <td>{tour.wifi}</td>
                    <td>{req ? req.travellerName : 'NOT FOUND'}</td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        )
      }



    </div>
  )
}
