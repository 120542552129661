import { collection, getDocs, query } from 'firebase/firestore'
import { useEffect, useState } from 'react'
import { useAppContext } from 'src/hooks/useAppContext'
import { UserDetailsBasicType, UserDetailsType } from 'src/types/types_user'


export const mapUsers = new Map([
  ['Jenny _ Jenny', 'jennifer.urano@80days.co.jp'],
  ['mirah.tahina@80days.co.jp _ Mirah', 'mirah.tahina@80days.co.jp'],
  ['Jay _ Mohamed Janaan', 'mohamed.janaan@80days.co.jp'],
  ['Carlotta Villa _ Carlotta Villa', 'carlotta.villa@80days.co.jp'],
  ['marin.charvet@80days.co.jp _ Marin', 'marin.charvet@80days.co.jp'],
  ['Malissa _ Malissa Esquivias', 'malissa.esquivias@80days.co.jp'],
  ['jared.lash@80days.co.jp _ Jared', 'jared.lash@80days.co.jp'],
  ['Giovanna _ Giovanna', 'giovanna.aversa@80days.co.jp'],
  ['nanami.granger@80days.co.jp _ グランジェ　七海', 'nanami.granger@80days.co.jp'],
  ['kenneth.hartono@80days.co.jp _ Ken', 'kenneth.hartono@80days.co.jp'],
  ['Lea _ Lea Baldelli', 'lea.baldelli@80days.co.jp'],
  ['maya.vesely@80days.co.jp:[230414055228] _ Maya', 'maya.vesely@80days.co.jp'],
])

export function useUserListKintone() {

  const { db, setDbError } = useAppContext()

  const [userList, setUserList] = useState<Map<string, UserDetailsBasicType>>() // dictionary indexed by email
  useEffect(() => {
    getDocs(query(collection(db, 'users')))
      .then((snapshot) => {
        const userList = new Map<string, UserDetailsBasicType>()
        snapshot.forEach((docUser) => {
          const userObj = { ...docUser.data(), id: docUser.id } as UserDetailsType
          userList.set(userObj.email, userObj)
        })

        userList.set('maya.vesely@80days.co.jp', { id: '', email: 'maya.vesely@80days.co.jp', displayNameEn: 'Maya' })

        setUserList(userList)

        // for (const docUser of snapshot) {
        //   const userObj = { ...docUser.data(), id: docUser.id }
        //   console.log({ userObj })
        //   userList.set(userObj.email, userObj)
        // }
      })
      .catch((err) => setDbError('Getting users list', err))
  }, [db, setDbError])

  return userList
}
