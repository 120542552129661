import { DocumentSnapshot, FirestoreError, QuerySnapshot, collection, doc, onSnapshot, query, serverTimestamp, updateDoc, where } from 'firebase/firestore';
import { useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Link, useNavigate } from 'react-router-dom';
import { ButtonTW } from 'src/components/Buttons/ButtonTW';
import { CheckboxSwitch } from 'src/components/Buttons/CheckboxSwitch';
import { SortCaret } from 'src/components/ColumnSorter/SortCaret';
import { useColumnSorter } from 'src/components/ColumnSorter/useColumnSorter';
import { getLoadingSpinnerOrNull } from 'src/components/Spinner/util_getLoadingSpinnerOrNull';
import { useAppContext } from 'src/hooks/useAppContext';
import { InvoiceType } from 'src/types/types_invoices';
import { PayeeCategoryType, PayeeListItemType } from 'src/types/types_payee';
import { dateFormatUserFriendly } from 'src/util/datelayouttools';
import { userrole_isAdmin } from 'src/util/user_roles';
import { convertInvoiceDates, convertPayeeDates } from 'src/util/util_firestoredates';
import { log_db_read, log_db_write } from 'src/util/util_log';
import { getShortDisplayNameEn, getShortDisplayNameJa, stripCommas } from '../util_payees';
import './listpayees.css';
import { getRuby } from './util_ruby';



export function ListPayees() {

  const { db, setDbError, userDetails, _lang } = useAppContext()

  const navigate = useNavigate()

  const [showKanaColumn, setShowKanaColumn] = useState<boolean>(false)
  const [showRubyId, setShowRubyId] = useState<string | null>(null)
  const [showDateCreatedModified, setShowDateCreatedModified] = useState<boolean>(false)


  const [sortCol, sortDir, setSortSetting, sortFunc] = useColumnSorter(['nameEnMain', 1]) // [colName, (-1|1)] where (1)=asc (-1)=desc



  const [payeeList, setPayeeList] = useState<PayeeListItemType[]>()
  useEffect(() => {

    log_db_read({ db, userDetails, logkey: 'db_read.list_payees', desc: 'List payees' })

    const processSnapshot = function (snapshot: QuerySnapshot) {
      const payees: PayeeListItemType[] = []
      const categoryCounts = {}
      for (const doc of snapshot.docs) {
        const payee = { ...doc.data(), id: doc.id } as PayeeListItemType
        convertPayeeDates(payee)
        // for sorting by category:
        payee.categories_0 = payee.categories && payee.categories[0] ? payee.categories[0] : ''
        // brand added recently:
        if (payee.nameBrandJa === undefined) payee.nameBrandJa = ''
        if (payee.nameBrandKana === undefined) payee.nameBrandKana = ''
        if (payee.nameBrandEn === undefined) payee.nameBrandEn = ''
        payees.push(payee)
      }
      setPayeeList(payees)
    }

    const q = query(collection(db, 'payees'), where('_isDeleted', '==', false))
    const unsubscribe = onSnapshot(q, processSnapshot, (err) => setDbError('Getting payees', err));

    return unsubscribe
  }, [db, setDbError, userDetails])





  const [invoiceList, setInvoiceList] = useState<InvoiceType[]>()
  useEffect(() => {
    const processSnapshot = function (snapshot: QuerySnapshot) {
      console.log(`Downloaded ${snapshot.size} invoices`)
      const invoices: InvoiceType[] = []
      for (const doc of snapshot.docs) {
        const invoice = { ...doc.data(), id: doc.id } as InvoiceType
        convertInvoiceDates(invoice)
        invoices.push(invoice)
      }
      setInvoiceList(invoices)
    }

    const q = query(collection(db, 'invoices'), where('_isDeleted', '==', false))
    const unsubscribe = onSnapshot(q, processSnapshot, (err) => setDbError('Getting invoices', err));

    return unsubscribe
  }, [db, setDbError])



  const [payeeCategories, setPayeeCategories] = useState<PayeeCategoryType[]>()
  useEffect(() => {
    const processSnapshot = function (snapshot: DocumentSnapshot) {
      const categories = snapshot.data()!.categories
      setPayeeCategories(categories)
    }

    const q = doc(db, 'settings', 'payeecategories')
    const unsubscribe = onSnapshot(q, processSnapshot, (err: FirestoreError) => setDbError('Getting payee categories in ListPayees', err));

    return unsubscribe
  }, [db, setDbError])


  const [selectedCategory, setSelectedCategory] = useState('ALL')


  const payeeListFilteredDict = useMemo(() => {
    if (!payeeCategories || !payeeList)
      return {}

    const dict: Record<string, PayeeListItemType[]> = {}
    dict['ALL'] = payeeList
    payeeCategories.forEach((cat: PayeeCategoryType) => {
      dict[cat.name] = payeeList.filter((payee) => {
        for (const subcat of cat.subcategories) {
          if (payee.categories.includes(subcat))
            return true
        }
      })
      cat.subcategories.forEach((subcat) => {
        dict[subcat] = payeeList.filter((payee) => payee.categories.includes(subcat))
      })
    })

    dict['NO_CAT'] = payeeList.filter((payee) => payee.categories.length === 0)
    dict['OVERSEAS_PAYEE'] = payeeList.filter((payee) => payee.isOverseasPayee)

    return dict

  }, [payeeCategories, payeeList])


  // *** all hooks above this line

  const loadingSpinner = getLoadingSpinnerOrNull([
    ['supplier list', payeeList],
    ['invoice list', invoiceList],
    ['supplier categories', payeeCategories],
  ])
  if (!payeeList || !invoiceList || !payeeCategories)
    return loadingSpinner


  // store num invoices on payee object to allow sorting
  // inefficient: this for loop is being run on every render. in the future, store invoiceMap on payee object in db
  for (const payee of payeeList) {
    payee.numInvoices = invoiceList.filter((invoice) => invoice.payeeId === payee.id).length
  }


  const payeeListDisplayed = payeeListFilteredDict[selectedCategory]

  // sort payees
  payeeListDisplayed.sort(sortFunc)



  // detect incorrect payee names
  const incorrectPayeeRows: JSX.Element[] = []
  for (const invoice of invoiceList) {
    if (invoice.payeeId) {
      const payee = payeeList.find((p) => p.id === invoice.payeeId)
      if (!payee) {
        incorrectPayeeRows.push(
          <tr key={`${invoice.id}_payeeNotFound`}>
            <td>{invoice.payeeNameEn}</td>
            <td>Supplier not found: {invoice.payeeId}</td>
            <td></td>
          </tr>
        )
        continue
      }
      const correctNameJa = getShortDisplayNameJa(payee)
      if (correctNameJa !== invoice.payeeNameJa) {
        incorrectPayeeRows.push(
          <tr key={`${invoice.id}_jaIncorrect`}>
            <td>JA</td>
            <td>{invoice.payeeNameJa}</td>
            <td>{correctNameJa}</td>
            <td></td>
          </tr>
        )
      }
      const correctNameEn = getShortDisplayNameEn(payee)
      if (correctNameEn !== invoice.payeeNameEn) {
        incorrectPayeeRows.push(
          <tr key={`${invoice.id}_enIncorrect`}>
            <td>EN</td>
            <td>{invoice.payeeNameEn}</td>
            <td>{correctNameEn}</td>
            <td></td>
          </tr>
        )
      }
    }
  }


  //const showRuby = showKanaColumn
  const showRubyPrefix = false


  return (
    <div className='container-fluid'>
      <Helmet><title>Supplier List</title></Helmet>
      <h2 className='my-4'>{_lang('Supplier List', '支払先リスト')}</h2>

      <div style={{
        display: 'grid',
        gap: '0 1rem',
        gridTemplateColumns: '14rem 1fr',
      }}>
        <div className='categoryMenu'>
          <div className={`boldLink ${selectedCategory === 'ALL' ? 'selected' : ''}`} onClick={(e) => setSelectedCategory('ALL')}>{_lang('Show all', '全てを表示')} <span className='catCount'>({payeeListFilteredDict['ALL'].length})</span></div>
          <ul>
            {payeeCategories.map((cat) => {
              return (
                <li key={cat.name}>
                  <div className={selectedCategory === cat.name ? 'selected' : ''} onClick={(e) => setSelectedCategory(cat.name)}>{cat.name} <span className='catCount'>({payeeListFilteredDict[cat.name].length})</span></div>
                  <ul>
                    {cat.subcategories.map((subcat) => {
                      return (
                        <li key={subcat} className={selectedCategory === subcat ? 'selected' : ''} onClick={(e) => setSelectedCategory(subcat)}>
                          <div><i className='bi bi-chevron-right'></i>{subcat} <span className='catCount'>({payeeListFilteredDict[subcat].length})</span></div>
                        </li>
                      )
                    })}
                  </ul>
                </li>
              )
            })}
          </ul>
          <div className={`boldLink mb-2 ${selectedCategory === 'NO_CAT' ? 'selected' : ''}`} onClick={(e) => setSelectedCategory('NO_CAT')}>
            {_lang('No category', 'カテゴリーなし')} <span className='catCount'>({payeeListFilteredDict['NO_CAT'].length})</span>
          </div>
          <div className={`boldLink mb-2 ${selectedCategory === 'OVERSEAS_PAYEE' ? 'selected' : ''}`} onClick={(e) => setSelectedCategory('OVERSEAS_PAYEE')}>
            <i className='bi bi-globe-americas'></i> {_lang('Overseas', '海外支払先')} <span className='catCount'>({payeeListFilteredDict['OVERSEAS_PAYEE'].length})</span>
          </div>

        </div>

        <div className='topPart'>
          <div className='d-flex mb-2'>
            <ButtonTW to='add'>{_lang('Add new supplier', '支払先を新規作成')}</ButtonTW>

            <CheckboxSwitch id='flexSwitchShowKana' label={_lang('Show kana', '読み仮名を表示')} className='mt-2 ms-4' checked={showKanaColumn} onChange={(e) => {
              setShowKanaColumn(e.target.checked)
            }} />

            <CheckboxSwitch id='flexSwitchShowDateCreatedModified' label={_lang('Show date added/modified', '作成日・修正日を表示')} className='mt-2 ms-4' checked={showDateCreatedModified} onChange={(e) => {
              setShowDateCreatedModified(e.target.checked)
            }} />
          </div>

          <div className='d-flex mb-2'>
            {_lang(
              `${payeeListDisplayed.length} ${payeeListDisplayed.length === 1 ? 'supplier' : 'suppliers'} displayed. ${payeeList.length} suppliers in total.`,
              `${payeeList.length}件中、${payeeListDisplayed.length}件が表示中。`
            )}
          </div>
        </div>

        <div className='mainPart'>
          <table className='table'>
            <thead>
              <tr>
                {/* <th>id</th> */}
                <th><i className='bi bi-globe-americas'></i></th>
                <th><SortCaret colName='nameKanaMain' sortCol={sortCol} sortDir={sortDir} setSortSetting={setSortSetting} /> {_lang('Name (Japanese)', '名前 (日本語)')}</th>
                {showKanaColumn && (
                  <th><SortCaret colName='nameKanaMain' sortCol={sortCol} sortDir={sortDir} setSortSetting={setSortSetting} /> {_lang('Name (Kana)', '名前 (カナ)')}</th>
                )}
                <th><SortCaret colName='nameEnMain' sortCol={sortCol} sortDir={sortDir} setSortSetting={setSortSetting} /> {_lang('Name (English)', '名前 (英語)')}</th>
                <th><SortCaret colName='categories_0' sortCol={sortCol} sortDir={sortDir} setSortSetting={setSortSetting} /> {_lang('Categories', 'カテゴリー')}</th>
                <th><SortCaret colName='numInvoices' sortCol={sortCol} sortDir={sortDir} setSortSetting={setSortSetting} /> {_lang('Num. invoices', '請求書の件数')}</th>
                {showDateCreatedModified && (
                  <>
                    <th><SortCaret colName='dateCreated' sortCol={sortCol} sortDir={sortDir} setSortSetting={setSortSetting} /> {_lang('Created', '作成日')}</th>
                    <th><SortCaret colName='dateModified' sortCol={sortCol} sortDir={sortDir} setSortSetting={setSortSetting} /> {_lang('Modified', '修正日')}</th>
                  </>
                )}
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {payeeListDisplayed.map((payee) => {

                const [rubyHtml, rubyError] = getRuby(payee.nameJaMain, payee.nameKanaMain)
                const [rubyHtmlBrand, rubyErrorBrand] = getRuby(payee.nameBrandJa, payee.nameBrandKana)
                const [rubyHtmlPerson, rubyErrorPerson] = getRuby(payee.namePersonJa, payee.namePersonKana)
                const showRuby = showRubyId === payee.id

                return (
                  <tr key={payee.id}>
                    {/* <td>{payee.id}</td> */}
                    <td style={{ verticalAlign: 'middle' }}>{payee.isOverseasPayee && (
                      <i className='bi bi-globe-americas'></i>
                    )}</td>
                    <td onMouseOver={(e) => setShowRubyId(payee.id)} onMouseOut={(e) => setShowRubyId(null)}>
                      {rubyError && userrole_isAdmin(userDetails.roles) && (
                        <div style={{ backgroundColor: '#fed' }}>{rubyError}</div>
                      )}
                      <div>
                        <span className='prefix'>{showRubyPrefix ? <ruby>{payee.nameJaPrefix}<rp>(</rp><rt>{payee.nameKanaPrefix}</rt><rp>)</rp></ruby> : payee.nameJaPrefix}</span>
                        <span className='main' style={{ lineHeight: '2.1em' }}>{showRuby ? rubyHtml : stripCommas(payee.nameJaMain)}</span>
                        <span className='suffix'>{showRubyPrefix ? <ruby>{payee.nameJaSuffix}<rp>(</rp><rt>{payee.nameKanaSuffix}</rt><rp>)</rp></ruby> : payee.nameJaSuffix}</span>
                      </div>
                      {payee.nameBrandJa && (
                        <>
                          {rubyErrorBrand && userrole_isAdmin(userDetails.roles) && (
                            <div style={{ backgroundColor: '#fed' }}>{rubyErrorBrand}</div>
                          )}
                          <div className='brand' style={{ lineHeight: '2.1em' }}>{showRuby ? rubyHtmlBrand : stripCommas(payee.nameBrandJa)}</div>
                        </>
                      )}
                      {payee.namePersonJa && (
                        <>
                          {rubyErrorPerson && userrole_isAdmin(userDetails.roles) && (
                            <div style={{ backgroundColor: '#fed' }}>{rubyErrorPerson}</div>
                          )}
                          <div className='person' style={{ lineHeight: '2.1em' }}>{showRuby ? rubyHtmlPerson : stripCommas(payee.namePersonJa)}</div>
                        </>
                      )}
                    </td>
                    {showKanaColumn && (
                      <td className='col-kana'>
                        <div>
                          <span className='prefix'>{payee.nameKanaPrefix}</span>{' '}
                          <span className='main'>{stripCommas(payee.nameKanaMain)}</span>{' '}
                          <span className='suffix'>{payee.nameKanaSuffix}</span>
                        </div>
                        {payee.namePersonKana && (
                          <>
                            <div className='person'>{payee.namePersonKana}</div>
                          </>
                        )}
                      </td>
                    )}
                    <td className='col-en'>
                      <div>
                        <span className='main' style={{ lineHeight: '2.1em' }}>{payee.nameEnMain}</span>
                      </div>
                      {payee.nameBrandEn && (
                        <div className='brand' style={{ lineHeight: '2.1em' }}>{payee.nameBrandEn}</div>
                      )}
                      {payee.namePersonEn && (
                        <div className='person' style={{ lineHeight: '2.1em' }}>{payee.namePersonEn}</div>
                      )}
                    </td>
                    <td>
                      <ul>{payee.categories.map((cat) => <li key={cat}>{cat.startsWith('_') ? cat.substring(1) : cat}</li>)}</ul>
                    </td>
                    <td>
                      <div className='tw-flex tw-gap-2 tw-justify-between'>
                        <div>{payee.numInvoices}</div>
                        <div><Link to={`/invoices/?payeeId=${payee.id}`} target='_blank'>{_lang('View list', 'リストを表示')}</Link></div>
                      </div>
                    </td>
                    {showDateCreatedModified && (
                      <>
                        <td className='colDate'>
                          <div>{dateFormatUserFriendly(payee.dateCreated, true)}</div>
                          <div>{payee.userCreatorName}</div>
                        </td>
                        <td className='colDate'>
                          <div>{dateFormatUserFriendly(payee.dateModified, true)}</div>
                          <div>{payee.userModifiedName}</div>
                        </td>
                      </>
                    )}
                    <td className='colActions'>
                      <div>
                        <ButtonTW to={`edit/${payee.id}`} variant='blue_outline'>{_lang('Edit', '編集')}</ButtonTW>
                        {' '}
                        <ButtonTW variant={payee.numInvoices === 0 ? 'blue_outline' : 'darkgray_outline'} onClick={(e) => {
                          if (payee.numInvoices > 0) {
                            alert(`Cannot delete supplier because it is currently assigned to ${payee.numInvoices} ${payee.numInvoices === 1 ? 'invoice' : 'invoices'}.`)
                            return
                          }
                          if (window.confirm(`Are you sure you want to delete supplier\n${stripCommas(payee.nameJaMain)}`)) {
                            updateDoc(doc(db, 'payees', payee.id), {
                              _isDeleted: true,
                              dateModified: serverTimestamp(),
                              userModifiedUid: userDetails.id,
                              userModifiedEmail: userDetails.email,
                              userModifiedName: userDetails.displayNameEn,
                            })
                              .then(() => {
                                log_db_write({ db, userDetails, logkey: 'db_write.payee.delete', desc: `Deleted payee [${payee.nameJaMain}] [${payee.nameEnMain}] [${payee.id}]` })
                              })
                              .catch((err) => setDbError(`Delete payee ${payee.id}`, err))
                          }
                        }}>{_lang('Delete', '削除')}</ButtonTW>
                      </div>
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </table>



          {userrole_isAdmin(userDetails.roles) && (
            <>
              <h5>Incorrect invoice suppliers</h5>

              <table className='table'>
                <thead>
                  <tr>
                    <th>Lang</th>
                    <th>Incorrect name</th>
                    <th>Correct name</th>
                    <th>Fix</th>
                  </tr>
                </thead>
                <tbody>
                  {incorrectPayeeRows}
                </tbody>
              </table>
            </>
          )}
        </div>
      </div>
    </div>
  )
}
