import { doc, serverTimestamp, updateDoc } from 'firebase/firestore';
import { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';
import { ButtonTW } from 'src/components/Buttons/ButtonTW';
import { CheckboxSwitch } from 'src/components/Buttons/CheckboxSwitch';
import { SortCaret } from 'src/components/ColumnSorter/SortCaret';
import { useColumnSorter } from 'src/components/ColumnSorter/useColumnSorter';
import { EditableField } from 'src/components/EditableField/EditableField';
import { getLoadingSpinnerOrNull } from 'src/components/Spinner/util_getLoadingSpinnerOrNull';
import { useAppContext } from 'src/hooks/useAppContext';
import { dateFormatUserFriendly } from 'src/util/datelayouttools';
import { userrole_isAdmin } from 'src/util/user_roles';
import { log_db_write } from 'src/util/util_log';
import { Unauthorized } from '../Unauthorized';
import './pageagencies.css';
import { useAgencyList } from './useAgencyList';
import { deleteAgency, validateTwoCharacterCode } from './util_agencies';


export function PageAgencyList() {

  const { db, setDbError, userDetails } = useAppContext()

  const [sortCol, sortDir, setSortSetting, sortFunc] = useColumnSorter(
    ['name', 1], // [colName, (-1|1)] where (1)=asc (-1)=desc
    { requestListLength: (obj) => obj.requestMap ? Object.keys(obj.requestMap).length : 0 },
  )

  const [isEditing, setIsEditing] = useState(false)
  const [editedCell, setEditedCell] = useState<string | null>(null)

  const agencyList = useAgencyList()

  // *** all hooks above ***

  if (!userrole_isAdmin(userDetails.roles))
    return <Unauthorized />

  const loadingSpinner = getLoadingSpinnerOrNull([
    ['agency list', agencyList],
  ])
  if (!agencyList)
    return loadingSpinner


  agencyList.sort(sortFunc)


  function updateDb(agencyId: string, field: string, dbvalue: any) {
    updateDoc(doc(db, 'agencies', agencyId), {
      [field]: dbvalue,
      dateModified: serverTimestamp(),
      userModifiedUid: userDetails.id,
      userModifiedEmail: userDetails.email,
      userModifiedName: userDetails.displayNameEn,
    })
      .then(() => {
        setEditedCell('')
        log_db_write({ db, userDetails, logkey: 'db_write.agency.update', desc: `Updated agency ${field}=${dbvalue} [${agencyId}]` })
      })
      .catch((err) => setDbError(`Editing agency ${agencyId}`, err))
  }


  return (
    <div className='container'>
      <Helmet><title>Clients</title></Helmet>

      <h2 className='my-4'>Agencies/Platforms/Clients</h2>

      <CheckboxSwitch id='editing-mode' label='Enable editing' checked={isEditing} onChange={(e) => {
        setIsEditing(e.target.checked)
      }} />

      <div className='my-3'>Number of agencies in list: {agencyList.length}</div>

      <table className={`table ${isEditing ? 'editing' : ''}`}>
        <thead>
          <tr>
            <th><SortCaret colName='name' sortCol={sortCol} sortDir={sortDir} setSortSetting={setSortSetting} /> Name</th>
            <th><SortCaret colName='officialName' sortCol={sortCol} sortDir={sortDir} setSortSetting={setSortSetting} /> Official name</th>
            <th><SortCaret colName='country' sortCol={sortCol} sortDir={sortDir} setSortSetting={setSortSetting} /> Country</th>
            <th><SortCaret colName='twoCharacterCode' sortCol={sortCol} sortDir={sortDir} setSortSetting={setSortSetting} /> Two character code</th>
            <th><SortCaret colName='requestListLength' sortCol={sortCol} sortDir={sortDir} setSortSetting={setSortSetting} /> Requests</th>
            <th><SortCaret colName='dateCreated' sortCol={sortCol} sortDir={sortDir} setSortSetting={setSortSetting} /> Created</th>
            <th><SortCaret colName='dateModified' sortCol={sortCol} sortDir={sortDir} setSortSetting={setSortSetting} /> Modified</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {agencyList.map((agency) => {

            return (
              <tr key={agency.id}>
                <td title={agency.id}>
                  <EditableField
                    tableid='agenciestable'
                    rowid={agency.id}
                    fieldname='name'
                    validationType=''
                    currentValue={agency.name}
                    isClickableToEdit={isEditing}
                    editedCell={editedCell}
                    setEditedCell={setEditedCell}
                    callbackCommitChange={(dbvalue) => {
                      updateDb(agency.id, 'name', dbvalue)
                    }}
                  />
                </td>
                <td>
                  <EditableField
                    tableid='agenciestable'
                    rowid={agency.id}
                    fieldname='officialName'
                    validationType=''
                    currentValue={agency.officialName}
                    isClickableToEdit={isEditing}
                    editedCell={editedCell}
                    setEditedCell={setEditedCell}
                    callbackCommitChange={(dbvalue) => {
                      updateDb(agency.id, 'officialName', dbvalue)
                    }}
                  />
                </td>
                <td>
                  <EditableField
                    tableid='agenciestable'
                    rowid={agency.id}
                    fieldname='country'
                    validationType=''
                    currentValue={agency.country}
                    isClickableToEdit={isEditing}
                    editedCell={editedCell}
                    setEditedCell={setEditedCell}
                    callbackCommitChange={(dbvalue) => {
                      updateDb(agency.id, 'country', dbvalue)
                    }}
                  />
                </td>
                <td>
                  <EditableField
                    tableid='agenciestable'
                    rowid={agency.id}
                    fieldname='twoCharacterCode'
                    validationType=''
                    currentValue={agency.twoCharacterCode || ''}
                    isClickableToEdit={isEditing}
                    editedCell={editedCell}
                    setEditedCell={setEditedCell}
                    callbackCommitChange={(dbvalue) => {
                      if (dbvalue !== '') {
                        const { str, err } = validateTwoCharacterCode(dbvalue)
                        if (err) {
                          // failed validation
                          window.alert(err)
                          return
                        }
                        dbvalue = str

                        const dupes = agencyList.filter((agency) => agency.twoCharacterCode === dbvalue)
                        if (dupes.length > 0) {
                          alert(`Already used on\n${dupes.map((a) => a.name).join('\n')}`)
                          return
                        }
                      }
                      updateDb(agency.id, 'twoCharacterCode', dbvalue)
                    }}
                  />
                </td>
                <td>
                  <div className='tw-flex tw-gap-2 tw-justify-between'>
                    <div>
                      {agency.requestMap ? Object.keys(agency.requestMap).length : 0}
                    </div>
                    <div>
                      <Link to={`/requests/list/?agencyId=${agency.id}`}>View list</Link>
                    </div>
                  </div>
                </td>
                <td className='colDate'>
                  <div>{dateFormatUserFriendly(agency.dateCreated, true)}</div>
                  <div>{agency.userCreatedName}</div>
                </td>
                <td className='colDate'>
                  <div>{dateFormatUserFriendly(agency.dateModified, true)}</div>
                  <div>{agency.userModifiedName}</div>
                </td>
                <td>
                  <ButtonTW variant='blue_outline' onClick={(e) => {
                    deleteAgency(agency, userDetails, db)
                      .then(() => setEditedCell(''))
                      .catch((err) => setDbError(`Deleting agency ${agency.id}`, err))
                  }}>Delete</ButtonTW>
                </td>
              </tr>
            )
          })}
        </tbody>
      </table>
    </div>
  )
}
