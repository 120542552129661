import React, { ReactNode } from 'react'
import { EditableFieldQuotation, FunctionSaveFieldToDbType, FunctionTabKeyPress } from 'src/components/EditableField/EditableFieldQuotation'
import { EnumLineItemType, QuotationLineItemType, QuotationLineItemTypeUnion, QuotationOverallType } from 'src/types/types_quotation'
import { dateutcFormatWithWeekday } from 'src/util/dateformattools'
import { addDaysIso, utc0_from_iso } from 'src/util/datetools'
import { formatNum } from 'src/util/util_formatnum'
import { NEW_ROW_ID } from '../util_calculateQuotationTotals'
import { UpdateQuotationType } from '../util_handlepaste'
import { FunctionCallbackOnPasteQuotation, QuotationLineItemTableColumn } from './LineItemTable'
import { TableRowActions } from './TableRowActions'



interface HotelSummaryTableProps {
  lineItemsThisTable: QuotationLineItemType[];
  lineItemsAll: QuotationLineItemTypeUnion[];
  updateQuotation: UpdateQuotationType;
  enableEditing: boolean;
  editedCell: string | null;
  setEditedCell: (cellid: string | null) => void;
  saveFieldToDb: FunctionSaveFieldToDbType; // save 1 value in 1 field, by later calling `updateQuotation`
  prepareSaveRowsToDb: (newItems: QuotationLineItemTypeUnion[]) => { updateObj: Record<string, any> }
  tabKeyPress: FunctionTabKeyPress;
  callbackOnPasteQuotation: FunctionCallbackOnPasteQuotation;
  renderImages: (text: string) => ReactNode;
  showDebugInfo: boolean;
  overall: QuotationOverallType;

  tablename: EnumLineItemType;
  columns: QuotationLineItemTableColumn[];
  bgcolor: string;
}

export function HotelSummaryTable({
  lineItemsThisTable,
  lineItemsAll,
  updateQuotation,
  enableEditing,
  editedCell,
  setEditedCell,
  saveFieldToDb,
  prepareSaveRowsToDb,
  tabKeyPress,
  callbackOnPasteQuotation,
  renderImages,
  showDebugInfo,
  overall,

  tablename,
  columns,
  bgcolor,
}: HotelSummaryTableProps) {


  if (enableEditing) {
    columns = columns.filter((col) => col.field !== 'date_checkin' && col.field !== 'date_checkout')
  }

  const listDateRowSpans: number[] = []

  {
    let curDayNum = -1
    let curDayNumIndex = -1
    for (const [index, item] of lineItemsThisTable.entries()) {
      if (item.dayNum !== curDayNum) {
        curDayNum = item.dayNum
        curDayNumIndex = index
        listDateRowSpans.push(1)
      } else {
        listDateRowSpans[curDayNumIndex]++
        listDateRowSpans.push(0)
      }
    }
  }

  const listNameRowSpans: number[] = []

  {
    let curName = '-----'
    let curDayNum = -1
    let curNameIndex = -1
    for (const [index, item] of lineItemsThisTable.entries()) {
      if (item.name !== curName
        || item.dayNum !== curDayNum // don't span accross multiple days
      ) {
        curName = item.name
        curDayNum = item.dayNum
        curNameIndex = index
        listNameRowSpans.push(1)
      } else {
        listNameRowSpans[curNameIndex]++
        listNameRowSpans.push(0)
      }
    }
  }

  const listCheckIn: string[] = []
  const listCheckOut: string[] = []

  {
    let curName = '-----'
    let curDayNum = -1
    let curNameIndex = -1
    let prevCheckoutIndex = -1
    for (const [index, item] of lineItemsThisTable.entries()) {
      if (item.name !== curName) {
        curName = item.name
        curNameIndex = index
        listCheckIn.push(item.date)
        listCheckOut.push(addDaysIso(item.date, 1))
        prevCheckoutIndex = index
      } else { // names are the same
        listCheckIn.push('')
        if (item.dayNum !== curDayNum) {
          listCheckOut[prevCheckoutIndex] = ''
          listCheckOut.push(addDaysIso(item.date, 1))
          prevCheckoutIndex = index
        } else {
          listCheckOut.push('')
        }
      }
      curDayNum = item.dayNum
    }
  }



  const lineItems_multiple: boolean = lineItemsThisTable.length > (enableEditing ? 2 : 1)
  const lineItems_total: number = lineItemsThisTable.reduce((acc, item) => acc + (item.totalPrice || 0), 0)

  if (!enableEditing && lineItemsThisTable.length === 0)
    return null

  // if (tablename === 'accommodation' && dayNum === 0)
  //   console.log('line items', lineItemsThisTable.map((item) => item.index))

  return (
    <div style={{ display: 'flex', alignItems: 'start' }}>
      <div className='divGridLineItemTable divGridTable tw-mb-4 summaryTable'
        style={{
          display: 'grid',
          width: enableEditing ? '98em' : '90em',
          gridTemplateColumns: `10em 4em 10em 1em 19em ${enableEditing ? '' : '10em 10em'} 10em 10em 10em 10em 10em 10em 8em`,
        }}
      >
        {/* <colgroup>
          <col style={{ width: '10em' }} />
          <col style={{ width: '4em' }} />
          <col style={{ width: '10em' }} />
          <col style={{ width: '1em' }} />
          {columns.map((col, index) => (
            <col key={index} style={{ width: `${col.widthEm}em` }} />
          ))}
          {enableEditing && (
            <col style={{ width: '8em' }} />
          )}
        </colgroup> */}

        <div className='leftArea' style={{
          gridRow: '1',
          gridColumn: '1',
        }}></div>
        <div className='cellHeader cellBorderLeft'>Day</div>
        <div className='cellHeader'>Date</div>
        <div className='cellHeader' style={{ borderRightStyle: 'none' }}></div>
        {columns.map((col, index) => {
          return (
            <div
              className='cellHeader'
              key={index}
              style={{
                borderLeftStyle: index === 0 ? 'none' : undefined,
              }}>
              {col.header}
            </div>
          )
        })}

        {/* <tbody> */}
        {lineItemsThisTable.map((item, indexRow) => {


          const rowInfo = {
            baseField: 'lineItems' as const,
            rowObj: item,
            tableid: `day_summary_${tablename}`,
            isClickableToEdit: enableEditing,
            editedCell,
            setEditedCell,
            tabKeyPress,
            saveFieldToDb,
          }

          const hasMemoRow = item.memorandum || enableEditing

          const gridRow = 2 + 2 * indexRow


          return (
            <React.Fragment key={item.id}>
              {/* <tr className='rowNonMemorandum'> */}
              <div className='leftArea' style={{
                gridRow: `${gridRow} / span 2`,
                gridColumn: '1',
              }}>
                <div style={{ borderColor: enableEditing ? 'silver' : 'transparent', height: enableEditing ? '3em' : undefined }}>
                  <EditableFieldQuotation
                    fieldname='leftAreaMemorandum'
                    placeholderText={'Memo'}
                    validationType=''
                    {...rowInfo}
                  />
                </div>
              </div>
              {(listDateRowSpans[indexRow] > 0) && (
                <>
                  <div className='cellData cellBorderLeft cellBorderBottom' style={{
                    gridRow: `${gridRow} / span ${2 * listDateRowSpans[indexRow]}`,
                  }}>
                    {item.dayNum + 1}
                  </div>
                  <div className='cellData cellBorderBottom' style={{
                    gridRow: `${gridRow} / span ${2 * listDateRowSpans[indexRow]}`,
                  }}>
                    {dateutcFormatWithWeekday(utc0_from_iso(item.date))}
                  </div>
                </>
              )}
              {listNameRowSpans[indexRow] > 0 && (
                <div className='cellBorderBottom' style={{
                  backgroundColor: bgcolor,
                  padding: 0,
                  gridRow: `${gridRow} / span ${2 * listDateRowSpans[indexRow]}`,
                }}>

                </div>
              )}
              {columns.map((col, indexCol) => {
                let rowSpan
                if (indexCol === columns.length - 1) {
                  rowSpan = 2
                } else if (indexCol <= (enableEditing ? 0 : 2)) {
                  if (!enableEditing) {
                    rowSpan = 2 * listNameRowSpans[indexRow]
                  } else {
                    rowSpan = 2
                  }
                } else {
                  rowSpan = hasMemoRow ? 1 : 2
                }

                if (rowSpan === 0) {
                  return null
                }

                if (!enableEditing && indexCol === 1) {
                  return (
                    <div
                      className='cellData cellBorderBottom'
                      key={indexCol}
                      style={{
                        gridRow: `${gridRow} / span ${rowSpan}`,
                      }}>
                      {listCheckIn[indexRow]}
                    </div>
                  )
                }

                if (!enableEditing && indexCol === 2) {
                  return (
                    <div
                      className='cellData cellBorderBottom'
                      key={indexCol}
                      style={{
                        gridRow: `${gridRow} / span ${rowSpan}`,
                      }}>
                      {listCheckOut[indexRow]}
                    </div>
                  )
                }

                return (
                  <div
                    key={indexCol}
                    className={`cellData ${col.isNumeric ? 'quotationCellNumeric' : ''} ${rowSpan % 2 === 0 ? 'cellBorderBottom' : ''}`}
                    style={{
                      gridRow: `${gridRow} / span ${rowSpan}`,
                    }}>
                    <EditableFieldQuotation
                      fieldname={col.field}
                      validationType={col.isNumeric ? 'formula' : ''}
                      {...rowInfo}
                    />
                  </div>
                )
              })}
              {enableEditing && (
                <div style={{
                  whiteSpace: 'nowrap',
                  gridRow: `${gridRow} / span 2`,
                }}>
                  <div style={{ visibility: item.id === NEW_ROW_ID ? 'hidden' : undefined }}>
                    <TableRowActions
                      lineItems={lineItemsThisTable}
                      itemId={item.id}
                      index={indexRow}
                      prepareSaveRowsToDb={prepareSaveRowsToDb}
                      updateQuotation={updateQuotation}
                      lineItemsAll={lineItemsAll}
                      overall={overall}
                      showDebugInfo={showDebugInfo}
                      blankRowKey={null}
                      setNumBlankRows={null}
                    />
                  </div>
                </div>
              )}
              {/* </tr> */}

              {/* <tr> */}
              {/* MEMO ROW */}
              {hasMemoRow && (
                <div className='cellMemorandum cellBorder'
                  style={{
                    gridColumn: `${enableEditing ? 6 : 8} / span 5`,
                  }}>
                  <div style={{ display: 'flex' }}>

                    {enableEditing && (
                      <div className='labelMemorandum'>
                        Memo
                      </div>
                    )}

                    <div style={{ flexGrow: 1, padding: '0.125rem 0.375rem' }}>
                      {enableEditing ? (
                        <EditableFieldQuotation
                          fieldname='memorandum'
                          isTextArea={true}
                          validationType=''
                          {...rowInfo}

                          callbackOnPaste={(e) => {
                            callbackOnPasteQuotation(e, 'lineItems', item, 'memorandum')
                          }}

                          getDisplayValue={renderImages}
                        />
                      ) : (
                        <div>
                          {renderImages(item.memorandum)}
                        </div>
                      )}
                    </div>

                  </div>
                </div>
              )}
              {/* </tr> */}
            </React.Fragment>
          )
        })}
        {/* <tr> */}
        {/* TABLE TOTAL ROW */}
        <div className='leftArea' style={{
          borderRight: 'none',
          gridColumn: '1',
        }}></div>
        <div
          style={{
            backgroundColor: 'transparent',
            borderLeft: 'none',
            borderBottom: 'none',
            gridColumn: `2 / span ${columns.length + 2}`,
          }}>
        </div>
        {(lineItems_multiple || enableEditing) && ( // always show total when editing (CEO request)
          <div className='quotationCellNumeric cellData cellBorderLeft cellBorder cellBorderBottom' style={{
            gridColumn: enableEditing ? 11 : 13,
          }}>{formatNum(lineItems_total)}</div>
        )}
        {/* </tr> */}
        {/* </tbody> */}
      </div>

    </div>
  )
}
