import { Dispatch, SetStateAction, useState } from 'react'
import { compare, stringCompare } from 'src/util/util_misc'

type GetterFunctionType = (obj: Record<string, any>) => string | number

function getSortFunc(sortCol: string, sortDir: 1 | -1, getter: GetterFunctionType | undefined) {
  return (objA: Record<string, any>, objB: Record<string, any>) => {
    // we use ?? '' so that undefined values are properly sorted
    let a: any = (getter ? getter(objA) : objA[sortCol]) ?? ''
    let b: any = (getter ? getter(objB) : objB[sortCol]) ?? ''
    if (!getter && !a && !b && sortCol.includes('.')) {
      // handle e.g. 'employee.name'
      const [field, subfield] = sortCol.split('.')
      a = objA[field]?.[subfield] ?? ''
      b = objB[field]?.[subfield] ?? ''
    }
    // comparing strings with '<' and '>' is case sensitive, which we don't want
    if (typeof a === 'string' && typeof b === 'string')
      return stringCompare(a, b) * sortDir
    else
      return compare(a, b) * sortDir
  }
}

export function useColumnSorter(defaultSort: [string, 1 | -1], getters?: Record<string, GetterFunctionType>):
  [
    string,
    -1 | 1,
    Dispatch<SetStateAction<[string, 1 | -1]>>,
    (objA: Record<string, any>, objB: Record<string, any>) => number,
  ] {

  const [sortSetting, setSortSetting] = useState(defaultSort) // [colName, (-1|1)] where (1)=asc (-1)=desc
  const [sortCol, sortDir] = sortSetting

  return [sortCol, sortDir, setSortSetting, getSortFunc(sortCol, sortDir, getters?.[sortCol])]
}
