import { PercentCrop } from 'react-image-crop';
import { setDbErrorType } from 'src/hooks/useDbError';
import { InvoiceContentsFileType, InvoicePartialAssignmentType } from 'src/types/types_invoices';
import { serverTimestampAsDate } from 'src/util/util_firestoredates';
import { arraySum } from 'src/util/util_misc';
import { nano_id } from 'src/util/util_nano_id';


export const PAYEE_ID_IWA = 'nXUgTX6yYtrCXpJ8zDqJ'

const bracketedDataToRemove = ['(払戻)', '(RF)', '(乗変)']

function removeBrackedData(str: string) {
  let result = str
  for (const bracketedData of bracketedDataToRemove) {
    result = result.replace(bracketedData, '')
    // sometimes the closing bracket is missing
    result = result.replace(bracketedData.slice(0, -1), '')
  }
  return result.trim()
}


function matchLine(line: string) {
  // example:          3      001-001  MILLER # F41B-RLSZ   KK   68,788   0   68,788
  return line.match(/^(\d+) +(\d{3}-\d{3}) (.+) +(KK) +(-?[\d,]+) +(0) +(-?[\d,]+)$/)
}

export function formatLine_iwa(line: string, index: number) {
  if (index === 0) {
    const match = line.match(/^(\d{2}\/\d{2}\/\d{2}) +(DDJ\d{3}) +(301) +(Eighty *Days) +([^ ]+)(様)(.*)$/)
    if (match)
      return (
        <span>
          <span className='invoice_line_parsed_green'>{match[1]}</span>{' '}
          <span className='invoice_line_parsed_gray'>{match[2]}</span>{' '}
          <span className='invoice_line_parsed_gray'>{match[3]}</span>{' '}
          <span className='invoice_line_parsed_gray'>{match[4]}</span>{' '}
          <span className='invoice_line_parsed_blue'>{match[5]}</span>
          <span className='invoice_line_parsed_gray'>{match[6]}</span>{' '}
          <span className='invoice_line_parsed_gray'>{match[7]}</span>{' '}
        </span>
      )
  }

  if (index === 1) {
    const match = matchLine(line)
    if (match)
      return (
        <span>
          <span className='invoice_line_parsed_green'>{match[1]}</span>{' '}
          <span className='invoice_line_parsed_gray'>{match[2]}</span>{' '}
          <span className='invoice_line_parsed_blue'>{match[3]}</span>{' '}
          <span className='invoice_line_parsed_gray'>{match[4]}</span>{' '}
          <span className='invoice_line_parsed_gray'>{match[5]}</span>{' '}
          <span className='invoice_line_parsed_gray'>{match[6]}</span>{' '}
          <span className='invoice_line_parsed_blue'>{match[7]}</span>{' '}
        </span>
      )
  }

  return line
}

export function auto_breakdown_iwa(
  invoiceId: string,
  selectedFile: string,
  selectedFileContents: InvoiceContentsFileType | undefined,
  partialAssignmentList: InvoicePartialAssignmentType[],
  setDbError: setDbErrorType,
  autosaveNewStep: (
    userAction: string,
    updateObj: any,
    sUndoWall: 'u' | 'UNDOWALL',
  ) => Promise<void>,
) {
  if (!selectedFileContents) {
    window.alert('No file contents')
    return
  }
  if (!selectedFileContents.pages || selectedFileContents.pages.length === 0) {
    window.alert('No pages')
    return
  }
  if (selectedFileContents.conversionTool !== 'xpdf') {
    window.alert(`Please contact Laurent for XPDF conversion. invoiceId=${invoiceId}`)
    return
  }
  if (partialAssignmentList.length > 0) {
    if (!window.confirm('This invoice already has some rows in the breakdown table. Running auto-breakdown may result in duplicate rows. Continue?'))
      return
  }

  // const allText = selectedFileContents.pages.map((page) => page.textContent).join('\n')
  // const allLines = allText.split('\n')

  // console.log(allLines)

  const result: { desc: string, price: number }[] = []

  const updateObj: Record<string, any> = {}

  const numLinesFullPage = 128 // number of lines in text extract (actual lines in pdf is about half, because text extract has a lot of added empty lines)

  const userSimpleAuto = {
    uid: '',
    name: 'Auto',
    email: '',
  }

  let curNum = 1
  let currentDateUserHeader = ''
  for (let iPage = 0; iPage < selectedFileContents.pages.length; iPage++) {
    const page = selectedFileContents.pages[iPage]
    if (!page.textContent)
      continue
    const pageLines = page.textContent
      .replaceAll('‑', '-') // 2011 NON-BREAKING HYPHEN
      .replaceAll('ｰ', '-') // FF70 HALFWIDTH KATAKANA-HIRAGANA PROLONGED SOUND MARK
      .split('\n')
    for (let iLine = 0; iLine < pageLines.length; iLine++) {
      const line = pageLines[iLine].trim()
      const match_dateuserheader = line.match(/^\d{2}\/\d{2}\/\d{2}/)
      if (match_dateuserheader) {
        currentDateUserHeader = line
        continue
      }
      if (line.startsWith(`${curNum} `)) {
        console.log('line found')
        const match = matchLine(line)
        if (!match) {
          console.log('line', line)
          window.alert(`Unexpected line: ${line}`)
          return
        }
        const entryNum = Number(match[1])
        if (entryNum !== curNum) {
          // unreachable?
          window.alert(`Unexpected entry number: ${entryNum} (expected ${curNum})`)
          return
        }
        const desc = match[3]
        const price1 = match[5]
        const price2 = match[7]
        if (price1 !== price2) {
          window.alert(`Price mismatch: ${price1} vs ${price2}`)
          return
        }

        const price = Number(price1.replaceAll(',', ''))
        if (isNaN(price)) {
          window.alert(`Invalid price: ${price1}`)
          return
        }

        const parts = desc.split('#', 2)

        let requestCode = ''
        if (parts.length === 2)
          requestCode = removeBrackedData(parts[1])

        const paxNameInvoice = removeBrackedData(parts[0])

        result.push({ desc, price })

        const crop: PercentCrop = {
          x: 6,
          width: 92,
          y: 6 + iLine * 91 / numLinesFullPage,
          height: 100 / numLinesFullPage * 2,
          unit: '%',
        }

        console.log('pageLines.length', pageLines.length)
        console.log('crop', crop)

        const newPartialAssignment: InvoicePartialAssignmentType = { // here we explicitly type, so that if fields are added in the future, they will not be forgotten here
          id: nano_id(),
          amount: price,
          requestCode,
          paxName: '',
          paxNameInvoice,
          fileStoragePath: selectedFile,
          pageNumber: 1 + iPage,
          crop: crop,
          isAutoBreakdown: true,
          autoBreakdownData: [currentDateUserHeader, line],
          needsManualConfirmation: true,
          //metadata
          userCreated: userSimpleAuto,
          dateCreated: serverTimestampAsDate(),
          userModified: userSimpleAuto,
          dateModified: serverTimestampAsDate(),
        }

        const partialId = newPartialAssignment.id
        const { id: _, ...dbObj } = newPartialAssignment

        updateObj[`partialAssignments.${partialId}`] = dbObj

        curNum++
      }
    } // each line of text
  } // each page

  const total = arraySum(result.map((entry) => entry.price))
  console.log('total', total)

  console.log('result', result)

  autosaveNewStep('Auto-assigning breakdown: IWA', updateObj, 'UNDOWALL')
    .catch((err) => setDbError('Auto-assigning breakdown', err))

}
