import { doc, updateDoc } from 'firebase/firestore';
import { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { DeleteButton } from 'src/components/Buttons/DeleteButton';
import { MoveUpDownButtons } from 'src/components/Buttons/MoveUpDownButtons';
import { EditableField } from 'src/components/EditableField/EditableField';
import { getLoadingSpinnerOrNull } from 'src/components/Spinner/util_getLoadingSpinnerOrNull';
import { useAppContext } from 'src/hooks/useAppContext';
import { useEmergencyPhoneList } from './util_emergencyphonelist';



export function EmergencyPhoneList() {

  const { db, userDetails, setDbError } = useAppContext()

  const phoneList = useEmergencyPhoneList()

  const [editedCell, setEditedCell] = useState<string | null>(null)



  // *** all hooks above ***

  const loadingSpinner = getLoadingSpinnerOrNull([
    ['phone list', phoneList],
  ])
  if (!phoneList)
    return loadingSpinner


  return (
    <div className='container'>
      <Helmet><title>Emergency Phone List</title></Helmet>
      <h3 className='my-3'>Emergency Phone list</h3>


      <table className='table' style={{ width: '30em', }}>
        <thead>
          <tr>
            <th>Phone</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {phoneList.map((phone, i) => {
            return (
              <tr key={i}>
                <td>{phone}</td>
                <td style={{ textAlign: 'right' }}>
                  <MoveUpDownButtons
                    array={phoneList}
                    setArray={(array) => {
                      updateDoc(doc(db, 'settings', 'emergencyPhoneList'), {
                        list: array,
                      })
                        .catch((err) => setDbError('Reordering phones', err))
                    }}
                    index={i}
                  />
                  {' '}
                  <DeleteButton
                    onClick={() => {
                      if (!window.confirm('Delete phone?'))
                        return

                      const newArray = [...phoneList.splice(0, i), ...phoneList.splice(i + 1)]
                      updateDoc(doc(db, 'settings', 'emergencyPhoneList'), {
                        list: newArray,
                      })
                        .catch((err) => setDbError('Deleting phone', err))
                    }}
                  />
                </td>
              </tr>
            )
          })}
          <tr>
            <td>
              <EditableField
                tableid='emergencyPhoneList'
                rowid='add_new'
                fieldname='phoneName'
                validationType=''
                currentValue=''
                isClickableToEdit={true}
                editedCell={editedCell}
                setEditedCell={setEditedCell}
                placeholderText='Add new phone'
                callbackCommitChange={(value) => {
                  setEditedCell(null)
                  if (!value)
                    return
                  const newArray = [...phoneList, value]
                  updateDoc(doc(db, 'settings', 'emergencyPhoneList'), {
                    list: newArray,
                  })
                    .catch((err) => setDbError('Adding new phone', err))
                }}
              />
            </td>
            <td>

            </td>
          </tr>
        </tbody>
      </table>

    </div>
  )
}
