import { QueryConstraint, QuerySnapshot, collection, onSnapshot, orderBy, query, where } from 'firebase/firestore'
import { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { Link } from 'react-router-dom'
import { getLoadingSpinnerOrNull } from 'src/components/Spinner/util_getLoadingSpinnerOrNull'
import { useAppContext } from 'src/hooks/useAppContext'
import 'src/pages/ExpenseSheet/expenses.css'
import { useUserListSimple } from 'src/pages/ExpenseSheet/util_getuserlist'
import { getStatusLabel } from 'src/pages/ExpenseSheet/util_status'
import { ExpenseSheetType } from 'src/types/types_expensesheet'
import { convertExpenseSheetDates } from 'src/util/util_firestoredates'
import { log_db_read } from 'src/util/util_log'
import { ExpenseListAllGuides } from './ExpenseListAllGuides'
import { ExpenseListTable } from './ExpenseListTable'
import './expenselist.css'


export function ExpenseList() {

  const { db, userDetails, perm, setDbError } = useAppContext()

  const isTravelDesignerRole = perm('guiding_expenses_view_all')


  const [sheetListMine, setSheetListMine] = useState<ExpenseSheetType[]>() // for guides, or travel designers who act as guide
  const [sheetListAll, setSheetListAll] = useState<ExpenseSheetType[]>() // only for Travel Designers


  useEffect(() => {

    log_db_read({ db, userDetails, logkey: 'db_read.list_expense_sheets', desc: 'List expense sheets' })

    const processSnapshot = function (snapshot: QuerySnapshot) {
      const sheetsAll: ExpenseSheetType[] = []
      const sheetsMine: ExpenseSheetType[] = []
      for (const docu of snapshot.docs) {
        const sheet = { ...docu.data(), id: docu.id } as ExpenseSheetType
        if (sheet._isDeleted)
          continue

        // if (!isTravelDesigner) {
        //   if (draft.status !== 'TL_DRAFT' && draft.status !== 'SUBMITTED' && draft.status !== 'APPROVED')
        //     return
        // }

        convertExpenseSheetDates(sheet)

        if (isTravelDesignerRole) {
          if (sheet.userGuideUid === userDetails.id) {
            sheetsMine.push(sheet)
          } else {
            sheetsAll.push(sheet)
          }
        } else {
          sheetsMine.push(sheet)
        }
      }

      if (isTravelDesignerRole) {
        setSheetListAll(sheetsAll)
        if (sheetsMine.length > 0)
          setSheetListMine(sheetsMine)
      } else {
        setSheetListMine(sheetsMine)
      }
    }

    const constraints: QueryConstraint[] = []
    if (!isTravelDesignerRole)
      constraints.push(where('userGuideUid', '==', userDetails.id))
    constraints.push(orderBy('dateCreated', 'desc'))
    // where('_isDeleted', '==', false)

    const q = query(collection(db, 'expensesheets'), ...constraints);
    const unsubscribe = onSnapshot(q, processSnapshot, (err) => {
      console.log({ error_expensesheet: err })
      setDbError('Loading expense sheet list', err)
    });

    return unsubscribe

  }, [db, userDetails, isTravelDesignerRole, setDbError])


  const userListSimple = useUserListSimple()



  // *** all useState and useEffect above this line. now we can return ***


  const loadingSpinner = getLoadingSpinnerOrNull([
    ['expense sheet list', (sheetListAll || sheetListMine)],
    ['user list', userListSimple],
  ])
  if (!(sheetListAll || sheetListMine) || !userListSimple)
    return loadingSpinner





  return (
    <div className='container-fluid'>
      <Helmet><title>Expense sheet list</title></Helmet>

      {sheetListMine && (
        <>
          <h3 className='mt-4 mb-2'>Guide expense sheet list</h3>

          <div className='mt-2 mb-4'>
            <Link to='/guide/expenses/rules'>View expense rules</Link>
          </div>


          <ExpenseListTable
            sheetList={sheetListMine}
            isTravelDesignerTable={false}
            setModalAction={null}
          />

        </>
      )}


      {isTravelDesignerRole && (

        <ExpenseListAllGuides
          sheetListAll={sheetListAll!} // not null for travel designers
          userListSimple={userListSimple}
        />

      )}

      <div className='mt-5'>
        <h5>Workflow:</h5>
        <table className='table'>
          <tbody>
            <tr>
              <th><span className='status active'>{getStatusLabel('TD_DRAFT')}</span></th>
              <td><b>Travel Designer</b> is inputting tour information, meal allowance, advance payments. <b>Guide</b> cannot edit the draft yet.</td>
            </tr>
            <tr>
              <th><i className='bi bi-chevron-double-right'></i> <span className='status active'>{getStatusLabel('TL_DRAFT')}</span></th>
              <td><b>Guide</b> can edit the draft. (<b>Travel Designer</b> can still edit the draft if needed.)</td>
            </tr>
            <tr>
              <th><i className='bi bi-chevron-double-right'></i> <span className='status active'>{getStatusLabel('SUBMITTED')}</span></th>
              <td>Draft submitted: <b>Guide</b> can no longer edit the draft.</td>
            </tr>
            <tr>
              <th><i className='bi bi-chevron-double-right'></i> <span className='status active'>{getStatusLabel('APPROVED')}</span></th>
              <td></td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

  )
}
