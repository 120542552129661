import { Firestore, Query, collection, query, where } from 'firebase/firestore'
import { ColumnFilterAmountType, TreeListStateAmountType } from 'src/types/types_columnfilters'
import { refreshTernaryState } from './util_filters'



export function constructDefaultAmountTreeListState(allAmounts: number[]) {
  const treeListState: TreeListStateAmountType = {
    amounts: new Map<number, 0 | 1>(),
    ternarySelected: 0,
  }

  for (const amount of allAmounts) {
    treeListState.amounts.set(amount, 0)
  }

  return treeListState
}


export function refreshAmountsTernaryState(treeListState: TreeListStateAmountType) {
  treeListState.ternarySelected = refreshTernaryState(treeListState.amounts)
}


export function getAmountFilterFromParam(selectedAmount: string | null, allAmounts: number[] | undefined): ColumnFilterAmountType | null {
  if (!allAmounts) return null  // still loading
  if (!selectedAmount) return null

  {
    const match = selectedAmount.match(/^(\d+(\.\d+)?)$/)
    if (match) {
      return {
        filterMode: 'equals',
        filterEquals: Number(selectedAmount),
      }
    }
  }

  {
    const match = selectedAmount.match(/^(\d+(\.\d+)?)~$/)
    if (match) {
      return {
        filterMode: 'range',
        filterLowerBound: Number(selectedAmount.slice(0, -1)),
      }
    }
  }

  {
    const match = selectedAmount.match(/^~(\d+(\.\d+)?)$/)
    if (match) {
      return {
        filterMode: 'range',
        filterUpperBound: Number(selectedAmount.slice(1)),
      }
    }
  }

  {
    const match = selectedAmount.match(/^(\d+(\.\d+)?)~(\d+(\.\d+)?)$/)
    if (match) {
      return {
        filterMode: 'range',
        filterLowerBound: Number(match[1]),
        filterUpperBound: Number(match[3]),
      }
    }
  }

  if (!selectedAmount.match(/^\[.+\]$/))
    throw new Error(`Invalid amount filter parameter: ${selectedAmount}`)

  const treeListState = constructDefaultAmountTreeListState(allAmounts)

  const list = selectedAmount.slice(1, -1).split(',')


  if (list.includes('all')) {
    // ALL SELECTED
    treeListState.ternarySelected = 1
    for (const amount of treeListState.amounts.keys()) {
      treeListState.amounts.set(amount, 1)
    }
  } else {


    for (const amount of treeListState.amounts.keys()) {
      const included = list.includes(amount.toString())
      treeListState.amounts.set(amount, included ? 1 : 0)
    }

    refreshAmountsTernaryState(treeListState)

  } // not 'all'

  return {
    filterMode: 'checkboxes',
    treeListState,
  }
}


export function getQueryWhenFilteringByAmount(
  db: Firestore,
  selectedAmount: string,
  appliedFilterAmount: ColumnFilterAmountType,
): [string, Query] {

  let minAmount: number | undefined = undefined // e.g. if user selects range with only upper bound, minAmount will be undefined
  let maxAmount: number | undefined = undefined

  let desc: string
  let qInvoices: Query

  if (appliedFilterAmount.filterMode === 'equals') {
    desc = `FILTER_AMOUNT equals ${selectedAmount}`
    minAmount = appliedFilterAmount.filterEquals
    maxAmount = minAmount
  } else if (appliedFilterAmount.filterMode === 'range') {
    desc = `FILTER_AMOUNT range ${selectedAmount}`
    minAmount = appliedFilterAmount.filterLowerBound
    maxAmount = appliedFilterAmount.filterUpperBound
  } else if (appliedFilterAmount.filterMode === 'checkboxes') {
    desc = `FILTER_AMOUNT checkboxes ${selectedAmount}`
    minAmount = 9999999999
    maxAmount = -9999999999
    for (const [amount, amountSelected] of appliedFilterAmount.treeListState.amounts) {
      if (amountSelected === 0) {
        continue
      }
      minAmount = Math.min(minAmount, amount)
      maxAmount = Math.max(maxAmount, amount)
    }
  } else {
    desc = 'FILTER_AMOUNT no filter'
  }

  qInvoices = query(collection(db, 'invoices'), where('_isDeleted', '==', false))
  if (minAmount)
    qInvoices = query(qInvoices, where('amount', '>=', minAmount))
  if (maxAmount)
    qInvoices = query(qInvoices, where('amount', '<=', maxAmount))

  return [desc, qInvoices]
}

